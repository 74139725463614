<template>
  <div>
    <table class="table my-closed-orders-table">
      <thead>
        <tr>
          <th>Time</th>
          <th>Buy/Sell</th>
          <th>Price</th>
          <th>{{ simbol_a }}</th>
          <th>{{ simbol_b }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in myorders" :key="item">
          <td>{{ moment(item.created_at).format("YYYY-MM-DD HH:mm") }}</td>
          <td>
            {{ item.buysell }}
          </td>
          <td>{{ item.price_db }}</td>
          <td>{{ parseFloat(item.qty_a_db).toFixed(6) }}</td>
          <td>{{ parseFloat(item.qty_b_db).toFixed(6) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// node_modules
import axios from "axios";
import moment from "moment";

// applibs
import themain from "@/applibs/themain.js";

// components

export default {
  components: {},
  props: {
    marketData: {
      type: Object,
      required: true,
    },
    connectedAddress: String,
  },
  data() {
    return {
      themain,
      moment,

      sc_address: this.$route.params.sc_address.toLowerCase(),
      market_id_bc: this.$route.params.market_id_bc,

      simbol_a: "",
      simbol_b: "",

      myorders: [],
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected from closed orders");
    },

    history: function (data) {
      var self = this;

      // pastikan history mengarah pada market_id ini dan sc ini
      if (
        parseInt(data.market_id) == parseInt(self.market_id_bc) &&
        data.sc_address == self.sc_address
      ) {
        if (
          data.history.buyer.toLowerCase() ==
            self.connectedAddress.toLowerCase() ||
          data.history.seller.toLowerCase() ==
            self.connectedAddress.toLowerCase()
        ) {
          var newData = data.history;
          if (
            data.history.buyer.toLowerCase() ==
            self.connectedAddress.toLowerCase()
          ) {
            newData["buysell"] = "Buy";
          } else {
            newData["buysell"] = "Sell";
          }
        }

        // prepend data pada array
        self.myorders.unshift(newData);
      }
    },
  },

  async mounted() {
    var self = this;

    setTimeout(async () => {
      await self.getMyOrders();

      // simbols
      var simbols = self.marketData.pair.split("-");
      self.simbol_a = simbols[0];
      self.simbol_b = simbols[1];

      console.log("self.marketData open", self.marketData);
    }, 1000);
  },

  methods: {
    async getMyOrders() {
      var self = this;

      var filter_rules = [];

      var res = await axios({
        method: "get",
        url: `${
          themain.baseApi
        }/myorders/${themain.getChosenChainId()}/closed-orders?my_address=${
          self.connectedAddress
        }&sc_address=${self.sc_address}&market_id_bc=${
          self.market_id_bc
        }&filter_rules=${JSON.stringify(filter_rules)}`,
      });

      // olah data karena ada buy dan sell disini
      var balikan = [];
      res.data.data.datas.forEach(function (fx) {
        if (
          fx.buyer.toLowerCase() == self.connectedAddress.toLocaleLowerCase()
        ) {
          fx["buysell"] = "Buy";
        } else {
          fx["buysell"] = "Sell";
        }
        balikan.push(fx);
      });
      self.myorders = balikan;
    },
  },
};
</script>

<style>
.my-closed-orders-table thead tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.my-closed-orders-table tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.my-closed-orders-table td,
th {
  padding: 5px;
  text-align: right;
}
</style>
