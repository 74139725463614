<template>
  <div>
    <TopNav />

    <div class="container text-white">
      <div class="col-lg-7">
        <h2 class="mt-4">Frequently Asked Question</h2>

        <h3 class="mt-3">What is DEX?</h3>

        <p>DEX is a decentralized exchange.</p>
        <p>
          All transaction data on DEX is on the blockchain, making this exchange
          the most transparent exchange.
        </p>

        <br class="text-light" />

        <h3>How does DEX works?</h3>
        <p>The market on DEX is created by users.</p>
        <p>
          Users in DEX do not need to make a deposit because they trade directly
          from their respective wallets. Every purchase and sale is stored in
          the blockchain. If a purchase or sale occurs, the user immediately
          gets the results directly in his wallet without having to make a
          withdrawal.
        </p>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules

// applibs

import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {};
  },
};
</script>
