<!-- 
  KODISI: TOKEN A mau ditukar menjadi TOKEN B

  Jika token user berada pada sell side,
  maka list instant swap adalah list dari order buy
-->
<template>
  <div class="row">
    <!-- 
      Swap dari token A menjadi token B
    -->
    <div
      id="modal-sell-dump"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered text-dark">
        <div class="modal-content">
          <div class="modal-body">
            <p>
              User {{ sellForm.buyer }} offer {{ sellForm.qty_b_db }}
              {{ b_symbol }} at price {{ sellForm.price_db }} {{ b_symbol }} per
              {{ a_symbol }}
            </p>

            <p>
              If you put
              <input
                type="text"
                v-model="sellForm.qty_a_db_input"
                @input="sellDumpCalculation"
              />
              {{ a_symbol }}, you will get {{ sellForm.calculation }}
              {{ b_symbol }}
            </p>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              @click="themain.closeModal(`modal-sell-dump`)"
            >
              Cancel
            </button>
            <button type="button" @click="sellDumpExec()">Submit</button>
          </div>
        </div>
      </div>
    </div>

    <!-- order buy disini -->
    <section class="row">
      <div class="col">
        <h2>Token A For Token B</h2>
      </div>

      <div class="col-12">
        <div class="row">
          List of users offering Token A for Token B. <br />
          - The highest price offer is xxxx TOKB per TOK A <br />
          - The lowest price offer is xxx TOKB per TOK A
        </div>
      </div>

      <table class="table table-striped">
        <thead>
          <tr>
            <th class="text-left">Owner</th>
            <th>Price</th>
            <th>
              {{ a_symbol }}
            </th>
            <th>
              {{ b_symbol }}
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in buys" :key="item.id">
            <td>
              {{ themain.shortAddress(item.buyer) }}
            </td>
            <td align="right">
              {{ item.price_db }}
            </td>
            <td align="right">
              {{ parseFloat(item.qty_a_db).toFixed(6) }}
            </td>
            <td align="right">
              {{ parseFloat(item.qty_b_db).toFixed(6) }}
            </td>
            <td>
              <button class="btn btn-default" @click="sellDumpModal(item)">
                Swap
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <!-- order sell disini -->
    <section class="row">
      You can make pending order here

      <table class="table table-striped">
        <thead>
          <tr>
            <th class="text-left">Owner</th>
            <th>Price</th>
            <th>
              {{ a_symbol }}
            </th>
            <th>
              {{ b_symbol }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in sells" :key="item.id">
            <td>
              {{ themain.shortAddress(item.seller) }}
            </td>
            <td align="right">
              {{ item.price_db }}
            </td>
            <td align="right">
              {{ parseFloat(item.qty_a_db).toFixed(6) }}
            </td>
            <td align="right">
              {{ parseFloat(item.qty_b_db).toFixed(6) }}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
// node_modules
import Cookie from "js-cookie";
import Big from "big.js";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";
// import erc20Data from "@/applibs/erc20/data.js";
import sellDump from "@/applibs/exchange/sell-dump.js";
import buyPump from "@/applibs/exchange/buy-pump.js";
import fee from "@/applibs/exchange/fee.js";
import allowance from "@/applibs/erc20/allowance.js";
import approve from "@/applibs/erc20/approve.js";

// components

export default {
  components: {},
  data() {
    return {
      themain,
      sc_address: this.$route.query.sc_address,
      market_id: this.$route.query.market_id,
      mytokenaddress: this.$route.query.mytokenaddress,
      market: {},
      a_symbol: "",
      b_symbol: "",

      buys: [],

      myOrderSide: "",

      sellForm: {
        qty_a: "",
        buyer: "",
        calculation: "",
        qty_a_db_input: 0,
      },

      marketFee: 0,
      marketFeeHuman: 0,

      allowance: 0,
      allowance_human: 0,
    };
  },

  async beforeMount() {
    var self = this;
    themain.beforeMountConnectToWallet(self);
    themain.networkAlert();
    themain.networkChanged(self);
    themain.accountChanged(self);
  },

  async mounted() {
    var self = this;

    await self.getMarket();
    await self.getBuys();
    await self.getSells();

    await self.getMarketFee();

    await self.getAllowance();
  },

  methods: {
    async getAllowance() {
      var self = this;
      var allowanceRes = await allowance(
        self.mytokenaddress,
        Cookie.get("connectedAddress"),
        self.sc_address
      );

      self.allowance = allowanceRes;

      // konversi allowance ke human readable
      var allowance_human = Big(`${allowanceRes}`)
        .div(`1e${self.market.a_decimals}`)
        .toString();
      console.log("allowance_human", allowance_human);
      self.allowance_human = allowance_human;
    },

    async getMarket() {
      var self = this;

      var res = await thestore.getMarket(self.sc_address, self.market_id);
      console.log("res", res);
      self.market = res;

      var pairs = res.pair.split("-");
      self.a_symbol = pairs[0];
      self.b_symbol = pairs[1];

      // Disini kita deteksi posisi dia. Apakah bagian sell atau bagian buy
      // Jika order side dia sell, maka dia akan hit buy
      if (res.a_address.toLowerCase() == self.mytokenaddress.toLowerCase()) {
        self.myOrderSide = "sell";
      } else {
        self.myOrderSide = "buy";
      }
    },

    async getMarketFee() {
      var self = this;
      var res = await fee();
      var humanFee = Big(res).div(
        `1e${process.env.VUE_APP_ROOT_COIN_DECIMALS}`
      );
      self.marketFeeHuman = humanFee.toString();
      self.marketFee = res;
    },

    async getBuys() {
      var self = this;
      var filter_rules = [
        {
          field: "sc_address",
          op: "equal",
          value: self.sc_address.toLowerCase(),
        },
        { field: "qty_a_db", op: "greater", value: "0" },
      ];
      var res = await thestore.getBuys(1, 20, "id", "desc", filter_rules);
      console.log("res", res);
      self.buys = res.datas;
    },

    async getSells() {
      var self = this;
      var filter_rules = [
        {
          field: "sc_address",
          op: "equal",
          value: self.sc_address.toLowerCase(),
        },
        { field: "qty_a_db", op: "greater", value: "0" },
      ];
      var res = await thestore.getSells(1, 20, "id", "desc", filter_rules);
      self.sells = res.datas;
    },

    // modal ini berada pada sell side
    async sellDumpModal(item) {
      var self = this;

      self.sellForm = item;

      // Sengaja dibuat properti baru karena bagian ini nanti akan diedit oleh user
      // Ketika form pertama kali di load, diberikan nilai yang sama dengan yang ada dari database
      self.sellForm["qty_a_db_input"] = item.qty_a_db;

      // kalkulasi untuk pertama kali harus sama dengan yang ada di database
      self.sellForm["calculation"] = item.qty_b_db;

      themain.openModal("modal-sell-dump");
    },

    /**
     * Ketika terjadi perubahan input, maka kode ini di eksekusi
     * untuk membuat perhitungan berapa perkiraan yang didapatkan oleh user
     * TODO: Gunakan big number
     */
    async sellDumpCalculation() {
      var self = this;

      // cegah input lebih besar dari yang ada di swap

      // tracking perbandingan dari yang di input
      var persentase =
        parseFloat(self.sellForm.qty_a_db_input) /
        parseFloat(self.sellForm.qty_a_db);
      console.log("persentase", persentase);

      if (persentase <= 1) {
        // hasil kalkulasi
        self.sellForm["calculation"] =
          parseFloat(self.sellForm.qty_b_db) * persentase;
      } else {
        alert("Can not be bigger than offering value");
      }
    },

    async sellDumpExec() {
      var self = this;

      // perhitungan untuk di submit
      var qty_a_input = Big(self.sellForm.qty_a_db_input);
      var qty_a_input_send = qty_a_input.times(
        Big(`1e${self.market.a_decimals}`)
      );

      if (Big(self.allowance).gte(qty_a_input_send.toString())) {
        // jika ada allowance, maka eksekusi langsung
        await sellDump(
          Cookie.get("connectedAddress"),
          [self.sellForm.buy_id_bc],
          [qty_a_input_send.toString()],
          self.marketFee,
          0,
          0,
          0
        );
      } else {
        // Jika tidak ada allowance, eksekusi dulu disini
        await approve(
          Cookie.get("connectedAddress"),
          self.mytokenaddress,
          self.sc_address,
          qty_a_input_send.toString()
        );

        await sellDump(
          Cookie.get("connectedAddress"),
          [self.sellForm.buy_id_bc],
          [qty_a_input_send.toString()],
          self.marketFee,
          0,
          0,
          0
        );
      }

      // TODO: APakah perlu sync data buy

      setTimeout(function () {
        self.$router.go();
      }, 3000);
    },

    // modal ini berada pada sell side
    async buyPumpModal(item) {
      var self = this;

      self.buyForm = item;

      // Sengaja dibuat properti baru karena bagian ini nanti akan diedit oleh user
      // Ketika form pertama kali di load, diberikan nilai yang sama dengan yang ada dari database
      self.buyForm["qty_b_db_input"] = item.qty_b_db;

      // kalkulasi untuk pertama kali harus sama dengan yang ada di database
      self.buyForm["calculation"] = item.qty_a_db;

      themain.openModal("modal-buy-pump");
    },

    /**
     * Ketika terjadi perubahan input, maka kode ini di eksekusi
     * untuk membuat perhitungan berapa perkiraan yang didapatkan oleh user
     * TODO: Gunakan big number
     */
    async buyPumpCalculation() {
      var self = this;

      // cegah input lebih besar dari yang ada di swap

      // tracking perbandingan dari yang di input
      var persentase =
        parseFloat(self.buyForm.qty_b_db_input) /
        parseFloat(self.buyForm.qty_b_db);
      console.log("persentase", persentase);

      if (persentase <= 1) {
        // hasil kalkulasi
        self.buyForm["calculation"] =
          parseFloat(self.buyForm.qty_a_db) * persentase;
      } else {
        alert("Can not be bigger than offering value");
      }
    },

    async buyPumpExec() {
      var self = this;

      // perhitungan untuk di submit
      var qty_b_input = Big(self.buyForm.qty_b_db_input);
      var qty_b_input_send = qty_b_input.times(
        Big(`1e${self.market.b_decimals}`)
      );

      if (Big(self.allowance).gte(qty_b_input_send.toString())) {
        // jika ada allowance, maka langsung eksekusi
        await buyPump(
          Cookie.get("connectedAddress"),
          [self.buyForm.sell_id_bc],
          [qty_b_input_send.toString()],
          self.marketFee,
          0,
          0,
          0
        );
      } else {
        // Jika tidak ada allowance, eksekusi dulu disini
        await approve(
          Cookie.get("connectedAddress"),
          self.mytokenaddress,
          self.sc_address,
          qty_b_input_send.toString()
        );

        await buyPump(
          Cookie.get("connectedAddress"),
          [self.buyForm.sell_id_bc],
          [qty_b_input_send.toString()],
          self.marketFee,
          0,
          0,
          0
        );
      }

      // TODO: Apakah perlu sync data buy

      setTimeout(function () {
        self.$router.go();
      }, 3000);
    },
  },
};
</script>
