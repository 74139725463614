<template>
  <div class="dex_bg">
    <div class="dex_container">
      <div class="dex_nav">
        <TopNav />
      </div>

      <div class="dex_center">
        <div class="dex_head">
          <Summary />
        </div>

        <div class="dex_wrapper">
          <div class="dex_col">
            <!-- column kiri -->
            <div class="dex_side">
              <div class="dex_list-title">
                <h2># Pairs</h2>
                <h2>Price & Change</h2>
              </div>

              <div class="market_section">
                <MarketSection />
              </div>
            </div>

            <!-- column tengah -->
            <div class="dex_middle">
              <div class="dex_top">
                <div
                  class="market-history market-order"
                  v-bind:id="`chart-tab`"
                >
                  <div class="heading">
                    <div
                      class="mainchart-head active cursor-pointer"
                      @click="themain.openTabNav('chart-tab', 'mainchart')"
                      style="color: #fff; float: left; margin-top: -8px"
                    >
                      <!-- Candle -->
                      <img
                        src="/images/candle-stick-logo.png"
                        alt="cs-logo"
                        style="height: 20px; margin-left: 4px"
                      />
                    </div>
                    <div
                      class="marketdepth-head cursor-pointer"
                      @click="themain.openTabNav('chart-tab', 'marketdepth')"
                      style="color: #fff; float: right; margin-top: -8px"
                    >
                      <img
                        src="/images/market-depth-logo.png"
                        alt="cs-logo"
                        style="height: 15px; width: 25px; margin-right: 4px"
                      />
                      <!-- <span>Depth</span> -->
                    </div>
                  </div>

                  <div class="tab-content">
                    <div
                      class="tab-pane fade mainchart-body show active"
                      role="tabpanel"
                    >
                      <div class="main-chart">
                        <div
                          class="tradingview-widget-container"
                          ref="tviframe"
                          style="height: 450px"
                        >
                          <iframe
                            :src="`${tv_host}/?chain_id=${chain_id}&sc_address=${sc_address}&market_id_bc=${market_id_bc}&pair=${market.pair}&width=${tvWidth}&height=${tvHeight}`"
                            style="
                              left: 0px;
                              top: 0px;
                              width: 100%;
                              height: 450px;
                              border: 0px;
                              margin: 0px;
                              display: block;
                              z-index: 999;
                            "
                          ></iframe>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane marketdepth-body fade" role="tabpanel">
                      <iframe
                        :src="`${tv_host}/market-depth?chain_id=${chain_id}&sc_address=${sc_address}&market_id_bc=${market_id_bc}&pair=${market.pair}&width=${tvWidth}&height=${tvHeight}`"
                        style="width: 100%; height: 550px; border-width: 0px"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dex_bottom">
                <div id="mainform-tab">
                  <div
                    class="row"
                    style="color: #fff"
                    id="mainform-tab-close-row"
                  >
                    <div class="col-12">
                      <span
                        class="cursor-pointer"
                        style="
                          float: right;
                          margin-bottom: -100px;
                          padding: 8px;
                        "
                        @click="closeMainFormTabInSmallScreen"
                      >
                        <i class="icon ion-md-close"></i>
                      </span>
                    </div>
                  </div>
                  <ul class="nav nav-pills">
                    <li class="nav-item">
                      <div
                        class="nav-link spot-head active cursor-pointer"
                        @click="themain.openTabNav('mainform-tab', 'spot')"
                      >
                        Spot
                      </div>
                    </li>
                    <li class="nav-item">
                      <div
                        class="nav-link swap-head cursor-pointer"
                        @click="themain.openTabNav('mainform-tab', 'swap')"
                      >
                        Swap
                      </div>
                    </li>
                  </ul>

                  <div class="tab-content">
                    <div class="tab-pane fade spot-body show active">
                      <div class="market-trade">
                        <div class="d-flex justify-content-between">
                          <div id="comp-buy-form">
                            <!-- icon swith to sell -->
                            <div
                              id="com-buy-close-form"
                              @click="smallScreenShowSellForm"
                              class="text-light"
                              style="
                                cursor: pointer;
                                width: 100%;
                                height: 37px;
                                padding-right: 17px;
                              "
                            >
                              <span
                                style="
                                  padding: 4px;
                                  background-color: rgb(246, 70, 93);
                                  float: right;
                                  border-radius: 30px;
                                  width: 30px;
                                  height: 30px;
                                  vertical-align: middle;
                                  text-align: center;
                                "
                              >
                                <i class="icon ion-md-arrow-down"></i>
                              </span>
                            </div>

                            <BuyFormBotSection
                              v-if="bot == 'true'"
                              :market="market"
                              :connectedAddress="connectedAddress"
                              :marketFee="marketFee"
                            />
                            <BuyFormSection
                              v-else
                              :market="market"
                              :connectedAddress="connectedAddress"
                              :marketFee="marketFee"
                            />
                          </div>

                          <div id="comp-sell-form">
                            <!-- icon swith to buy -->
                            <div
                              id="com-sell-close-form"
                              @click="smallScreenShowBuyForm"
                              class="text-light text-right"
                              style="
                                cursor: pointer;
                                width: 100%;
                                height: 37px;
                                padding-right: 0px;
                              "
                            >
                              <span
                                style="
                                  padding: 4px;
                                  background-color: rgb(14, 203, 129);
                                  float: left;
                                  border-radius: 30px;
                                  width: 30px;
                                  height: 30px;
                                  vertical-align: middle;
                                  text-align: center;
                                "
                              >
                                <i class="icon ion-md-arrow-up"></i>
                              </span>
                            </div>

                            <SellFormBotSection
                              v-if="bot == 'true'"
                              :market="market"
                              :connectedAddress="connectedAddress"
                              :marketFee="marketFee"
                            />
                            <SellFormSection
                              v-else
                              :market="market"
                              :connectedAddress="connectedAddress"
                              :marketFee="marketFee"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade swap-body">
                      <SwapAFormSection
                        :market="market"
                        :connectedAddress="connectedAddress"
                        :marketFee="marketFee"
                      />
                      <SwapBFormSection
                        :market="market"
                        :connectedAddress="connectedAddress"
                        :marketFee="marketFee"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- column kanan -->
            <div class="dex_side">
              <div class="order-book" id="orderbook-history-tab">
                <ul class="nav nav-pills">
                  <li class="nav-item">
                    <div
                      style="padding: 6px"
                      class="nav-link orderbook-head active cursor-pointer"
                      @click="
                        themain.openTabNav('orderbook-history-tab', 'orderbook')
                      "
                    >
                      Order Book
                    </div>
                  </li>
                  <li class="nav-item">
                    <div
                      style="padding: 6px"
                      class="nav-link history-head cursor-pointer"
                      @click="
                        themain.openTabNav('orderbook-history-tab', 'history')
                      "
                    >
                      Recent Trades
                    </div>
                  </li>
                </ul>

                <div class="tab-content h-100">
                  <!-- orderbook -->
                  <div class="tab-pane fade orderbook-body show active">
                    <div class="dex_filter">
                      <div class="dex_filter-wrapper">
                        <button
                          @click="changeOrderView('orderBook')"
                          class="css-vpi5k2"
                          :class="{ order_active: isOrderBook }"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="7" height="7" fill="#F6465D" />
                            <rect x="9" width="7" height="4" fill="#707A8A" />
                            <rect
                              x="9"
                              y="6"
                              width="7"
                              height="4"
                              fill="#707A8A"
                            />
                            <rect
                              x="9"
                              y="12"
                              width="7"
                              height="4"
                              fill="#707A8A"
                            />
                            <rect y="9" width="7" height="7" fill="#0ECB81" />
                          </svg>
                        </button>

                        <button
                          @click="changeOrderView('orderBuy')"
                          class="css-vpi5k2"
                          :class="{ order_active: isOrderBuy }"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect x="9" width="7" height="4" fill="#707A8A" />
                            <rect
                              x="9"
                              y="6"
                              width="7"
                              height="4"
                              fill="#707A8A"
                            />
                            <rect
                              x="9"
                              y="12"
                              width="7"
                              height="4"
                              fill="#707A8A"
                            />
                            <rect width="7" height="16" fill="#0ECB81" />
                          </svg>
                        </button>

                        <button
                          @click="changeOrderView('orderSell')"
                          class="css-vpi5k2"
                          :class="{ order_active: isOrderSell }"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect x="9" width="7" height="4" fill="#707A8A" />
                            <rect
                              x="9"
                              y="6"
                              width="7"
                              height="4"
                              fill="#707A8A"
                            />
                            <rect
                              x="9"
                              y="12"
                              width="7"
                              height="4"
                              fill="#707A8A"
                            />
                            <rect width="7" height="16" fill="#F6465D" />
                          </svg>
                        </button>
                      </div>

                      <div
                        @click="handleDigitMenu"
                        @mouseleave="isActive = false"
                        class="dex_digit"
                      >
                        <div class="digit_item">
                          <span>{{ valueSelected }}</span>
                          <svg
                            width="15"
                            height="7"
                            viewBox="0 0 19 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.2195 0L9.10975 10.9317L0 0H18.2195Z"
                              fill="#707A8A"
                            />
                          </svg>
                        </div>

                        <div
                          class="digit_lists"
                          :class="{ menu_active: isActive }"
                        >
                          <span @click="changeDigitFloat('0.0000001')">
                            0.0000001</span
                          >
                          <span @click="changeDigitFloat('0.000001')">
                            0.000001</span
                          >
                          <span @click="changeDigitFloat('0.00001')">
                            0.00001</span
                          >
                          <span @click="changeDigitFloat('0.0001')">
                            0.0001</span
                          >
                          <span @click="changeDigitFloat('0.001')"> 0.001</span>
                          <span @click="changeDigitFloat('0.01')"> 0.01</span>
                          <span @click="changeDigitFloat('0.1')"> 0.1 </span>
                          <span @click="changeDigitFloat('1')">1</span>
                        </div>
                      </div>
                    </div>

                    <table class="table">
                      <thead :class="{ order_hide: isOrderBuy }">
                        <tr>
                          <th style="font-size: 11px">Price({{ simbol_b }})</th>
                          <th style="font-size: 11px">
                            Amount({{ simbol_a }})
                          </th>
                          <th style="font-size: 11px">Total({{ simbol_b }})</th>
                        </tr>
                      </thead>

                      <!-- lists of sell section -->
                      <tbody
                        v-bind:id="`obook_sell`"
                        :class="{ order_hide: isOrderBuy }"
                      >
                        <tr
                          v-for="item in sells.sort(function (a, b) {
                            return b.price_db_float - a.price_db_float;
                          })"
                          :key="item.id"
                          :class="`red-bg-${item.percent}`"
                          @click="orderbookRowClicked('sell', item)"
                        >
                          <td class="red">
                            <div v-if="item.price_db == '1000000000000000'">
                              &nbsp;
                            </div>
                            <div v-else>
                              {{ parseFloat(item.price_db).toFixed(floatDigit) }}
                            </div>
                          </td>
                          <td>
                            <div v-if="item.price_db == '1000000000000000'">
                              &nbsp;
                            </div>
                            <div v-else>
                              {{ parseFloat(item.qty_a_db).toFixed(floatDigit) }}
                            </div>
                          </td>
                          <td>
                            <div v-if="item.price_db == '1000000000000000'">
                              &nbsp;
                            </div>
                            <div v-else>
                              {{ parseFloat(item.qty_b_db).toFixed(floatDigit) }}
                            </div>
                            <div
                              class="trick"
                              style="height: 0; overflow: hidden"
                              :id="`sell_bc_id_${item.sell_id_bc}`"
                            >
                              {{ item }}
                            </div>
                          </td>
                        </tr>
                      </tbody>

                      <!-- lists of last price sections -->
                      <tbody class="ob-heading">
                        <tr>
                          <td :class="`${price_color}`">
                            <span>Last Price</span>
                            <div
                              :class="`${price_color}`"
                              style="font-size: 1rem"
                            >
                              {{ parseFloat(last_price).toFixed(floatDigit) }}
                            </div>
                          </td>
                          <td>
                            <span>Vol</span>
                            {{ parseFloat(vol_24h).toFixed(2) }}
                          </td>
                          <td :class="`${price_color}`">
                            <span>Change</span>
                            {{
                              themain.angkaDenganPersentaseBesar(
                                parseFloat(change_24h)
                              )
                            }}%
                          </td>
                        </tr>
                      </tbody>

                      <!-- lists of buy section -->
                      <tbody
                        v-bind:id="`obook_buy`"
                        :class="{ order_hide: isOrderSell }"
                      >
                        <tr
                          v-for="item in buys.sort(function (a, b) {
                            return b.price_db_float - a.price_db_float;
                          })"
                          :key="item.id"
                          :class="`green-bg-${item.percent}`"
                          @click="orderbookRowClicked('buy', item)"
                        >
                          <td class="green">
                            {{ parseFloat(item.price_db).toFixed(floatDigit) }}
                          </td>
                          <td>
                            {{ parseFloat(item.qty_a_db).toFixed(floatDigit) }}
                          </td>
                          <td>
                            {{ parseFloat(item.qty_b_db).toFixed(floatDigit) }}
                            <div
                              class="trick"
                              style="height: 0; overflow: hidden"
                              :id="`buy_bc_id_${item.buy_id_bc}`"
                            >
                              {{ item }}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- history -->
                  <div class="tab-pane fade history-body h-100" role="tabpanel">
                    <table class="table h-100">
                      <thead>
                        <tr class="tbl_head">
                          <th>Time</th>
                          <th>Price({{ simbol_b }})</th>
                          <th>Amount({{ simbol_a }})</th>
                        </tr>
                      </thead>
                      <tbody class="history_t">
                        <tr v-for="item in histories" :key="item.id">
                          <td>
                            {{
                              moment(item.time * 1000).format(
                                "YYYY-MM-DD HH:mm"
                              )
                            }}
                          </td>
                          <td :class="historyBuySellColor(item)">
                            {{ parseFloat(item.price_db).toFixed(6) }}
                          </td>
                          <td>
                            {{ parseFloat(item.qty_a_db).toFixed(6) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- bagian bawah -->
        <div class="row row_edt">
          <div class="col_edt">
            <div class="market-news">
              <h2 class="heading">Market News</h2>
              <ul>
                <li v-for="item in articles" :key="item.id">
                  <a :href="item.ext_link" target="_blank">
                    <strong>{{ item.title }}</strong>
                    {{ item.body }}
                    <span>{{ item.created_at }}</span>
                  </a>
                </li>
              </ul>
              <div class="row">
                <div class="col">
                  <div class="btn btn-default">
                    <span class="btn_load">Load More</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col_edt">
            <div class="market-history market-order" v-bind:id="`my-tab`">
              <ul class="nav nav-pills" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link cursor-pointer open-orders-head active"
                    data-toggle="pill"
                    role="tab"
                    aria-selected="true"
                    @click="themain.openTabNav('my-tab', 'open-orders')"
                  >
                    Open Orders
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link cursor-pointer closed-orders-head"
                    data-toggle="pill"
                    role="tab"
                    aria-selected="false"
                    @click="themain.openTabNav('my-tab', 'closed-orders')"
                  >
                    Closed Orders
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div
                  class="tab-pane fade open-orders-body show active"
                  role="tabpanel"
                >
                  <MyOpenOrdersBot v-if="bot == 'true'" :market="market" />
                  <MyOpenOrders v-else :market="market" />
                </div>
                <div class="tab-pane fade closed-orders-body" role="tabpanel">
                  <MyClosedOrders
                    :marketData="market"
                    :connectedAddress="connectedAddress"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Sticky Bottom Bar khusus mobile -->
      <div class="sticky-bottom" id="bottom-navbar">
        <div class="container-fluid">
          <!-- buy dan sell jika terkoneksi -->
          <div class="row">
            <div class="col-6">
              <button class="btn btn-buy" @click="smallScreenShowBuyForm">
                Beli
              </button>
            </div>
            <div class="col-6">
              <button class="btn btn-sell" @click="smallScreenShowSellForm">
                Jual
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules
import axios from "axios";
import moment from "moment";
import Big from "big.js";
import Cookie from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
// import thestore from "@/applibs/thestore.js";
import fee from "@/applibs/exchange/fee.js";
import bcBuys from "@/applibs/exchange/buys.js";
import bcSells from "@/applibs/exchange/sells.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import BuyFormSection from "@/components/markets/Exchange/BuyFormSection.vue";
import BuyFormBotSection from "@/components/markets/Exchange/BuyFormBotSection.vue";
import SwapAFormSection from "@/components/markets/Exchange/SwapAFormSection.vue";
import SwapBFormSection from "@/components/markets/Exchange/SwapBFormSection.vue";
import SellFormSection from "@/components/markets/Exchange/SellFormSection.vue";
import SellFormBotSection from "@/components/markets/Exchange/SellFormBotSection.vue";
import MarketSection from "@/components/markets/Exchange/MarketSection.vue";
import MyOpenOrders from "@/components/markets/Exchange/MyOpenOrders.vue";
import MyOpenOrdersBot from "@/components/markets/Exchange/MyOpenOrdersBot.vue";
import MyClosedOrders from "@/components/markets/Exchange/MyClosedOrders.vue";
import Summary from "@/components/markets/Exchange/Summary.vue";

export default {
  components: {
    TopNav,
    BuyFormSection,
    BuyFormBotSection,
    SwapAFormSection,
    SwapBFormSection,
    SellFormSection,
    SellFormBotSection,
    MarketSection,
    MyOpenOrders,
    MyOpenOrdersBot,
    MyClosedOrders,
    Summary,
  },
  data() {
    return {
      moment,
      themain,

      chain_id: this.$route.params.chainId,
      sc_address: this.$route.params.sc_address.toLowerCase(),
      market_id_bc: this.$route.params.market_id_bc,
      bot: this.$route.query.bot,
      botAddress: this.$route.query.botAddress,

      connectedAddress: Cookie.get("connectedAddress"),

      last_price: 0,
      price_buy: 0,
      price_sell: 0,
      vol_24h: 0,
      change_24h: 0,
      price_color: "green",

      simbol_a: "",
      simbol_b: "",

      market: {
        pair: "a-b",
      },
      sells: [],
      buys: [],
      histories: [],

      tvWidth: 0,
      tvHeight: 450,

      tv_host: process.env.VUE_APP_TV,

      biggest_qty_b_db_sell: 0,
      biggest_qty_b_db_buy: 0,
      biggest_qty_b_db: 0,

      articles: [],

      marketFee: "0",

      isActive: false,
      floatDigit: 6,
      valueSelected: "0.000001",
      isOrderActive: false,
      isOrderBook: true,
      isOrderSell: false,
      isOrderBuy: false,
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected");
    },

    /**
     * Ketika pertama kali halaman di load, maka aplikasi memanggil api.
     * Setelah menerima panggilan, app api mengirimkan websocket kepada semua orang
     * yang sedang terkoneksi. Jadi, disini diterima tepat setelah mounted di eksekusi.
     */
    sells: function (data) {
      var self = this;

      if (
        parseInt(data.chain_id) == parseInt(themain.getChosenChainId()) &&
        parseInt(data.market_id) == parseInt(self.market_id_bc) &&
        data.sc_address == self.sc_address
      ) {

        // TRICK
        // Saat ini sell row jika sedikit, dia akan stuck diatas
        // Kita ingin row sell ini tidak stuck diatas sehingga kita harus buat trick jika sells.count < 20, maka kita tambahkan row saja

        const sell_trick = { 
          id: 0, 
          sell_id_bc: 0,  
          price_db: "1000000000000000",
          price_db_float: 1000000000000000,
          trick_view: true
        }
      
        if(data.sells.length < 20) {
          for (let i = 0; i < 20; i++) {
            data.sells.push(sell_trick);
          }
        }

        // letakkan data sells
        self.sells = data.sells.sort(function (a, b) {
          return b.price_db_float - a.price_db_float;
        });

        if (self.sells.length > 0 && self.sells) {
          self.price_sell = self.sells[0].price_db;
        } else {
          self.price_sell = 0;
        }

        self.recalcOrderBook();

        self.emitter.emit("fe-sells", {
          data: self.sells,
        });
      }
    },

    /**
     * Ketika pertama kali halaman di load, maka aplikasi memanggil api.
     * Setelah menerima panggilan, app api mengirimkan websocket kepada semua orang
     * yang sedang terkoneksi. Jadi, disini diterima tepat setelah mounted di eksekusi.
     */
    buys: function (data) {
      var self = this;

      if (
        parseInt(data.chain_id) == parseInt(themain.getChosenChainId()) &&
        parseInt(data.market_id) == parseInt(self.market_id_bc) &&
        data.sc_address == self.sc_address
      ) {
        // letakkan data buys
        self.buys = data.buys.sort(function (a, b) {
          return b.price_db_float - a.price_db_float;
        });

        if (self.buys.length > 0 && self.buys) {
          self.price_buy = self.buys[0].price_db;
        } else {
          self.price_buy = 0;
        }

        self.recalcOrderBook();

        self.emitter.emit("fe-buys", {
          data: self.buys,
        });
      }
    },

    // ini ketika dapat satu data history saja
    history: function (data) {
      var self = this;

      // pastikan history mengarah pada market_id ini dan sc ini
      if (
        parseInt(data.chain_id) == parseInt(themain.getChosenChainId()) &&
        parseInt(data.market_id) == parseInt(self.market_id_bc) &&
        data.sc_address == self.sc_address
      ) {
        self.histories.unshift(data.history);
      }
    },

    myorders: function (data) {
      var self = this;

      // hanya bisa diterima jika ada address yang terkoneksi
      if (self.connectedAddress != "") {
        // hanya diterima jika market sesuai
        if (
          parseInt(data.myorder.market_id_bc) == parseInt(self.market_id_bc) &&
          data.myorder.sc_address == self.sc_address
        ) {
          // jika sesuai dengan address yang terkoneksi
          if (
            data.myorder.seller == self.connectedAddress.toLowerCase() ||
            data.myorder.buyer == self.connectedAddress.toLowerCase()
          ) {
            self.emitter.emit("fe-myorders", {
              data: data.myorder,
            });
          }
        }
      }
    },

    market_update: function (data) {
      var self = this;

      var market = data.market;

      // buat timeout sedikit karena hasilnya kadang gak sesuai ekspektasi
      setTimeout(function () {
        if (
          market.market_id_bc == parseInt(self.market_id_bc) &&
          market.sc_address == self.sc_address
        ) {
          self.last_price = market.last_price;
          self.price_buy = market.last_bid;
          self.price_sell = market.last_ask;
          self.change_24h = market.change_24h;

          if (market.change_24h >= 0) {
            self.price_color = "green";
          } else {
            self.price_color = "red";
          }

          // update volume 24 jam
          self.vol_24h = market.vol_24h;

          self.emitter.emit("fe-market_update", {
            data: market,
          });
        }
      }, 1000);
    },
  },

  async beforeMount() {
    var self = this;
    self.connectedAddress = Cookie.get("connectedAddress");
  },

  async mounted() {
    var self = this;

    console.log("self.$route", self.$route);

    // jika ada bot
    if (self.botAddress) {
      self.connectedAddress = self.botAddress;
    }

    // karena pada component form sering gagal, maka kita emit saja
    self.emitter.emit("fe-exchage_page_connected_address", {
      data: {
        connectedAddress: self.connectedAddress,
      },
    });

    // Disini kita dapat data market seperti pair, desimal token A, desimal token B
    // dan juga histories untuk market ini
    // Pada function ini juga men-triger untuk mengirimkan websocket sells dan buys kepada users
    await self.getMarketExchange();

    // simbols
    var simbols = self.market.pair.split("-");
    self.simbol_a = simbols[0];
    self.simbol_b = simbols[1];

    var tvWidthRes = self.$refs.tviframe.clientWidth;
    self.tvWidth = tvWidthRes;

    self.sellElScrollToBottom();

    // ambil artikel
    // self.getArticles();

    // ambil fee market
    self.getMarketFee();

    // TODO: KODE INI ADA ERROR, JADI DIMATIKAN SEMENTARA
    //       DAN SEPERTINYA QUERY SELECTOR YANG DICARI JUGA GAK ADA DI CODE MANAPUN
    // self.handleFullScreen();

    // Tunggu sekitar 6000 ms agar data buys dan sells sudah tersedia
    // Setelah itu baru kita sync antara database dan blockchain
    setTimeout(function () {
      self.syncBcDbOrderbooks();
    }, 6000);
  },
  methods: {
    /**
     * Karena object sell ditaro pada element atas,
     * jika row nya sudah terlalu banyak maka price paling kecil akan tersembuyi.
     * Jadi, disini kita scroll ke bawah sehingga harga paling kecil terlihat.
     * Untuk memahami, lihat orderbook bagian sell
     * Function ini dipanggil dari beberapa tempat.
     */
    sellElScrollToBottom() {
      setTimeout(() => {
        var objDiv = document.getElementById("obook_sell");
        if(objDiv != null) {
          objDiv.scrollTop = objDiv.scrollHeight;
        }
      }, 100);
    },

    /**
     * Mengurutkan semua data berdasarkan order yang benar.
     */
    recalcOrderBook() {
      var self = this;

      var max_obj_buys = { qty_b_db: 0 };
      if (self.buys.length > 0) {
        max_obj_buys = self.buys.reduce(function (prev, current) {
          return parseFloat(prev.qty_b_db) > parseFloat(current.qty_b_db)
            ? prev
            : current;
        });
      }

      var max_obj_sells = { qty_b_db: 0 };
      if (self.sells.length > 0) {
        max_obj_sells = self.sells.reduce(function (prev, current) {
          return parseFloat(prev.qty_b_db) > parseFloat(current.qty_b_db)
            ? prev
            : current;
        });
      }

      var biggest = 0;
      if (
        parseFloat(max_obj_sells.qty_b_db) > parseFloat(max_obj_buys.qty_b_db)
      ) {
        biggest = parseFloat(max_obj_sells.qty_b_db);
      } else {
        biggest = parseFloat(max_obj_buys.qty_b_db);
      }

      // Data buys price dalam string, kita tidak bisa mengurutkan string.
      // Jadi kita urutkan setelah kita membuat menjadi float
      var buys_obj = [];
      self.buys.forEach((element) => {
        // buat persentase
        element.percent = ((element.qty_b_db / biggest) * 100).toFixed(0);
        element.price_db_float = parseFloat(element.price_db);

        buys_obj.push(element);
      });

      // karena data sells price dalam string, kita susah mengurutkannya
      // ada kalanya mengurutkan string menjadi masalah
      var sells_obj = [];
      self.sells.forEach((element) => {
        // buat persentase
        element.percent = ((element.qty_b_db / biggest) * 100).toFixed(0);
        element.price_db_float = parseFloat(element.price_db);

        sells_obj.push(element);
      });

      self.sellElScrollToBottom();
    },

    orderbookRowClicked(buy_or_sell, obj) {
      var self = this;

      // pada view orderbook sell, ada sebuah trick untuk scroll halaman. 
      // jadi jika itu adalah trick, kita tidak sertakan dalam click
      if(obj.trick_view == null || obj.trick_view == false) {
        self.emitter.emit("fe-obook_clicked", {
          data: {
            kind: buy_or_sell,
            obj,
          },
        });
      }
    },

    smallScreenShowSellForm() {
      // tampilkan main form
      const mainFormTab = document.getElementById("mainform-tab");
      mainFormTab.style.display = "block";

      // hide buy
      var buy = document.getElementById("comp-buy-form");
      buy.style.display = "none";

      // show sell
      var sell = document.getElementById("comp-sell-form");
      sell.style.display = "block";
      sell.scrollIntoView({ behavior: "smooth", block: "end" });

      // sticky bar hide
      var elNav = document.getElementById("bottom-navbar");
      elNav.style.display = "none";

      var elCLoseMainFormTab = document.getElementById(
        "mainform-tab-close-row"
      );
      elCLoseMainFormTab.style.display = "block";
    },
    smallScreenShowBuyForm() {
      // tampilkan main form
      const mainFormTab = document.getElementById("mainform-tab");
      mainFormTab.style.display = "block";

      // show buy
      var buy = document.getElementById("comp-buy-form");
      buy.style.display = "block";
      buy.scrollIntoView({ behavior: "smooth", block: "end" });

      // hide sell
      var sell = document.getElementById("comp-sell-form");
      sell.style.display = "none";

      // sticky bar hide
      var elNav = document.getElementById("bottom-navbar");
      elNav.style.display = "none";

      var elCLoseMainFormTab = document.getElementById(
        "mainform-tab-close-row"
      );
      elCLoseMainFormTab.style.display = "block";
    },

    closeMainFormTabInSmallScreen() {
      // tampilkan main form
      const mainFormTab = document.getElementById("mainform-tab");
      mainFormTab.style.display = "none";

      // sticky bar show
      var elNav = document.getElementById("bottom-navbar");
      elNav.style.display = "block";
    },

    async getMarketExchange() {
      var self = this;
      var marketRes = await axios({
        method: "get",
        url: `${themain.baseApi}/markets/${Cookie.get(
          "chosenChainId"
        )}/exchange?sc_address=${self.sc_address}&market_id_bc=${
          self.market_id_bc
        }`,
      });

      console.log(
        "data-market-pertama-kali-dari-page-exchange",
        marketRes.data.data.market
      );
      self.market = marketRes.data.data.market;
      self.histories = marketRes.data.data.histories;
    },

    // async getArticles() {
    //   var self = this;
    //   var res = await thestore.getArticles(1, 10, "id", "desc", []);
    //   self.articles = res.datas;
    // },

    async getMarketFee() {
      var self = this;
      var res = await fee();

      // TODO: Ambil root coin decimals dari config
      var humanFee = Big(res).div(`1e18`);
      self.marketFee = humanFee.toString();
    },

    handleFullScreen() {
      // var self = this;
      const fullscreenElement = this.$el.querySelector(
        ".header-group-fullscreen"
      );
      fullscreenElement.addEventListener("click", () => {
        console.log(fullscreenElement);
      });
    },

    handleDigitMenu() {
      var self = this;

      self.isActive = !self.isActive;
    },

    changeDigitFloat(val) {
      var self = this;

      self.valueSelected = val;

      switch (val) {
        case "0.0000001":
          self.floatDigit = 7;
          break;
        case "0.000001":
          self.floatDigit = 6;
          break;
        case "0.00001":
          self.floatDigit = 5;
          break;
        case "0.0001":
          self.floatDigit = 4;
          break;
        case "0.001":
          self.floatDigit = 3;
          break;
        case "0.01":
          self.floatDigit = 2;
          break;
        case "0.1":
          self.floatDigit = 1;
          break;
        case "1":
          self.floatDigit = 0;
          break;
        default:
          break;
      }

      self.sells();
    },

    changeOrderView(e) {
      var self = this;

      self.isOrderBook = e === "orderBook";
      self.isOrderBuy = e === "orderBuy";
      self.isOrderSell = e === "orderSell";
    },

    historyBuySellColor(item) {
      if (item.buy_or_sell != null) {
        if (item.buy_or_sell == true) {
          return "green";
        } else {
          return "red";
        }
      } else {
        return "red";
      }
    },

    async syncBcDbOrderbooks() {
      var self = this;

      // ambil semua data buys
      self.buys.forEach(async function (ele) {
        var hasil = await bcBuys(ele.buy_id_bc);
        if (hasil.qtyA != ele.qty_a) {
          // sync karena data blockchain tidak sama dengan yang di database
          console.log("TODO: PERLU DIBUATKAN DATA SYNC");
        }
      });

      // ambil semua data sells
      self.sells.forEach(async function (ele) {
        var hasil = await bcSells(ele.sell_id_bc);
        if (hasil.qtyA != ele.qty_a) {
          // sync karena data blockchain tidak sama dengan yang di database
          console.log("TODO: PERLU DIBUATKAN DATA SYNC");
        }
      });
    },
  },
};
</script>

<style scoped>
.sticky-bottom {
  display: none;
}
#mainform-tab {
  display: block;
}
@media only screen and (max-width: 767px) {
  .sticky-bottom {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
    padding: 10px 15px;
    text-align: center;
    z-index: 1020;
  }
  #mainform-tab {
    display: none;
  }
}

.dex_bg {
  background-color: #161a1f;
}
.dex_container {
  height: 100%;
  /* background-color: #161a1f; */
}

.dex_wrapper {
  border: 1px solid #262c34;
  border-top: none;
  border-bottom: none;
  /* margin: 0 20px; */
  height: 70%;
}

.dex_center {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 0 20px;
}

.dex_head {
  border: 1px solid #262c34;
  border-top: none;
  border-bottom: none;
}

.dex_col {
  border: 1px solid #262c34;
  border-bottom: none;
  border-right: none;
  border-left: none;
  display: flex;

  height: 100%;
}

.dex_side {
  flex: 2;
  /* border: 1px solid #262c34; */
  padding: 10px;
  /* height: 100%; */
  overflow: hidden;
}

.dex_side:nth-child(1) {
  border-right: 1px solid #262c34;
}

.dex_side:nth-child(3) {
  border-left: 1px solid #262c34;
}

.market_section {
  height: 100%;
  /* overflow-y: scroll; */
}

.dex_middle {
  flex: 6;
  display: flex;
  flex-direction: column;
}

.dex_list-title {
  display: flex;
  justify-content: space-between;
}

.dex_list-title h2 {
  font-size: 12px;
  font-weight: bold;
  color: #4f5966;
}

.dex_top {
  flex: 3;
  height: 450px;
}

.dex_bottom {
  flex: 4;
  background-color: #1e2329 !important;
}

.swap-head {
  background-color: #161a1f;
  width: 100% !important;
}

.nav-pills {
  background-color: #161a1f;
  width: 100% !important;
}

.ob-heading tr {
  display: flex !important;
}

.ob-heading span {
  display: flex;
  justify-content: center;
}

#dark #mainform-tab .nav-link {
  color: #4f5966;
}
#dark #mainform-tab .nav-link.active {
  color: #fff;
  background: transparent;
}
.market-trade {
  padding: 14px;
}

.tradingview-widget-container {
  width: 100%;
}

#mainform-tab {
  height: 100% !important;
}

.market-trade {
  border: none !important;
  background-color: #1e2329 !important;
  border-radius: 0 !important;
  height: 100% !important;
}

.swap-body {
  /* background-color: rgb(230, 230, 230); */

  padding: 10px 15px;
  margin-top: 20px;
  border-radius: 20px;
}

.nav-link.active {
  background-color: #1e2329 !important;
  border-radius: 0 !important;
}

.dex_filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.css-vpi5k2 svg {
  opacity: 0.2;
}

.order_active svg {
  opacity: 1;
}

.css-vpi5k2 {
  background: none;
  border: none;
}

.digit_item {
  width: 60px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.digit_item span {
  color: aliceblue;
}

.dex_digit {
  position: relative;
  cursor: pointer;
}

.digit_lists {
  position: absolute;
  /* margin-right: 10px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  background-color: #262c34;
  border: 1px solid #262c34;
  border-radius: 3px;
  width: 60px;
  z-index: 999;

  opacity: 0;
  transition-duration: 0.5s;
}

.menu_active {
  opacity: 1;
}

.digit_lists span {
  color: aliceblue;
  width: 100%;
  display: flex;
  justify-content: center;
}

.digit_lists span:hover {
  background-color: rgb(14, 203, 129);
}

.order_hide {
  display: none !important;
}

/*
bagian ini membuat table yang berantakan menjadi rapi
lihat diskusi ini https://stackoverflow.com/questions/41534794/table-rows-not-aligning
sample berantakan: https://jsfiddle.net/fk6fnto4/
dirapikan: https://jsfiddle.net/fk6fnto4/1/
 */

.order-book {
  border: none !important;
}

.order-book thead tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.order-book tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.order-book td,
th {
  padding: 5px;
  text-align: right;
  font-size: 12px;
}

.wrapper_dex {
  display: flex;
  justify-content: center;
  padding: 0 10px;
  /* background-color: yellow; */
}

.no-gutters {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tbl_head th {
  text-align: start;
  border: 1px solid #fff;
}

table td {
  border: none !important;
}

table {
  height: 300px;
}

.history_t {
  height: 100% !important;
}

.row_edt {
  padding: 0 15px;
}

.col_edt:nth-child(1) {
  /* padding-right: 0 !important; */
  flex: 2;
}

.col_edt:nth-child(2) {
  /* padding-left: 0 !important; */
  flex: 6.4;
}

.market-order {
  height: 100% !important;
}

.btn_load {
  background-color: rgb(14, 203, 129);
  padding: 5px 30px;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
}

#dark #orderbook-history-tab .nav {
  background: #161a1f;
}
#dark #orderbook-history-tab .nav-link {
  color: #4f5966;
}

#dark #orderbook-history-tab .nav-link.active {
  color: #fff;
  background: transparent;
}

#orderbook-history-tab {
  height: 100%;
}
@media only screen and (max-width: 767px) {
  #orderbook-history-tab {
    height: 550px;
  }
  .market_section {
    display: none;
  }
}

/** pada desktop, buy dan sell form berdampingan */
#comp-buy-form {
  display: block;
  width: 100%;
}
#com-buy-close-form {
  display: none;
}
#comp-sell-form {
  display: block;
  width: 100%;
}
#com-sell-close-form {
  display: none;
}
#bottom-navbar {
  display: none;
}

#mainform-tab-close-row {
  display: none;
}

@media only screen and (max-width: 884px) {
  /** pada mobile, secara default buy muncul dan sell disembunyikan */
  #comp-buy-form {
    display: block;
  }
  #com-buy-close-form {
    display: block;
  }
  #comp-sell-form {
    display: none;
  }
  #com-sell-close-form {
    display: block;
  }
  #bottom-navbar {
    display: block;
    background-color: #131722;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #888;
  }
  #bottom-navbar .btn-buy {
    background: rgb(14, 203, 129);
    color: #fff;
    text-transform: uppercase;
    padding: 10px;
    width: 100%;
    height: 40px;
    border-radius: 50px;
  }
  #bottom-navbar .btn-sell {
    background: rgb(246, 70, 93);
    color: #fff;
    text-transform: uppercase;
    padding: 10px;
    width: 100%;
    height: 40px;
    border-radius: 50px;
  }

  .dex_col,
  .row_edt {
    flex-direction: column;
  }

  .col_edt:nth-child(1) {
    order: 2;
  }

  .col_edt:nth-child(2) {
    order: 1;
  }

  .dex_side:nth-child(1) {
    display: none;
  }
}

.red-bg-100,
.red-bg-99,
.red-bg-98,
.red-bg-97,
.red-bg-96,
.red-bg-95,
.red-bg-94,
.red-bg-93,
.red-bg-92,
.red-bg-91,
.red-bg-90,
.red-bg-89,
.red-bg-88,
.red-bg-87,
.red-bg-86,
.red-bg-85,
.red-bg-84,
.red-bg-83,
.red-bg-82,
.red-bg-81,
.red-bg-80,
.red-bg-79,
.red-bg-78,
.red-bg-77,
.red-bg-76,
.red-bg-75,
.red-bg-74,
.red-bg-73,
.red-bg-72,
.red-bg-71,
.red-bg-70,
.red-bg-69,
.red-bg-68,
.red-bg-67,
.red-bg-66,
.red-bg-65,
.red-bg-64,
.red-bg-63,
.red-bg-62,
.red-bg-61,
.red-bg-60,
.red-bg-59,
.red-bg-58,
.red-bg-57,
.red-bg-56,
.red-bg-55,
.red-bg-54,
.red-bg-53,
.red-bg-52,
.red-bg-51,
.red-bg-50,
.red-bg-49,
.red-bg-48,
.red-bg-47,
.red-bg-46,
.red-bg-45,
.red-bg-44,
.red-bg-43,
.red-bg-42,
.red-bg-41,
.red-bg-40,
.red-bg-39,
.red-bg-38,
.red-bg-37,
.red-bg-36,
.red-bg-35,
.red-bg-34,
.red-bg-33,
.red-bg-32,
.red-bg-31,
.red-bg-30,
.red-bg-29,
.red-bg-28,
.red-bg-27,
.red-bg-26,
.red-bg-25,
.red-bg-24,
.red-bg-23,
.red-bg-22,
.red-bg-21,
.red-bg-20,
.red-bg-19,
.red-bg-18,
.red-bg-17,
.red-bg-16,
.red-bg-15,
.red-bg-14,
.red-bg-13,
.red-bg-12,
.red-bg-11,
.red-bg-10,
.red-bg-9,
.red-bg-8,
.red-bg-7,
.red-bg-6,
.red-bg-5,
.red-bg-4,
.red-bg-3,
.red-bg-2,
.red-bg-1,
.red-bg-0,
.red-bg,
.green-bg-100,
.green-bg-99,
.green-bg-98,
.green-bg-97,
.green-bg-96,
.green-bg-95,
.green-bg-94,
.green-bg-93,
.green-bg-92,
.green-bg-91,
.green-bg-90,
.green-bg-89,
.green-bg-88,
.green-bg-87,
.green-bg-86,
.green-bg-85,
.green-bg-84,
.green-bg-83,
.green-bg-82,
.green-bg-81,
.green-bg-80,
.green-bg-79,
.green-bg-78,
.green-bg-77,
.green-bg-76,
.green-bg-75,
.green-bg-74,
.green-bg-73,
.green-bg-72,
.green-bg-71,
.green-bg-70,
.green-bg-69,
.green-bg-68,
.green-bg-67,
.green-bg-66,
.green-bg-65,
.green-bg-64,
.green-bg-63,
.green-bg-62,
.green-bg-61,
.green-bg-60,
.green-bg-59,
.green-bg-58,
.green-bg-57,
.green-bg-56,
.green-bg-55,
.green-bg-54,
.green-bg-53,
.green-bg-52,
.green-bg-51,
.green-bg-50,
.green-bg-49,
.green-bg-48,
.green-bg-47,
.green-bg-46,
.green-bg-45,
.green-bg-44,
.green-bg-43,
.green-bg-42,
.green-bg-41,
.green-bg-40,
.green-bg-39,
.green-bg-38,
.green-bg-37,
.green-bg-36,
.green-bg-35,
.green-bg-34,
.green-bg-33,
.green-bg-32,
.green-bg-31,
.green-bg-30,
.green-bg-29,
.green-bg-28,
.green-bg-27,
.green-bg-26,
.green-bg-25,
.green-bg-24,
.green-bg-23,
.green-bg-22,
.green-bg-21,
.green-bg-20,
.green-bg-19,
.green-bg-18,
.green-bg-17,
.green-bg-16,
.green-bg-15,
.green-bg-14,
.green-bg-13,
.green-bg-12,
.green-bg-11,
.green-bg-10,
.green-bg-9,
.green-bg-8,
.green-bg-7,
.green-bg-6,
.green-bg-5,
.green-bg-4,
.green-bg-3,
.green-bg-2,
.green-bg-1,
.green-bg-0,
.green-bg {
  position: relative;
}
.red-bg-100:after,
.red-bg-99:after,
.red-bg-98:after,
.red-bg-97:after,
.red-bg-96:after,
.red-bg-95:after,
.red-bg-94:after,
.red-bg-93:after,
.red-bg-92:after,
.red-bg-91:after,
.red-bg-90:after,
.red-bg-89:after,
.red-bg-88:after,
.red-bg-87:after,
.red-bg-86:after,
.red-bg-85:after,
.red-bg-84:after,
.red-bg-83:after,
.red-bg-82:after,
.red-bg-81:after,
.red-bg-80:after,
.red-bg-79:after,
.red-bg-78:after,
.red-bg-77:after,
.red-bg-76:after,
.red-bg-75:after,
.red-bg-74:after,
.red-bg-73:after,
.red-bg-72:after,
.red-bg-71:after,
.red-bg-70:after,
.red-bg-69:after,
.red-bg-68:after,
.red-bg-67:after,
.red-bg-66:after,
.red-bg-65:after,
.red-bg-64:after,
.red-bg-63:after,
.red-bg-62:after,
.red-bg-61:after,
.red-bg-60:after,
.red-bg-59:after,
.red-bg-58:after,
.red-bg-57:after,
.red-bg-56:after,
.red-bg-55:after,
.red-bg-54:after,
.red-bg-53:after,
.red-bg-52:after,
.red-bg-51:after,
.red-bg-50:after,
.red-bg-49:after,
.red-bg-48:after,
.red-bg-47:after,
.red-bg-46:after,
.red-bg-45:after,
.red-bg-44:after,
.red-bg-43:after,
.red-bg-42:after,
.red-bg-41:after,
.red-bg-40:after,
.red-bg-39:after,
.red-bg-38:after,
.red-bg-37:after,
.red-bg-36:after,
.red-bg-35:after,
.red-bg-34:after,
.red-bg-33:after,
.red-bg-32:after,
.red-bg-31:after,
.red-bg-30:after,
.red-bg-29:after,
.red-bg-28:after,
.red-bg-27:after,
.red-bg-26:after,
.red-bg-25:after,
.red-bg-24:after,
.red-bg-23:after,
.red-bg-22:after,
.red-bg-21:after,
.red-bg-20:after,
.red-bg-19:after,
.red-bg-18:after,
.red-bg-17:after,
.red-bg-16:after,
.red-bg-15:after,
.red-bg-14:after,
.red-bg-13:after,
.red-bg-12:after,
.red-bg-11:after,
.red-bg-10:after,
.red-bg-9:after,
.red-bg-8:after,
.red-bg-7:after,
.red-bg-6:after,
.red-bg-5:after,
.red-bg-4:after,
.red-bg-3:after,
.red-bg-2:after,
.red-bg-1:after,
.red-bg-0:after,
.red-bg:after {
  position: absolute;
  content: "";
  background: #de262663;
  right: 0;
  top: 0;
  width: 100px;
  height: 45px;
}
.red-bg-100:after {
  width: 200px;
}
.red-bg-99:after {
  width: 198px;
}
.red-bg-98:after {
  width: 196px;
}
.red-bg-97:after {
  width: 194px;
}
.red-bg-96:after {
  width: 192px;
}
.red-bg-95:after {
  width: 190px;
}
.red-bg-94:after {
  width: 188px;
}
.red-bg-93:after {
  width: 186px;
}
.red-bg-92:after {
  width: 184px;
}
.red-bg-91:after {
  width: 182px;
}
.red-bg-90:after {
  width: 180px;
}
.red-bg-89:after {
  width: 178px;
}
.red-bg-88:after {
  width: 176px;
}
.red-bg-87:after {
  width: 174px;
}
.red-bg-86:after {
  width: 172px;
}
.red-bg-85:after {
  width: 170px;
}
.red-bg-84:after {
  width: 168px;
}
.red-bg-83:after {
  width: 166px;
}
.red-bg-82:after {
  width: 164px;
}
.red-bg-81:after {
  width: 162px;
}
.red-bg-80:after {
  width: 160px;
}
.red-bg-79:after {
  width: 158px;
}
.red-bg-78:after {
  width: 156px;
}
.red-bg-77:after {
  width: 154px;
}
.red-bg-76:after {
  width: 152px;
}
.red-bg-75:after {
  width: 150px;
}
.red-bg-74:after {
  width: 148px;
}
.red-bg-73:after {
  width: 146px;
}
.red-bg-72:after {
  width: 144px;
}
.red-bg-71:after {
  width: 142px;
}
.red-bg-70:after {
  width: 140px;
}
.red-bg-69:after {
  width: 138px;
}
.red-bg-68:after {
  width: 136px;
}
.red-bg-67:after {
  width: 134px;
}
.red-bg-66:after {
  width: 132px;
}
.red-bg-65:after {
  width: 130px;
}
.red-bg-64:after {
  width: 128px;
}
.red-bg-63:after {
  width: 126px;
}
.red-bg-62:after {
  width: 124px;
}
.red-bg-61:after {
  width: 122px;
}
.red-bg-60:after {
  width: 120px;
}
.red-bg-59:after {
  width: 118px;
}
.red-bg-58:after {
  width: 116px;
}
.red-bg-57:after {
  width: 114px;
}
.red-bg-56:after {
  width: 112px;
}
.red-bg-55:after {
  width: 110px;
}
.red-bg-54:after {
  width: 108px;
}
.red-bg-53:after {
  width: 106px;
}
.red-bg-52:after {
  width: 104px;
}
.red-bg-51:after {
  width: 102px;
}
.red-bg-50:after {
  width: 100px;
}
.red-bg-49:after {
  width: 98px;
}
.red-bg-48:after {
  width: 96px;
}
.red-bg-47:after {
  width: 94px;
}
.red-bg-46:after {
  width: 92px;
}
.red-bg-45:after {
  width: 90px;
}
.red-bg-44:after {
  width: 88px;
}
.red-bg-43:after {
  width: 86px;
}
.red-bg-42:after {
  width: 84px;
}
.red-bg-41:after {
  width: 82px;
}
.red-bg-40:after {
  width: 80px;
}
.red-bg-39:after {
  width: 78px;
}
.red-bg-38:after {
  width: 76px;
}
.red-bg-37:after {
  width: 74px;
}
.red-bg-36:after {
  width: 72px;
}
.red-bg-35:after {
  width: 70px;
}
.red-bg-34:after {
  width: 68px;
}
.red-bg-33:after {
  width: 66px;
}
.red-bg-32:after {
  width: 64px;
}
.red-bg-31:after {
  width: 62px;
}
.red-bg-30:after {
  width: 60px;
}
.red-bg-29:after {
  width: 58px;
}
.red-bg-28:after {
  width: 56px;
}
.red-bg-27:after {
  width: 54px;
}
.red-bg-26:after {
  width: 52px;
}
.red-bg-25:after {
  width: 50px;
}
.red-bg-24:after {
  width: 48px;
}
.red-bg-23:after {
  width: 46px;
}
.red-bg-22:after {
  width: 44px;
}
.red-bg-21:after {
  width: 42px;
}
.red-bg-20:after {
  width: 40px;
}
.red-bg-19:after {
  width: 38px;
}
.red-bg-18:after {
  width: 36px;
}
.red-bg-17:after {
  width: 34px;
}
.red-bg-16:after {
  width: 32px;
}
.red-bg-15:after {
  width: 30px;
}
.red-bg-14:after {
  width: 28px;
}
.red-bg-13:after {
  width: 26px;
}
.red-bg-12:after {
  width: 24px;
}
.red-bg-11:after {
  width: 22px;
}
.red-bg-10:after {
  width: 20px;
}
.red-bg-9:after {
  width: 18px;
}
.red-bg-8:after {
  width: 16px;
}
.red-bg-7:after {
  width: 14px;
}
.red-bg-6:after {
  width: 12px;
}
.red-bg-5:after {
  width: 10px;
}
.red-bg-4:after {
  width: 8px;
}
.red-bg-3:after {
  width: 6px;
}
.red-bg-2:after {
  width: 4px;
}
.red-bg-1:after {
  width: 2px;
}
.red-bg-0:after {
  width: 0px;
}

.green-bg-100:after,
.green-bg-99:after,
.green-bg-98:after,
.green-bg-97:after,
.green-bg-96:after,
.green-bg-95:after,
.green-bg-94:after,
.green-bg-93:after,
.green-bg-92:after,
.green-bg-91:after,
.green-bg-90:after,
.green-bg-89:after,
.green-bg-88:after,
.green-bg-87:after,
.green-bg-86:after,
.green-bg-85:after,
.green-bg-84:after,
.green-bg-83:after,
.green-bg-82:after,
.green-bg-81:after,
.green-bg-80:after,
.green-bg-79:after,
.green-bg-78:after,
.green-bg-77:after,
.green-bg-76:after,
.green-bg-75:after,
.green-bg-74:after,
.green-bg-73:after,
.green-bg-72:after,
.green-bg-71:after,
.green-bg-70:after,
.green-bg-69:after,
.green-bg-68:after,
.green-bg-67:after,
.green-bg-66:after,
.green-bg-65:after,
.green-bg-64:after,
.green-bg-63:after,
.green-bg-62:after,
.green-bg-61:after,
.green-bg-60:after,
.green-bg-59:after,
.green-bg-58:after,
.green-bg-57:after,
.green-bg-56:after,
.green-bg-55:after,
.green-bg-54:after,
.green-bg-53:after,
.green-bg-52:after,
.green-bg-51:after,
.green-bg-50:after,
.green-bg-49:after,
.green-bg-48:after,
.green-bg-47:after,
.green-bg-46:after,
.green-bg-45:after,
.green-bg-44:after,
.green-bg-43:after,
.green-bg-42:after,
.green-bg-41:after,
.green-bg-40:after,
.green-bg-39:after,
.green-bg-38:after,
.green-bg-37:after,
.green-bg-36:after,
.green-bg-35:after,
.green-bg-34:after,
.green-bg-33:after,
.green-bg-32:after,
.green-bg-31:after,
.green-bg-30:after,
.green-bg-29:after,
.green-bg-28:after,
.green-bg-27:after,
.green-bg-26:after,
.green-bg-25:after,
.green-bg-24:after,
.green-bg-23:after,
.green-bg-22:after,
.green-bg-21:after,
.green-bg-20:after,
.green-bg-19:after,
.green-bg-18:after,
.green-bg-17:after,
.green-bg-16:after,
.green-bg-15:after,
.green-bg-14:after,
.green-bg-13:after,
.green-bg-12:after,
.green-bg-11:after,
.green-bg-10:after,
.green-bg-9:after,
.green-bg-8:after,
.green-bg-7:after,
.green-bg-6:after,
.green-bg-5:after,
.green-bg-4:after,
.green-bg-3:after,
.green-bg-2:after,
.green-bg-1:after,
.green-bg-0:after,
.green-bg:after {
  position: absolute;
  content: "";
  background: #26de8163;
  right: 0;
  top: 0;
  width: 280px;
  height: 45px;
}
.green-bg-100:after {
  width: 200px;
}
.green-bg-99:after {
  width: 198px;
}
.green-bg-98:after {
  width: 196px;
}
.green-bg-97:after {
  width: 194px;
}
.green-bg-96:after {
  width: 192px;
}
.green-bg-95:after {
  width: 190px;
}
.green-bg-94:after {
  width: 188px;
}
.green-bg-93:after {
  width: 186px;
}
.green-bg-92:after {
  width: 184px;
}
.green-bg-91:after {
  width: 182px;
}
.green-bg-90:after {
  width: 180px;
}
.green-bg-89:after {
  width: 178px;
}
.green-bg-88:after {
  width: 176px;
}
.green-bg-87:after {
  width: 174px;
}
.green-bg-86:after {
  width: 172px;
}
.green-bg-85:after {
  width: 170px;
}
.green-bg-84:after {
  width: 168px;
}
.green-bg-83:after {
  width: 166px;
}
.green-bg-82:after {
  width: 164px;
}
.green-bg-81:after {
  width: 162px;
}
.green-bg-80:after {
  width: 160px;
}
.green-bg-79:after {
  width: 158px;
}
.green-bg-78:after {
  width: 156px;
}
.green-bg-77:after {
  width: 154px;
}
.green-bg-76:after {
  width: 152px;
}
.green-bg-75:after {
  width: 150px;
}
.green-bg-74:after {
  width: 148px;
}
.green-bg-73:after {
  width: 146px;
}
.green-bg-72:after {
  width: 144px;
}
.green-bg-71:after {
  width: 142px;
}
.green-bg-70:after {
  width: 140px;
}
.green-bg-69:after {
  width: 138px;
}
.green-bg-68:after {
  width: 136px;
}
.green-bg-67:after {
  width: 134px;
}
.green-bg-66:after {
  width: 132px;
}
.green-bg-65:after {
  width: 130px;
}
.green-bg-64:after {
  width: 128px;
}
.green-bg-63:after {
  width: 126px;
}
.green-bg-62:after {
  width: 124px;
}
.green-bg-61:after {
  width: 122px;
}
.green-bg-60:after {
  width: 120px;
}
.green-bg-59:after {
  width: 118px;
}
.green-bg-58:after {
  width: 116px;
}
.green-bg-57:after {
  width: 114px;
}
.green-bg-56:after {
  width: 112px;
}
.green-bg-55:after {
  width: 110px;
}
.green-bg-54:after {
  width: 108px;
}
.green-bg-53:after {
  width: 106px;
}
.green-bg-52:after {
  width: 104px;
}
.green-bg-51:after {
  width: 102px;
}
.green-bg-50:after {
  width: 100px;
}
.green-bg-49:after {
  width: 98px;
}
.green-bg-48:after {
  width: 96px;
}
.green-bg-47:after {
  width: 94px;
}
.green-bg-46:after {
  width: 92px;
}
.green-bg-45:after {
  width: 90px;
}
.green-bg-44:after {
  width: 88px;
}
.green-bg-43:after {
  width: 86px;
}
.green-bg-42:after {
  width: 84px;
}
.green-bg-41:after {
  width: 82px;
}
.green-bg-40:after {
  width: 80px;
}
.green-bg-39:after {
  width: 78px;
}
.green-bg-38:after {
  width: 76px;
}
.green-bg-37:after {
  width: 74px;
}
.green-bg-36:after {
  width: 72px;
}
.green-bg-35:after {
  width: 70px;
}
.green-bg-34:after {
  width: 68px;
}
.green-bg-33:after {
  width: 66px;
}
.green-bg-32:after {
  width: 64px;
}
.green-bg-31:after {
  width: 62px;
}
.green-bg-30:after {
  width: 60px;
}
.green-bg-29:after {
  width: 58px;
}
.green-bg-28:after {
  width: 56px;
}
.green-bg-27:after {
  width: 54px;
}
.green-bg-26:after {
  width: 52px;
}
.green-bg-25:after {
  width: 50px;
}
.green-bg-24:after {
  width: 48px;
}
.green-bg-23:after {
  width: 46px;
}
.green-bg-22:after {
  width: 44px;
}
.green-bg-21:after {
  width: 42px;
}
.green-bg-20:after {
  width: 40px;
}
.green-bg-19:after {
  width: 38px;
}
.green-bg-18:after {
  width: 36px;
}
.green-bg-17:after {
  width: 34px;
}
.green-bg-16:after {
  width: 32px;
}
.green-bg-15:after {
  width: 30px;
}
.green-bg-14:after {
  width: 28px;
}
.green-bg-13:after {
  width: 26px;
}
.green-bg-12:after {
  width: 24px;
}
.green-bg-11:after {
  width: 22px;
}
.green-bg-10:after {
  width: 20px;
}
.green-bg-9:after {
  width: 18px;
}
.green-bg-8:after {
  width: 16px;
}
.green-bg-7:after {
  width: 14px;
}
.green-bg-6:after {
  width: 12px;
}
.green-bg-5:after {
  width: 10px;
}
.green-bg-4:after {
  width: 8px;
}
.green-bg-3:after {
  width: 6px;
}
.green-bg-2:after {
  width: 4px;
}
.green-bg-1:after {
  width: 2px;
}
.green-bg-0:after {
  width: 0px;
}
</style>
