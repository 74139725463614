import { createApp } from "vue";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
// import "./assets/css/responsive.css";

import router from "./router";
import store from "./store";
// import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import App from "./App.vue";

import VueIframe from "vue-iframes";

import VueSocketIO from "vue-3-socket.io";
// import SocketIO from 'vue-3-socket.io';

import mitt from "mitt";
const emitter = mitt();

library.add(fas, far, fab);

const app = createApp(App);

app.config.globalProperties.emitter = emitter;

app.component("fa", FontAwesomeIcon);

app.use(store);
app.use(router);
app.use(VueIframe);
app.use(
  new VueSocketIO({
    debug: true,
    // connection: SocketIO(process.env.VUE_APP_WS)
    connection: process.env.VUE_APP_WS,
  })
);
app.mount("#app");
