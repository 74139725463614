<template>
  <div class="wrapper">
    <TopNav />

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h1 class="text-light">P2P Exchange</h1>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="d-flex flex-row-reverse">
            <button class="btn btn-primary mr-1">Filter</button>
            <button class="btn btn-primary mr-1">New</button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="text-left">Direction</th>
                <th class="text-left">Owner</th>
                <th>Price</th>
                <th>Offering</th>
                <th>Offering For</th>
                <th class="text-left">Action</th>
              </tr>

              <tr>
                <!-- direction (price) -->
                <th>
                  <div style="margin-bottom: 5px">
                    <table width="100%">
                      <tr>
                        <td style="text-align: center; font-weight: bold">
                          Direction
                        </td>
                        <td style="width: 28px">
                          <a href="">
                            <i
                              class="fa fa-arrow-up"
                              style="font-size: small"
                            ></i>
                          </a>
                          <a href="">
                            <i
                              class="fa fa-arrow-down"
                              style="font-size: small"
                            ></i>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="input_search_pair"
                    />
                    <div class="input-group-btn">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        @click="tableHeadDropdownElementShow('pair')"
                      >
                        {{ op_search_pair }}
                        <span class="fa fa-caret-down"></span>
                      </button>
                      <ul class="dropdown-menu" ref="pair-el-dropdown">
                        <a
                          class="dropdown-item"
                          @click="advFilterPutOp('equal', 'pair')"
                        >
                          equal
                        </a>
                        <a
                          class="dropdown-item"
                          @click="advFilterPutOp('contains', 'pair')"
                        >
                          contains
                        </a>
                      </ul>
                    </div>
                  </div>
                </th>

                <!-- owner -->
                <th>
                  <div style="margin-bottom: 5px">
                    <table width="100%">
                      <tr>
                        <td style="text-align: center; font-weight: bold">
                          Owner
                        </td>
                        <td style="width: 28px">
                          <a href="">
                            <i
                              class="fa fa-arrow-up"
                              style="font-size: small"
                            ></i>
                          </a>
                          <a href="">
                            <i
                              class="fa fa-arrow-down"
                              style="font-size: small"
                            ></i>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="input_search_owner"
                    />
                    <div class="input-group-btn">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        @click="tableHeadDropdownElementShow('owner')"
                      >
                        {{ op_search_owner }}
                        <span class="fa fa-caret-down"></span>
                      </button>
                      <ul class="dropdown-menu" ref="owner-el-dropdown">
                        <a
                          class="dropdown-item"
                          @click="advFilterPutOp('equal', 'owner')"
                        >
                          equal
                        </a>
                        <a
                          class="dropdown-item"
                          @click="advFilterPutOp('contains', 'owner')"
                        >
                          contains
                        </a>
                      </ul>
                    </div>
                  </div>
                </th>

                <!-- price -->
                <th>
                  <div style="margin-bottom: 5px">
                    <table width="100%">
                      <tr>
                        <td style="text-align: center; font-weight: bold">
                          Price
                        </td>
                        <td style="width: 28px">
                          <a href="">
                            <i
                              class="fa fa-arrow-up"
                              style="font-size: small"
                            ></i>
                          </a>
                          <a href="">
                            <i
                              class="fa fa-arrow-down"
                              style="font-size: small"
                            ></i>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="input_search_price_db"
                    />
                    <div class="input-group-btn">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        @click="tableHeadDropdownElementShow('price_db')"
                      >
                        {{ op_search_price_db }}
                        <span class="fa fa-caret-down"></span>
                      </button>
                      <ul class="dropdown-menu" ref="price_db-el-dropdown">
                        <a
                          class="dropdown-item"
                          @click="advFilterPutOp('equal-num', 'price_db')"
                        >
                          equal-num
                        </a>
                        <a
                          class="dropdown-item"
                          @click="advFilterPutOp('less', 'price_db')"
                        >
                          less
                        </a>
                        <a
                          class="dropdown-item"
                          @click="advFilterPutOp('greater', 'price_db')"
                        >
                          greater
                        </a>
                      </ul>
                    </div>
                  </div>
                </th>

                <!-- offering (qty_a) -->
                <th>
                  <div style="margin-bottom: 5px">
                    <table width="100%">
                      <tr>
                        <td style="text-align: center; font-weight: bold">
                          Offering
                        </td>
                        <td style="width: 28px">
                          <a href="">
                            <i
                              class="fa fa-arrow-up"
                              style="font-size: small"
                            ></i>
                          </a>
                          <a href="">
                            <i
                              class="fa fa-arrow-down"
                              style="font-size: small"
                            ></i>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="input_search_qty_a"
                    />
                    <div class="input-group-btn">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        @click="tableHeadDropdownElementShow('qty_a')"
                      >
                        {{ op_search_qty_a }}
                        <span class="fa fa-caret-down"></span>
                      </button>
                      <ul class="dropdown-menu" ref="qty_a-el-dropdown">
                        <a
                          class="dropdown-item"
                          @click="advFilterPutOp('equal-num', 'qty_a')"
                        >
                          equal-num
                        </a>
                        <a
                          class="dropdown-item"
                          @click="advFilterPutOp('less', 'qty_a')"
                        >
                          less
                        </a>
                        <a
                          class="dropdown-item"
                          @click="advFilterPutOp('greater', 'qty_a')"
                        >
                          greater
                        </a>
                      </ul>
                    </div>
                  </div>
                </th>

                <!-- offering_for (qty_b) -->
                <th>
                  <div style="margin-bottom: 5px">
                    <table width="100%">
                      <tr>
                        <td style="text-align: center; font-weight: bold">
                          Offering For
                        </td>
                        <td style="width: 28px">
                          <a href="">
                            <i
                              class="fa fa-arrow-up"
                              style="font-size: small"
                            ></i>
                          </a>
                          <a href="">
                            <i
                              class="fa fa-arrow-down"
                              style="font-size: small"
                            ></i>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="input_search_qty_b"
                    />
                    <div class="input-group-btn">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        @click="tableHeadDropdownElementShow('qty_b')"
                      >
                        {{ op_search_qty_b }}
                        <span class="fa fa-caret-down"></span>
                      </button>
                      <ul class="dropdown-menu" ref="qty_b-el-dropdown">
                        <a
                          class="dropdown-item"
                          @click="advFilterPutOp('equal-num', 'qty_b')"
                        >
                          equal-num
                        </a>
                        <a
                          class="dropdown-item"
                          @click="advFilterPutOp('less', 'qty_b')"
                        >
                          less
                        </a>
                        <a
                          class="dropdown-item"
                          @click="advFilterPutOp('greater', 'qty_b')"
                        >
                          greater
                        </a>
                      </ul>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in obooks" :key="item.id">
                <td v-if="item.market_obj != null">
                  <span v-if="item.table == 'sells'">
                    {{ item.market_obj.pair.split("-")[0] }}
                    &#8592;
                    {{ item.market_obj.pair.split("-")[1] }}
                  </span>
                  <span v-else>
                    {{ item.market_obj.pair.split("-")[0] }}
                    &#8594;
                    {{ item.market_obj.pair.split("-")[1] }}
                  </span>
                </td>
                <td>
                  {{ themain.shortAddress(item.owner) }}
                </td>
                <td align="right">
                  {{ item.price_db }}
                </td>
                <td align="right">
                  {{ parseFloat(item.qty_a_db).toFixed(6) }}
                </td>
                <td align="right">
                  {{ parseFloat(item.qty_b_db).toFixed(6) }}
                </td>
                <td>
                  <span v-if="item.table == 'sells'">
                    <router-link
                      class="btn btn-primary btn-sm"
                      :to="`/p2p/swap-buy?sc_address=${item.sc_address}&market_id_bc=${item.market_id_bc}&buy_or_sell=${item.table}&id_bc=${item.id_bc}`"
                    >
                      Swap
                    </router-link>
                  </span>
                  <span v-else>
                    <router-link
                      class="btn btn-primary btn-sm"
                      :to="`/p2p/swap-sell?sc_address=${item.sc_address}&market_id_bc=${item.market_id_bc}&buy_or_sell=${item.table}&id_bc=${item.id_bc}`"
                    >
                      Swap
                    </router-link>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="footer_sc">
      <Footer />
    </div>
  </div>
</template>

<script>
// node_modules
import { toRaw } from "vue";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {
      themain,
      obooks: [
        {
          market_obj: {
            id: 0,
            pair: "A-B",
          },
        },
      ],

      input_search_pair: null,
      op_search_pair: "-",

      input_search_price_db: null,
      op_search_price_db: "-",

      input_search_owner: null,
      op_search_owner: null,

      input_search_qty_a: null,
      op_search_qty_a: null,

      input_search_qty_b: null,
      op_search_qty_b: null,

      filter_rules: [],
    };
  },

  async beforeMount() {
    var self = this;
    themain.beforeMountConnectToWallet(self);
    themain.networkAlert();
    themain.networkChanged(self);
    themain.accountChanged(self);
  },

  async mounted() {
    var self = this;

    await self.getObooks();
  },

  methods: {
    async getObooks() {
      var self = this;
      var res = await thestore.getObooks(
        1,
        10,
        "id",
        "desc",
        self.filter_rules
      );
      self.obooks = res.datas;
    },

    tableHeadDropdownElementShow(element) {
      var self = this;

      // the ref name
      const refName = `${element}-el-dropdown`;

      if (self.$refs[refName].classList.contains("show")) {
        self.$refs[`${element}-el-dropdown`].classList.remove("show");
      } else {
        self.$refs[`${element}-el-dropdown`].classList.add("show");
      }
    },

    advFilterPutOp(operation, field) {
      var self = this;

      try {
        // Element itu sama dengan field
        // Jadi disini element karena toggle, kita panggil sekali lagi supaya hide
        self.tableHeadDropdownElementShow(field);

        console.log("operation", operation);
        console.log("field", field);

        const currentFilterRules = toRaw(self.filter_rules);
        console.log("currentFilterRules", currentFilterRules);

        // Cari field, jika sudah ada maka update.
        // Jika belum ada, tambahkan
        const cari = currentFilterRules.filter(
          (oneData) => oneData["field"] == field
        );
        console.log("cari.length", cari.length);
        if (cari.length > 0) {
          if (self[`input_search_${field}`] != null) {
            // Mengeluarkan field yang dicari dari array filter_rules
            const newArr = currentFilterRules.filter(
              (oneData) => oneData.field !== field
            );

            console.log("newArr", newArr);

            // memasukkan field yang baru beserta operation-nya
            newArr.push({
              field: field,
              op: operation,
              value: self[`input_search_${field}`],
            });

            // mengganti filter_rules
            self.filter_rules = newArr;

            // operator
            self[`op_search_${field}`] = operation;
          } else {
            console.log("null-log");

            // Mengeluarkan field yang dicari dari array filter_rules (ini sama dengan mereset search untuk field ini)
            self.filter_rules = currentFilterRules.filter(
              (oneData) => oneData.field !== field
            );

            // operator
            self[`op_search_${field}`] = "-";
          }
        } else {
          if (self[`input_search_${field}`] != null) {
            // disini langsung tambahkan saja kedalam filter_rules
            self.filter_rules.push({
              field: field,
              op: operation,
              value: self[`input_search_${field}`],
            });

            // operator
            self[`op_search_${field}`] = operation;
          } else {
            // Mengeluarkan field yang dicari dari array filter_rules (ini sama dengan mereset search untuk field ini)
            self.filter_rules = currentFilterRules.filter(
              (oneData) => oneData.field !== field
            );

            // operator
            self[`op_search_${field}`] = "-";
          }
        }

        console.log("self.filter_rules", self.filter_rules);

        self.getObooks();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.dropdown-menu {
  color: #fff;
}
</style>
