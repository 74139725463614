// node_modules
import axios from "axios";

const utama = async function (
  address,
  marketId,
  qtyA,
  price,
  deadline,
  v,
  r,
  s,
  ethSend,
  pk
) {
  const res = await axios({
    method: "post",
    url: `${process.env.VUE_APP_BASE_BOT}/exchange/set-sell`,
    data: {
      address,
      marketId,
      qtyA,
      price,
      deadline,
      v,
      r,
      s,
      ethSend,
      pk,
    },
  });
  console.log("res", res);
  return res;
};

export default utama;
