<template>
  <div class="wrapper_w">
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <div class="wallet_d tab-content" id="v-pills-tabContent">
              <div v-if="cryptos.length">
                <div
                  v-for="(item, i) in cryptos"
                  :key="item.id"
                  class="tab-pane fade show active"
                  id="settings-wallet"
                  role="tabpanel"
                  aria-labelledby="settings-wallet-tab"
                >
                  <div class="wallet_item">
                    <div class="w_card">
                      <div class="d-flex w_itm">
                        <div class="w_itm-c">
                          <img
                            :src="`data:image/svg+xml;base64, ${item.b64img}`"
                          />
                          <div class="w_symbol">
                            <h2>{{ item.simbol }}</h2>
                            <p>{{ item.name }}</p>
                          </div>
                        </div>
                        <div class="w_right">
                          <h3>{{ item.balance }}</h3>

                          <div class="btn_wrapper">
                            <a
                              :href="`${explorer}/address/${item.address}`"
                              target="_blank"
                              class="btn_d"
                            >
                              Explorer
                            </a>
                            <router-link
                              :to="`/coins/detail?address=${item.address}`"
                              class="btn_d"
                            >
                              Detail
                            </router-link>
                            <router-link
                              :to="`/coins/detail?address=${item.address}`"
                              class="btn_d"
                            >
                              Website
                            </router-link>
                          </div>

                          <!-- menu pada mobile view -->
                          <div @click="handleShowMenu(i)" class="wallet_m">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="4"
                              viewBox="0 0 18 4"
                              fill="none"
                            >
                              <path
                                d="M9 0C8.60444 0 8.21776 0.117298 7.88886 0.337061C7.55996 0.556824 7.30362 0.869181 7.15224 1.23463C7.00087 1.60009 6.96126 2.00222 7.03843 2.39018C7.1156 2.77814 7.30608 3.13451 7.58579 3.41421C7.86549 3.69392 8.22186 3.8844 8.60982 3.96157C8.99778 4.03874 9.39992 3.99913 9.76537 3.84776C10.1308 3.69638 10.4432 3.44004 10.6629 3.11114C10.8827 2.78224 11 2.39556 11 2C11 1.46957 10.7893 0.96086 10.4142 0.585787C10.0391 0.210714 9.53043 0 9 0ZM2 0C1.60444 0 1.21776 0.117298 0.88886 0.337061C0.559962 0.556824 0.303617 0.869181 0.152242 1.23463C0.000866562 1.60009 -0.0387401 2.00222 0.0384303 2.39018C0.115601 2.77814 0.306082 3.13451 0.585787 3.41421C0.865492 3.69392 1.22186 3.8844 1.60982 3.96157C1.99778 4.03874 2.39992 3.99913 2.76537 3.84776C3.13082 3.69638 3.44318 3.44004 3.66294 3.11114C3.8827 2.78224 4 2.39556 4 2C4 1.46957 3.78929 0.96086 3.41421 0.585787C3.03914 0.210714 2.53043 0 2 0ZM16 0C15.6044 0 15.2178 0.117298 14.8889 0.337061C14.56 0.556824 14.3036 0.869181 14.1522 1.23463C14.0009 1.60009 13.9613 2.00222 14.0384 2.39018C14.1156 2.77814 14.3061 3.13451 14.5858 3.41421C14.8655 3.69392 15.2219 3.8844 15.6098 3.96157C15.9978 4.03874 16.3999 3.99913 16.7654 3.84776C17.1308 3.69638 17.4432 3.44004 17.6629 3.11114C17.8827 2.78224 18 2.39556 18 2C18 1.46957 17.7893 0.96086 17.4142 0.585787C17.0391 0.210714 16.5304 0 16 0Z"
                                fill="white"
                              />
                            </svg>

                            <div
                              v-if="i == numSelected"
                              :class="{ show_menu: showMenu }"
                              @mouseleave="showMenu = false"
                              class="w_menus"
                            >
                              <a
                                :href="`${explorer}/address/${item.address}`"
                                target="_blank"
                                class="btn_m"
                              >
                                Explorer
                              </a>
                              <router-link
                                :to="`/coins/detail?address=${item.address}`"
                                class="btn_m"
                              >
                                Detail
                              </router-link>
                              <router-link
                                :to="`/coins/detail?address=${item.address}`"
                                class="btn_m"
                              >
                                Website
                              </router-link>
                            </div>
                          </div>
                          <!-- <p class="text-right"><i class="icon ion-md-lock"></i> 0.0000000</p> -->
                        </div>
                      </div>
                      <div v-if="i != 1" class="border-bottom"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-else
                class="d-flex justify-content-center align-items-center"
                style="height: 15rem"
              >
                <h5>No Wallets Available</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookie from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";
import balanceObj from "@/applibs/erc20/balance-obj.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";

export default {
  components: {
    TopNav,
    Footer,
    Menu,
  },
  data() {
    return {
      themain,
      connectedAddress: Cookie.get("connectedAddress"),
      cryptos: [],

      showMenu: false,
      numSelected: null,
    };
  },

  async beforeMount() {
    var self = this;
    themain.beforeMountConnectToWallet(self);
    themain.networkAlert();
    themain.networkChanged(self);
    themain.accountChanged(self);
  },

  async mounted() {
    var self = this;
    await self.getCryptos();
  },

  methods: {
    async getCryptos() {
      var self = this;
      var res = await thestore.getCryptos(1, 20, "id", "desc", []);
      // self.cryptos = res.datas; // dimatikan saja. biarkan agak lambat tampil karena jika tampil duluan, broken image

      // dari data yang didapat, kita
      var ups = [];
      var jumlah = res.datas.length;
      var hitung = 0;
      res.datas.forEach(async (el) => {
        hitung = hitung + 1;
        // ambil balance
        var token = await balanceObj(self.connectedAddress, el.address);

        var nudata = el;
        nudata.balance = token["balance"];

        var imgText = themain.iconB64Text(el.address.slice(2, 42));
        nudata["b64img"] = imgText;

        ups.push(nudata);

        // harus diakali seperti ini.
        // kalau tidak, maka render akan terjadi sebelum bagian loop ini selesai dan data tidak ter-load sempurna
        if (hitung == jumlah) {
          self.cryptos = [];
          self.cryptos = ups;
        }
      });
    },

    handleShowMenu(val) {
      var self = this;

      self.numSelected = val;
      self.showMenu = !self.showMenu;
    },
  },
};
</script>

<style scoped>
.wrapper_w {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.w_card {
  width: 100%;
  /* background-color: yellow; */
}

.w_itm {
  justify-content: space-between;
  padding: 10px 0;
}

.w_itm-c {
  display: flex;
  align-items: center;
  gap: 20px;
}

.w_itm-c img {
  width: 45px;
  height: 45px;
}

.w_symbol h2 {
  font-size: 19px;
  margin: 0;
  padding: 0;
}

.w_symbol p {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.w_right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.w_right h3 {
  font-size: 19px;
  margin: 0;
  padding: 0;
}

.btn_d {
  margin-right: 5px;
  height: 30px;
  border: 1px solid #28a745;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  color: #28a745;
}

.btn_d:hover {
  border: 1px solid #14752b;
  color: #14752b;
}

.row_w {
  display: flex;
}

.wallet_d {
  width: 100%;
  background-color: #1e222d;
  border-radius: 5px;
  color: aliceblue;
  padding: 5px 10px;

  display: flex;
  flex-direction: column;
}

.wallet_m {
  display: none;
}

@media only screen and (max-width: 993px) {
  .row_w {
    flex-direction: column;
  }

  .btn_wrapper {
    display: none;
  }

  .wallet_m {
    display: flex;
    cursor: pointer;
    position: relative;
  }

  .w_menus {
    position: absolute;
    top: 3%;
    right: 25%;
    transform: translate(-25%, -3%);
    background-color: #161a1f;
    border: 1px solid #262930;
    border-radius: 4px;
    display: none;
  }

  .btn_m {
    padding: 2px 10px;
    color: aliceblue;
  }
  .btn_m:hover {
    background-color: #ff666d;
  }

  .show_menu {
    display: flex !important;
    flex-direction: column;
  }
}
</style>
