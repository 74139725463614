import jsonInterface from "./abi";
import themain from "@/applibs/themain.js";
import Web3 from "web3";

var utama = async function (
  fromAddress,
  marketId,
  qtyB,
  price,
  deadline,
  v,
  r,
  s,
  ethSend
) {
  try {
    const chainId = themain.getChosenChainId();
    const chainObj = themain.getChosenChainObj(chainId);

    const web3 = new Web3(window.ethereum);

    var myContract = new web3.eth.Contract(jsonInterface, chainObj.sc_address);

    var hasil = await myContract.methods
      .setBuy(marketId, qtyB, price, deadline, v, r, s)
      .send({
        from: fromAddress,
        value: ethSend,
      });

    return hasil;
  } catch (error) {
    console.log(error);
  }
};

export default utama;
