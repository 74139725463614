<template>
  <div
    :class="`d-flex flex-column ${
      isSender ? 'align-items-end' : 'align-items-start'
    }`"
  >
    <div :class="`bubble_chat p-3 ${isSender ? 'bg_sender' : 'bg_receiver'}`">
      {{ messageText }}
    </div>
  </div>
</template>

<script scoped>
export default {
  props: {
    isSender: Boolean,
    messageText: String,
  },

  data() {
    return {};
  },

  mounted() {
    console.log(typeof this.isSender);
  },
};
</script>

<style scoped>
.bg_sender {
  background-color: #ff6c6e;
}

.bg_receiver {
  background-color: #3b404e;
}

.bubble_chat {
  border-radius: 1rem;
  max-width: 70%;
  word-wrap: break-word;
}
</style>
