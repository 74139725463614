<template>
  <div class="wrapper">
    <TopNav />

    <div class="container text-white">
      <div class="chat_container mx-auto">
        <div class="message_container">
          <div v-for="chat of chats" :key="chat.id">
            <Bubble :isSender="chat.isSender" :messageText="chat.messageText" />
          </div>
        </div>

        <div class="d-flex p-3">
          <input v-model="messageText" type="text" class="input_chat" />
          <button @click="sendChat(true)" class="btn-gradient button_chat">Send</button>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules

// applibs

import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Bubble from "@/components/chat/Bubble.vue";

export default {
  components: {
    TopNav,
    Footer,
    Bubble,
  },

  data() {
    return {
      messageText: "",
      isSender: null,
      chats: [
        {
          id: 1,
          messageText: "test chat message",
          isSender: true,
        },
        {
          id: 2,
          messageText: "test chat message",
          isSender: false,
        },
      ],
    };
  },

  methods: {
    sendChat(isSender) {
      if (this.messageText !== "") {
        this.chats.push({
          id: this.chats.length + 1,
          messageText: this.messageText,
          isSender: isSender,
        });
        this.messageText = "";
      }
    },
  },
};
</script>

<style scoped>
.chat_container {
  width: 75%;
  background-color: #1e222d;
}

.message_container {
  height: 35rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  overflow-y: auto;
}

.wrapper {
  min-height: 100vh;
}

.input_chat {
  /* width: 100%; */
  flex-grow: 1;
  margin-right: 0.7rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  background-color: rgb(238, 238, 238);
}

.input_chat:focus {
  outline: none;
}

.button_chat {
  border-radius: 0.5rem;
}
</style>
