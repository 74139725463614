<template>
  <div>
    <div class="markets-pair-list">
      <table class="table">
        <thead>
          <tr>
            <th class="text-left">MarketID</th>
            <th class="text-left">Pair</th>
            <th class="text-left">Coin</th>
            <th>Last Price</th>
            <th>Change (24H)</th>
            <th>High (24H)</th>
            <th>Low (24H)</th>
            <th>Volume</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in markets" :key="item.id">
            <td @click="gotoMarket(item)">#{{ item.market_id_bc }}</td>
            <td @click="gotoMarket(item)">
              {{ item.pair_show }}
            </td>
            <td @click="gotoMarket(item)">
              <img
                width="40"
                height="40"
                :src="`data:image/svg+xml;base64, ${item.b64img}`"
              />
              {{ item.simbols[0] }}
            </td>
            <td
              @click="gotoMarket(item)"
              :class="`text-right ${item.theclass}`"
            >
              {{ item.last_price }}
            </td>
            <td
              @click="gotoMarket(item)"
              :class="`text-right ${item.theclass}`"
            >
              {{
                themain.angkaDenganPersentaseBesar(parseFloat(item.change_24h))
              }}
              %
            </td>
            <td @click="gotoMarket(item)" class="text-right">
              {{ item.high_24h }}
            </td>
            <td @click="gotoMarket(item)" class="text-right">
              {{ item.low_24h }}
            </td>
            <td @click="gotoMarket(item)" class="text-right">
              {{ parseFloat(item.vol_24h).toFixed(2) }} {{ item.simbols[1] }}
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="text-light btn btn-sm btn-gradient mt-2"
        @click="loadMorePage"
      >
        <span v-if="!loading"> Load More </span>

        <span
          v-else
          class="spinner-border spinner-border-sm"
          aria-hidden="true"
        ></span>
      </div>
    </div>

    <div class="markets-pair-list-mobile">
      <table class="table">
        <tbody>
          <tr v-for="item in markets" :key="item.id">
            <td @click="gotoMarket(item)">
              <img
                width="40"
                height="40"
                :src="`data:image/svg+xml;base64, ${item.b64img}`"
              />
            </td>
            <td @click="gotoMarket(item)">
              #{{ item.market_id_bc }} {{ item.pair_show }} <br />

              <span :class="item.theclass">
                {{ item.last_price }}
              </span>
            </td>
            <!-- <td @click="gotoMarket(item)" class="text-right">
            GRAFIK DISINI KALAU SEMPAT  
            </td> -->
            <td @click="gotoMarket(item)" class="text-right">
              {{
                themain.angkaDenganPersentaseBesar(parseFloat(item.change_24h))
              }}
              % <br />
              {{ parseFloat(item.vol_24h).toFixed(2) }} {{ item.simbols[1] }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="d-flex justify-content-center">
        <div
          class="text-light btn btn-sm btn-gradient mt-2"
          @click="loadMorePage"
        >
          <span v-if="!loading"> Load More </span>

          <span
            v-else
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules
import Cookie from "js-cookie";
import { toRaw } from "vue";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components

export default {
  components: {},
  data() {
    return {
      themain,
      markets: [],
      nomarkets: [],
      pageObj: {
        page: 1,
        rows: 10,
        sort: "id",
        order: "asc",
        filter_rules: [],
      },
      loading: false,
    };
  },

  async mounted() {
    var self = this;

    try {
      // ambil config untuk market yang di hide oleh user ini
      if (
        Cookie.get("connectedAddress") != null &&
        Cookie.get("connectedAddress") != ""
      ) {
        await self.getUsermarketconfigs();
      }

      await self.getMarkets();

      self.emitter.on("fe-market_update", async function (e) {
        if (e.data) {
          var lamas = self.markets; // tampung data lama
          self.markets = []; // kosongkan markets
          var barus = [];

          lamas.forEach((ele) => {
            if (ele.id == e.data.id) {
              barus.push(e.data);
            } else {
              barus.push(ele);
            }
          });
          await self.upgradeMarkets(barus);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  },
  methods: {
    async getMarkets() {
      var self = this;
      var res = await thestore.getMarkets(
        self.pageObj.page,
        self.pageObj.rows,
        self.pageObj.sort,
        self.pageObj.order,
        self.pageObj.filter_rules
      );
      await self.upgradeMarkets(res.datas);
    },

    async getUsermarketconfigs() {
      var self = this;
      var res = await thestore.getUsermarketconfigs(1, 50, "id", "desc", [
        {
          field: "user_address",
          op: "equal",
          value: `${Cookie.get("connectedAddress").toLowerCase()}`,
        },
      ]);
      self.nomarkets = res.datas;
    },

    /**
     * Setelah markets, kita upgrade dengan menempatkan class dan
     * image unique-nya. Disini juga kita hide market yang tidak ingin dilihat oleh user ini
     */
    async upgradeMarkets(plain_datas) {
      var self = this;

      // data harus kita olah lagi
      var markets = [];
      plain_datas.forEach((ele) => {
        var showMe = self.showMarket(ele);

        if (showMe == true) {
          // parsing
          var simbols = ele.pair.split("-");
          ele["pair_show"] = `${simbols[0]}/${simbols[1]}`;
          ele["simbols"] = simbols;

          var imgText = themain.iconB64Text(ele.a_address.slice(2, 42));
          ele["b64img"] = imgText;

          if (ele.change_24h >= 0) {
            ele["theclass"] = "green";
          } else {
            ele["theclass"] = "red";
          }

          markets.push(ele);
        }
      });

      self.markets = markets;
    },

    /**
     * Untuk mengarahkan ke halaman exchange yang dipilih
     * @param {*} item object market
     */
    gotoMarket(item) {
      var self = this;
      // alert(item.mtipe);
      self.$router.push({
        path: `/${themain.getChosenChainId()}/markets/${item.sc_address}/${
          item.market_id_bc
        }`,
        query: {
          pair: item.pair,
          mtipe: item.mtipe,
        },
      });
    },

    /**
     * Function ini hanya dipanggil dari dalam `upgradeMarkets`
     * Function ini untuk menyembunyikan market yang tidak ingin dilihat oleh user
     * @param {*} mdat
     */
    showMarket(mdat) {
      var self = this;
      // jika kembali atau show default nya adalah true
      var kembali = true;

      // jika ada daftar market yang tidak ingin dilihat, dilakukan perulangan
      self.nomarkets.forEach(function (ele) {
        if (
          mdat.sc_address.toLowerCase() == ele.sc_address.toLowerCase() &&
          parseInt(mdat.market_id_bc) == parseInt(ele.market_id_bc)
        ) {
          // jika ketemu market yang tidak ingin dilihat, disembunyikan disini
          kembali = false;
        } else {
          kembali = true;
        }
      });
      return kembali;
    },

    async loadMorePage() {
      var self = this;
      this.loading = true;

      self.pageObj.page = self.pageObj.page + 1;

      var res = await thestore.getMarkets(
        self.pageObj.page,
        self.pageObj.rows,
        self.pageObj.sort,
        self.pageObj.order,
        self.pageObj.filter_rules
      );

      // remove proxy
      var oldData = [];
      self.markets.forEach(function (ele) {
        oldData.push(toRaw(ele));
      });
      console.log("oldData", oldData);

      var newData = oldData.concat(res.datas);
      console.log("newData", newData);

      self.upgradeMarkets(newData);

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.markets-pair-list {
  display: block;
}
.markets-pair-list-mobile {
  display: none;
}

@media only screen and (max-width: 884px) {
  /** mobile */
  .markets-pair-list {
    display: none;
  }
  .markets-pair-list-mobile {
    display: block;
  }
}
</style>
