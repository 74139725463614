import jsonInterface from "./abi";
import themain from "@/applibs/themain.js";
import Web3 from "web3";

var utama = async function (
  fromAddress,
  sellIds,
  qtyBs,
  feeAndQtyB,
  marketId,
  deadline,
  v,
  r,
  s
) {
  console.log("----- obj");
  console.log({
    fromAddress,
    sellIds,
    qtyBs,
    feeAndQtyB,
    marketId,
    deadline,
    v,
    r,
    s,
  });

  const chainId = themain.getChosenChainId();
  const chainObj = themain.getChosenChainObj(chainId);

  const web3 = new Web3(window.ethereum);

  var myContract = new web3.eth.Contract(jsonInterface, chainObj.sc_address);

  var hasil = await myContract.methods
    .buyPump(sellIds, qtyBs, `${marketId}`, deadline, v, r, s)
    .send({
      from: fromAddress,
      value: feeAndQtyB,
    });

  return hasil;
};

export default utama;
