<template>
  <div class="col-md-12 text-light">
    <!-- hanya muncul pada big screen -->
    <div class="sum-big-screen">
      <div class="row wrapper_row">
        <div class="col-md-2 col_dex">
          <div class="">
            <div class="fs15">
              <h2>{{ simbol_a }}/{{ simbol_b }}</h2>
            </div>
            <div class="fs08">
              <div
                class="btn btn-outline_info btn-sm"
                style="padding-bottom: 0rem"
                @click="openModalDetail(market)"
              >
                Detail
              </div>
            </div>
          </div>
          <div class="dex_dash"></div>
        </div>

        <div class="col-md-2">
          <div class="fs14">
            <span>{{ market.last_price }} {{ simbol_b }}</span>
          </div>
          <div class="fs08"><span>Last Price</span></div>
        </div>
        <div class="col-md-2">
          <div :class="`${price_color} fs10`">
            <span
              >{{
                themain.angkaDenganPersentaseBesar(
                  parseFloat(market.change_24h)
                )
              }}
              %</span
            >
          </div>
          <div class="fs08"><span>24h Change</span></div>
        </div>
        <div class="col-md-2">
          <div class="fs10">
            <span>{{ parseFloat(market.high_24h).toFixed(6) }}</span>
          </div>
          <div class="fs08"><span>24h High</span></div>
        </div>
        <div class="col-md-2">
          <div class="fs10">
            <span>{{ parseFloat(market.low_24h).toFixed(6) }}</span>
          </div>
          <div class="fs08"><span>24h Low</span></div>
        </div>
        <div class="col-md-2">
          <div class="fs10">
            <span>{{ parseFloat(market.vol_24h).toFixed(6) }}</span>
          </div>
          <div class="fs08"><span>24h Volume</span></div>
        </div>
      </div>
    </div>

    <!-- hanya muncul pada small screen -->
    <div class="sum-small-screen">
      <div class="row">
        <div class="col-5 menu_wrapper">
          <div class="fs14 judul" @click="openModalMarket">
            {{ simbol_a }}/{{ simbol_b }}
          </div>
          <div class="fs13">{{ market.last_price }} {{ simbol_b }}</div>

          <div
            class="btn btn-outline-info btn-sm"
            style="padding-top: 1px; padding-bottom: 1px"
            @click="openModalDetail(market)"
          >
            Detail
          </div>
        </div>

        <div class="col-7">
          <div class="row">
            <div class="col">
              <div class="fs10">
                {{ parseFloat(market.high_24h).toFixed(4) }}
              </div>
              <div class="fs07">24h High</div>

              <div class="fs10 mt10">
                {{ parseFloat(market.low_24h).toFixed(4) }}
              </div>
              <div class="fs07">24h Low</div>
            </div>

            <div class="col">
              <div class="fs10">
                {{ parseFloat(market.vol_24h).toFixed(6) }}
              </div>
              <div class="fs07">24h Volume</div>

              <div :class="`${price_color} fs10 mt10`">
                {{
                  themain.angkaDenganPersentaseBesar(
                    parseFloat(market.change_24h)
                  )
                }}
                %
              </div>
              <div class="fs07">24h Change</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal bisa dipanggil dari big screen dan small screen -->
    <Teleport to="body">
      <MarketModal :show="showModalMarket" @close="showModalMarket = false">
        <template #header>
          <div class="dex_list-title">
            <h2>Pairs</h2>
            <h2>Price & Change</h2>
          </div>
        </template>
        <template #body>
          <div class="">
            <MarketSection />
          </div>
        </template>
      </MarketModal>

      <MarketDetailModal
        :show="showModalMarketDetail"
        @close="showModalMarketDetail = false"
      >
        <template #header>
          <div class="dex_list-title">
            <h2>Market Detail</h2>
            <button @click="showModalMarketDetail = false" type="button">
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10.5" cy="10.5" r="10.5" fill="#F6465D" />
                <path
                  d="M16.275 14.122C16.275 14.3694 16.1884 14.5798 16.0152 14.753L14.753 16.0152C14.5798 16.1884 14.3694 16.275 14.122 16.275C13.8745 16.275 13.6641 16.1884 13.4909 16.0152L10.7625 13.2867L8.03409 16.0152C7.86086 16.1884 7.6505 16.275 7.40303 16.275C7.15556 16.275 6.9452 16.1884 6.77197 16.0152L5.50985 14.753C5.33662 14.5798 5.25 14.3694 5.25 14.122C5.25 13.8745 5.33662 13.6641 5.50985 13.4909L8.23826 10.7625L5.50985 8.03409C5.33662 7.86086 5.25 7.6505 5.25 7.40303C5.25 7.15556 5.33662 6.9452 5.50985 6.77197L6.77197 5.50985C6.9452 5.33662 7.15556 5.25 7.40303 5.25C7.6505 5.25 7.86086 5.33662 8.03409 5.50985L10.7625 8.23826L13.4909 5.50985C13.6641 5.33662 13.8745 5.25 14.122 5.25C14.3694 5.25 14.5798 5.33662 14.753 5.50985L16.0152 6.77197C16.1884 6.9452 16.275 7.15556 16.275 7.40303C16.275 7.6505 16.1884 7.86086 16.0152 8.03409L13.2867 10.7625L16.0152 13.4909C16.1884 13.6641 16.275 13.8745 16.275 14.122Z"
                  fill="#FAFAFA"
                />
              </svg>
            </button>
          </div>
        </template>
        <template #body>
          <div class="modal_body-section">
            <!-- TOKA -->
            <div class="box_item">
              <div class="side_box">
                <div class="uniq_icon">
                  <img
                    :src="`data:image/svg+xml;base64, ${a_uni_icon}`"
                    alt="icon"
                  />
                </div>
                <h2>{{ simbol_a }}</h2>
              </div>
              <div
                @click="gotoDetailNetwork('tokA')"
                class="address_ab tooltip_modal"
              >
                <p v-if="isMobileView">
                  {{ market.a_address.slice(0, 10) }}...{{
                    market.a_address.slice(-10)
                  }}
                </p>
                <p v-else>{{ market.a_address }}</p>
                <span class="tooltiptext" id="myTooltip">{{
                  navigateMsg
                }}</span>
              </div>
            </div>

            <!-- TOKB -->
            <div v-if="themain.rootCoinSymbol != simbol_b" class="box_item">
              <div class="side_box">
                <div class="uniq_icon">
                  <img
                    :src="`data:image/svg+xml;base64, ${b_uni_icon}`"
                    alt="icon"
                  />
                </div>
                <h2>
                  {{ simbol_b }}
                </h2>
              </div>
              <div
                @click="gotoDetailNetwork('tokB')"
                class="address_ab tooltip_modal"
              >
                <p v-if="isMobileView">
                  {{ market.b_address.slice(0, 10) }}...{{
                    market.b_address.slice(-10)
                  }}
                </p>
                <p v-else>{{ market.b_address }}</p>
                <span class="tooltiptext" id="myTooltip">{{
                  navigateMsg
                }}</span>
              </div>
            </div>

            <!-- Attention -->
            <div class="box_item attention_box">
              <h2>Attention!</h2>
              <p>
                This is open market. Everyone can create their own market.
                <br />
                Please make sure you interact with the right contract. If you
                don't want to see this market, you can hide this market
              </p>
            </div>

            <div class="check_box">
              <input
                type="checkbox"
                id="idontwannasee"
                name="idontwannasee"
                @click="periksaHideShow"
              />
              <span>I don't want to see this market</span>
            </div>
          </div>
        </template>
      </MarketDetailModal>
    </Teleport>
  </div>
</template>

<script>
// node_modules
import Web3 from "web3";
import axios from "axios";
import Cookie from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import MarketModal from "../../modals/MarketModal.vue";
import MarketDetailModal from "../../modals/MarketDetailModal.vue";
import MarketSection from "@/components/markets/Exchange/MarketSection.vue";

export default {
  components: {
    MarketModal,
    MarketDetailModal,
    MarketSection,
  },
  props: {},

  data() {
    return {
      themain,

      explorer: thestore.getBlockchainExplorerDomain(),

      chain_id: this.$route.params.chainId,
      sc_address: this.$route.params.sc_address.toLowerCase(),
      market_id_bc: this.$route.params.market_id_bc,

      simbol_a: "",
      simbol_b: "",

      a_uni_icon: "",
      b_uni_icon: "",

      market: {
        last_price: 0,
        price_buy: 0,
        price_sell: 0,
        vol_24h: 0,
        change_24h: 0,
        high_24h: 0,
        low_24h: 0,
      },

      price_color: "green",

      isMarketMenu: false,
      showModalMarket: false,
      showModalMarketDetail: false,
      navigateMsg: "Click to see details",
      isMobileView: false,
    };
  },

  async created() {
    var self = this;

    self.emitter.on("fe-market_update", async function (e) {
      if (
        e.data.market_id_bc == self.market.market_id_bc &&
        e.data.sc_address == self.market.sc_address
      ) {
        console.log("self.market ---- market update", self.market);

        self.market.last_price = e.data.last_price;
        self.market.price_buy = e.data.last_bid;
        self.market.price_sell = e.data.last_ask;
        self.market.change_24h = e.data.change_24h;
        self.market.high_24h = e.data.high_24h;
        self.market.low_24h = e.data.low_24h;
        self.market.vol_24h = e.data.vol_24h;

        if (e.data.change_24h >= 0) {
          self.price_color = "green";
        } else {
          self.price_color = "red";
        }
      }
    });
  },

  async mounted() {
    var self = this;

    // update data market dari main
    await themain.updateMarketData(self, themain);

    console.log('explorer', self.explorer);

    // detect apakah mobile view
    if (window.innerWidth <= 500) {
      self.isMobileView = true;
    }
  },

  methods: {
    async periksaHideShow() {
      let self = this;

      var web3;
      if (window.ethereum) {
        web3 = new Web3(window.ethereum);
      } else {
        web3 = new Web3(window.ethProvider);
      }

      var msg = `I don't want to see market ${self.market.sc_address} #${self.market.market_id_bc} anymore`;

      // alert('mantap');
      var signature = await web3.eth.personal.sign(
        msg,
        Cookie.get("connectedAddress")
      );

      // var anu = document.getElementById('idontwannasee').value;

      // yang dikirim adalah show: false
      await axios({
        url: `${themain.baseApi}/usermarketconfigs/dont-wanna-see`,
        method: "post",
        data: {
          address: Cookie.get("connectedAddress"),
          signature: signature,
          msg,
          sc_address: self.market.sc_address,
          market_id_bc: self.market.market_id_bc,
          show: false,
        },
      });

      themain.closeModal("summary-modal-detail");

      self.$router.push({
        path: "/markets",
      });
    },

    openModalDetail(item) {
      var self = this;
      // themain.openModal("summary-modal-detail");
      self.showModalMarketDetail = true;

      let imgAUnique = themain.iconB64Text(item.a_address.slice(2, 42));
      self.a_uni_icon = imgAUnique;

      if (themain.rootCoinSymbol != item.simbol_b) {
        let imgBUnique = themain.iconB64Text(item.b_address.slice(2, 42));
        self.b_uni_icon = imgBUnique;
      }
    },

    marketMenu() {
      this.isMarketMenu = !this.isMarketMenu;
    },

    gotoDetailNetwork(tok) {
      var self = this;
      // console.log(self.market.b_address);
      // console.log(self.market.a_address);

      if (tok === "tokA") {
        window.open(
          `${self.explorer}/address/${self.market.a_address}`,
          "_blank"
        );

        self.showModalMarketDetail = false;
      }

      if (tok === "tokB") {
        window.open(
          `${self.explorer}/address/${self.market.b_address}`,
          "_blank"
        );

        self.showModalMarketDetail = false;
      }
    },

    openModalMarket() {
      this.showModalMarket = true;
    },
  },
};
</script>

<style scoped>
.dex_dash {
  height: 90%;
  width: 1px;
  background-color: #262c34;
  margin-left: 40px;
}

.col_dex {
  width: 100%;
  display: flex;
}
.fs07 {
  font-size: 0.7rem;
}
.fs08 {
  font-size: 0.8rem;
}
.fs15 h2 {
  font-size: 1rem;
  font-weight: bold;
}
.fs14 span {
  font-size: 1rem;
}
.fs13 {
  font-size: 1.3rem;
}
.fs10 {
  font-size: 12px;
  color: #777575;
}

.mt10 {
  margin-top: 10px;
}

.sum-big-screen {
  display: block;
  width: 100%;
  padding: 15px;
}
.sum-small-screen {
  display: none;
}

.wrapper_row {
  width: 900px;
}

.btn-outline_info {
  border: 1px solid rgb(14, 203, 129);
  background: none;
  color: rgb(14, 203, 129);
}

.btn-close {
  background: none;
  border: none;
}

.menu_wrapper {
  position: relative;
  cursor: pointer;
}

.sum_menu {
  position: absolute;
  top: 20%;
  /* right: 60%; */
  width: 60px;
  padding: 10px 0;
  background-color: #1e2329;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition-duration: 0.5s;
}

.sum_menu span {
  width: 100%;
  padding: 5px 0;

  display: flex;
  justify-content: center;
  cursor: pointer;
}

.sum_menu span:hover {
  background-color: rgb(14, 203, 129);
}

.market_show {
  opacity: 1 !important;
}

.dex_list-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: aliceblue;
}

.dex_list-title h2 {
  font-size: 20px;
  font-weight: bold;
}

/* modal sstyles */
.box_item {
  width: 100%;
  height: 128px;
  background-color: #2a2e39;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 10px;
}

.uniq_icon {
  position: relative;
  width: 70px;
  height: 70px;
  background-color: aliceblue;
  border-radius: 10px;
}

.uniq_icon img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.side_box {
  display: flex;
  gap: 40px;
  align-items: center;
}

.side_box h2 {
  font-size: 20px;
  font-weight: bold;
  color: #0ecb81;
  padding: 0 !important;
  margin: 0 !important;
}

.box_item p {
  font-size: 16px;
  color: aliceblue;
  padding: 0 !important;
  margin: 0 !important;
}

.attention_box {
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px;

  height: 150px;
  background-color: #d1ecf1;
  margin: 50px 0 30px 0;
}

.attention_box h2 {
  font-size: 24px;
  color: #0c5460;
}

.attention_box p {
  font-size: 14px;
  color: #0c5460;
}

.check_box span {
  color: aliceblue;
  font-size: 14px;
  margin-left: 10px;
}

.dex_list-title button {
  background: none;
  border: none;
}

.tooltip_modal {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip_modal .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #d86969;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip_modal .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip_modal:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.address_ab {
  height: 40px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 884px) {
  .sum-big-screen {
    display: none;
  }
  .sum-small-screen {
    display: block;
    width: 100%;
    padding: 15px;
  }
}

@media only screen and (max-width: 632px) {
  .attention_box h2,
  .side_box h2 {
    font-size: 14px;
  }

  .box_item p {
    font-size: 12px;
  }

  .side_box {
    gap: 10px;
  }

  .box_item {
    height: 80px;
  }

  .attention_box {
    height: 130px;
  }

  .uniq_icon {
    width: 45px;
    height: 45px;
  }
}

@media only screen and (max-width: 550px) {
  .dex_list-title h2 {
    font-size: 14px;
  }
}

.judul::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  font-size: 0.8rem;
}
</style>
