import Web3 from "web3";
import Big from "big.js";

import abiMarket from "@/applibs/exchange/abi.js";
import createPermitPure from "@/applibs/exchange/create-permit-pure.js";

var utama = async function (
  self,
  tokenAddress,
  marketId,
  buyIds,
  qtyAs,
  final_normal_sell
) {
  try {
    var qty_sisa = "0";

    var qty_arr = [];
    let qtyAsSum = Big("0");
    qtyAs.forEach((ele) => {
      if (ele.split(".").length == 2) {
        var apo = ele.split(".");
        qty_arr.push(apo[0]);
        qtyAsSum = qtyAsSum.add(Big(apo[0]));
      } else {
        qty_arr.push(ele);
        qtyAsSum = qtyAsSum.add(Big(ele));
      }
    });

    var ethSend = Big(self.market_fee).mul(buyIds.length).toString();

    if (final_normal_sell != null) {
      // cegah error koma
      // Disini jangan to Fixed karena jika dengan toFixed, jika pembulatan keatas,
      // maka akan tidak akan terkirim juga karena saldo tidak cukup
      qty_sisa = final_normal_sell["qty_a"].split(".")[0];

      // kita anggap saja yang terakhir adalah yang paling kecil price-nya
      // TODO: Hilangkan pesan diatas jika function ini dipanggil dengan mengurutkan dari yang besar ke yang kecil untuk price
      const last_qty = qty_arr[qty_arr.length - 1];
      const last_qty_send = Big(last_qty).add(qty_sisa);

      // replace last qty
      qty_arr.pop();
      qty_arr.push(last_qty_send.toString());
    }

    const web3 = new Web3(window.ethereum);
    const accounts = await web3.eth.getAccounts();
    const account = accounts[0];

    const marketContract = new web3.eth.Contract(abiMarket, self.sc_address);

    // permit untuk ini harus ditambah dengan qty_sisa
    const spender_value = qtyAsSum.add(qty_sisa);

    const permitObj = await createPermitPure(
      self.sc_address,
      tokenAddress,
      spender_value.toString()
    );

    console.log(
      "sellDump",
      JSON.stringify({
        buyIds,
        qty_arr,
        marketId,
        deadline: permitObj.deadline,
        v: permitObj.v,
        r: permitObj.r,
        s: permitObj.s,
      })
    );

    await marketContract.methods
      .sellDump(
        buyIds,
        qty_arr,
        `${marketId}`,
        permitObj.deadline,
        permitObj.v,
        permitObj.r,
        permitObj.s
      )
      .send({
        from: account,
        value: ethSend,
      });

    return true;
  } catch (error) {
    console.log(error);

    if (error.code === 4001) {
      return true;
    } else {
      return false;
    }
  }
};

export default utama;
