<template>
  <div class="wrapper">
    <TopNav />

    <div class="container ptb70">
      <div class="row">
        <h1 class="col text-light">Cryptos</h1>
      </div>

      <div class="row">
        <table class="table">
          <thead>
            <tr>
              <th class="text-left">Icon</th>
              <th class="text-left">Symbol</th>
              <th class="text-left">Name</th>
              <th class="text-left">Contract Address</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in cryptos" :key="item.id">
              <td>
                <img
                  width="40"
                  height="40"
                  :src="`data:image/svg+xml;base64, ${item.b64img}`"
                />
              </td>
              <td>
                {{ item.simbol }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.address }}
              </td>
              <td>
                <a
                  :href="`${explorer}/address/${item.address}`"
                  target="_blank"
                  class="btn btn-sm btn-outline-info"
                  style="margin-right: 5px"
                >
                  Explorer
                </a>
                <router-link
                  :to="`/coins/detail?address=${item.address}`"
                  class="btn btn-sm btn-outline-info"
                >
                  Detail
                </router-link>
                <router-link
                  :to="`/coins/detail?address=${item.address}`"
                  class="btn btn-sm btn-outline-info"
                >
                  Website
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="footer_sc">
      <Footer />
    </div>
  </div>
</template>

<script>
// node_modules
// import Identicon from 'identicon.js';

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {
      themain,
      cryptos: [],
      explorer: thestore.getBlockchainExplorerDomain(),
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected");
    },
  },

  async beforeMount() {
    var self = this;
    themain.beforeMountConnectToWallet(self);
    themain.networkAlert();
    themain.networkChanged(self);
    themain.accountChanged(self);
  },

  async mounted() {
    var self = this;
    await self.getCryptos();
  },
  methods: {
    async getCryptos() {
      var self = this;
      var res = await thestore.getCryptos(1, 20, "id", "desc", []);

      var nudata = [];
      res.datas.forEach((el) => {
        var aa = el;

        var imgText = themain.iconB64Text(el.address.slice(2, 42));
        aa["b64img"] = imgText;

        nudata.push(aa);
      });

      self.cryptos = nudata;
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-template-rows: 1fr 4fr 2fr;
}
.footer_sc {
  height: 100%;
}
</style>
