<template>
  <div class="market-trade-sell">
    <div class="row">
      <div class="col-5 text-light">
        Fee: <span>{{ marketFee }} {{ rootCoinSymbol }}</span>
      </div>
      <div class="col-7 text-light text-right">
        Available:
        <span>
          {{ parseFloat(sellForm.tokenAOwnedHumanView).toFixed(4) }}
          {{ simbol_a }}
        </span>
      </div>
    </div>

    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">Price</span>
      </div>
      <input
        id="sell_form_price"
        type="text"
        step="any"
        class="form-control text-right"
        v-model="sellForm.price"
      />
      <div class="input-group-append">
        <span class="input-group-text">{{ simbol_b }}</span>
      </div>
    </div>

    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">Amount</span>
      </div>
      <input
        id="sell_form_qty"
        type="text"
        step="any"
        class="form-control text-right"
        v-model="sellForm.qtyA"
        @keyup="sellBtnClicked(false)"
      />
      <div class="input-group-append">
        <span class="input-group-text">{{ simbol_a }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col text-right text-light">
        Estimation: {{ sellForm.qtyBEstimation }} {{ simbol_b }}
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button
          id="sell_form_submit_btn"
          class="btn sell"
          @click="sellBtnClicked(true)"
        >
          Bot Sell
        </button>
      </div>
      <div
        @click="allowanceBtnClicked"
        class="col-12 cursor-pointer allowance-row"
        v-if="mtipe != '3'"
      >
        <i class="icon ion-md-checkmark-circle"></i> Allowance: &nbsp;&nbsp;
        {{ parseFloat(allowance_human).toFixed(4) }} {{ simbol_a }}
      </div>
    </div>

    <!-- modal allowance -->
    <div
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
      :class="{ show: modalShow }"
      :style="modalStyle"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Allowance</h5>
            <button
              type="button"
              class="btn-close btn_cls"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="modalClose"
              :disabled="modalCloseBtnDisabled"
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10.5" cy="10.5" r="10.5" fill="#F6465D" />
                <path
                  d="M16.275 14.122C16.275 14.3694 16.1884 14.5798 16.0152 14.753L14.753 16.0152C14.5798 16.1884 14.3694 16.275 14.122 16.275C13.8745 16.275 13.6641 16.1884 13.4909 16.0152L10.7625 13.2867L8.03409 16.0152C7.86086 16.1884 7.6505 16.275 7.40303 16.275C7.15556 16.275 6.9452 16.1884 6.77197 16.0152L5.50985 14.753C5.33662 14.5798 5.25 14.3694 5.25 14.122C5.25 13.8745 5.33662 13.6641 5.50985 13.4909L8.23826 10.7625L5.50985 8.03409C5.33662 7.86086 5.25 7.6505 5.25 7.40303C5.25 7.15556 5.33662 6.9452 5.50985 6.77197L6.77197 5.50985C6.9452 5.33662 7.15556 5.25 7.40303 5.25C7.6505 5.25 7.86086 5.33662 8.03409 5.50985L10.7625 8.23826L13.4909 5.50985C13.6641 5.33662 13.8745 5.25 14.122 5.25C14.3694 5.25 14.5798 5.33662 14.753 5.50985L16.0152 6.77197C16.1884 6.9452 16.275 7.15556 16.275 7.40303C16.275 7.6505 16.1884 7.86086 16.0152 8.03409L13.2867 10.7625L16.0152 13.4909C16.1884 13.6641 16.275 13.8745 16.275 14.122Z"
                  fill="#FAFAFA"
                />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mt-2">
              <div class="col">
                <label class="fw-bold"> Balance ({{ simbol_a }}) </label><br />
                <input
                  type="text"
                  class="form-control x-form-control f_modal"
                  v-model="sellForm.tokenAOwnedHumanView"
                  readonly
                />
              </div>
            </div>

            <div class="row mt-2">
              <div class="col">
                <label class="fw-bold"> Allowance ({{ simbol_a }}) </label
                ><br />
                <input
                  type="text"
                  step="any"
                  class="form-control x-form-control f_modal"
                  v-model="allowanceForm.allowance_input"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="modalClose"
              :disabled="modalCloseBtnDisabled"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-dark btn_app"
              @click="submitAllowance"
              :disabled="submitBtnDisabled"
            >
              Approve
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      :class="{
        'modal-backdrop': modalBackdropMain,
        fade: modalBackdropFade,
        show: modalBackdropShow,
      }"
    ></div>

    <Teleport to="body">
      <!-- use the modal component, pass in the prop -->
      <WarningModal :show="showModal" @close="showModal = false">
        <template #body>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 48 48"
            fill="none"
          >
            <mask
              id="mask0_290_8"
              style="mask-type: luminance"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="48"
              height="48"
            >
              <path d="M0 0H47.5V47.5H0V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_290_8)">
              <path
                d="M4.56624 45C2.26374 45 1.24874 43.3275 2.30874 41.2837L21.82 3.715C22.88 1.67125 24.6175 1.67125 25.6787 3.715L45.19 41.2837C46.2525 43.3275 45.2362 45 42.9325 45H4.56624Z"
                fill="#FFCC4D"
              />
              <path
                d="M20.9612 14.5751C20.9612 12.9476 22.1625 11.9401 23.7512 11.9401C25.3025 11.9401 26.5425 12.9863 26.5425 14.5751V29.4976C26.5425 31.0863 25.3025 32.1326 23.7512 32.1326C22.1625 32.1326 20.9612 31.1238 20.9612 29.4976V14.5751ZM20.7275 37.4413C20.7288 36.6399 21.0479 35.8717 21.6147 35.3051C22.1815 34.7385 22.9498 34.4198 23.7512 34.4188C24.5527 34.4198 25.321 34.7385 25.8878 35.3051C26.4546 35.8717 26.7737 36.6399 26.775 37.4413C26.774 38.243 26.4551 39.0115 25.8883 39.5784C25.3214 40.1452 24.5529 40.4641 23.7512 40.4651C22.9496 40.4641 22.1811 40.1452 21.6142 39.5784C21.0474 39.0115 20.7285 38.243 20.7275 37.4413Z"
                fill="#231F20"
              />
            </g>
          </svg>
          <h3 style="color: aliceblue; font-size: 16px">{{ warningMsg }}</h3>
        </template>
      </WarningModal>
    </Teleport>
  </div>
</template>

<script>
// node_modules
import Big from "big.js";
import Cookie from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";
import setSell from "@/applibs/exchange-bot/set-sell.js";
import sellDump from "@/applibs/exchange-bot/sell-dump.js";
import balanceOf from "@/applibs/erc20/balance-of.js";
import allowance from "@/applibs/erc20/allowance.js";
import approve from "@/applibs/erc20/approve.js";
import howManyConfirmation from "@/applibs/eth/how-many-confirmation.js";
import fee from "@/applibs/exchange/fee.js";
import balance from "@/applibs/eth/balance.js";
import createPermit from "@/applibs/exchange-bot/create-permit.js";
import createPermitDump from "@/applibs/exchange-bot/create-permit-dump.js";
import appfunsSell from "@/applibs/appfuns/sell.js";

// components
import WarningModal from "@/components/modals/WarningModal.vue";

export default {
  components: {
    WarningModal,
  },
  props: {
    connectedAddress: String,
    marketFee: String,
    market: Object,
  },
  data() {
    return {
      themain,

      sc_address: this.$route.params.sc_address.toLowerCase(),
      market_id_bc: this.$route.params.market_id_bc,
      pair: this.$route.query.pair,
      mtipe: this.$route.query.mtipe,

      price_buy: 0,

      simbol_a: "",
      simbol_b: "",

      sellForm: {
        price: 0,
        qtyA: 0,
        qtyBEstimation: 0,
        tokenAOwned: 0,
        tokenAOwnedHumanView: 0,
      },

      f_buys: [],

      market_fee: 0,

      modalShow: false,
      modalStyle: {
        diplay: "none",
      },
      modalBidShow: false,
      modalBidStyle: {
        diplay: "none",
      },
      modalBackdropMain: false,
      modalBackdropFade: false,
      modalBackdropShow: false,

      modalCloseBtnDisabled: false,

      allowanceForm: {
        allowance_input: 0,
      },

      allowance: 0,
      allowance_human: 0,

      submitBtnDisabled: false,

      rootCoinSymbol: process.env.VUE_APP_ROOT_COIN_SYMBOL,

      showModal: false,
      warningMsg: "",
    };
  },

  async created() {
    var self = this;

    self.emitter.on("fe-buys", function (e) {
      self.f_buys = e.data.sort((a, b) => {
        return b.price_db_float - a.price_db_float;
      });
      if (self.f_buys && self.f_buys.length > 0) {
        self.price_buy = self.f_buys[0].price_db;
      } else {
        self.price_buy = 0;
      }
    });

    self.emitter.on("fe-obook_clicked", async function (e) {
      self.sellForm.price = e.data.obj.price_db;
      if (e.data.kind == "sell") {
        self.sellForm.qtyA = e.data.obj.qty_a_db;
      } else {
        var jumlah = 0;
        self.f_buys.forEach((el) => {
          if (parseFloat(el.price_db) >= parseFloat(e.data.obj.price_db)) {
            jumlah = jumlah + parseFloat(el.qty_a_db);
          }
        });
        self.sellForm.qtyA = jumlah.toFixed(8); // harus dibatasi segini. Kalau tidak dibatasi, maka akan ada error
        self.sellBtnClicked(false); // anggap saja sell button di klik tetapi tidak dieksekusi supaya kita dapat estimasi hasil
      }
    });

    // Dengarkan balance update dari component lainnya.
    // Jika pair adalah TOKA dan TOKB, maka balance update akan
    // mengandung kedua token tersebut
    self.emitter.on("fe-balance_update", async function (e) {
      self.sellForm.tokenAOwned = e.bal_a.balanceRaw.balance;
      self.sellForm.tokenAOwnedHumanView = e.bal_a.balance;
    });
  },

  async mounted() {
    var self = this;

    setTimeout(async function () {
      if (
        Cookie.get("connectedAddress") != "" &&
        Cookie.get("connectedAddress") != null &&
        self.market &&
        self.market.a_address
      ) {
        if (self.mtipe == "1" || self.mtipe == "2") {
          var tokenAOwned = await balanceOf(
            self.connectedAddress,
            self.market.a_address
          );
          self.sellForm.tokenAOwned = tokenAOwned;
          self.sellForm.tokenAOwnedHumanView =
            tokenAOwned / 10 ** parseInt(self.market.a_decimals);

          await self.getAllowance();
        } else if (self.mtipe == "3") {
          // ambil balance coin disini
          // TODO: Ambil 1x saja. Ambil wei dan convert ke ethere pakai web3js
          self.sellForm.tokenAOwned = await balance(
            Cookie.get("connectedAddress"),
            "wei"
          );
          self.sellForm.tokenAOwnedHumanView = await balance(
            Cookie.get("connectedAddress"),
            "ether"
          );
        }
      } else {
        self.sellForm.tokenAOwned = 0;
        self.sellForm.tokenAOwnedHumanView = 0;
      }

      // simbols
      var simbols = self.market.pair.split("-");
      // console.log('simbols',simbols);
      self.simbol_a = simbols[0];
      self.simbol_b = simbols[1];
    }, 3000);

    // TODO: Perbaiki
    if (self.$route.query.bot != "true") {
      var thefee = await fee();
      console.log("thefee", thefee);
      self.market_fee = thefee;
    } else {
      self.market_fee = "100000000000000";
    }
  },

  methods: {
    async sellBtnClicked(eksekusi) {
      var self = this;

      var price = Big(self.sellForm.price);
      var qty_a_input = Big(self.sellForm.qtyA);
      var qty_a_input_send = qty_a_input.times(
        Big(`1e${self.market.a_decimals}`)
      );
      // console.log("qty_a_input_send", qty_a_input_send.toString());

      if (price.lte(0) && eksekusi == true) {
        return self.showToast("Price can't be zero");
      }

      if (qty_a_input.lte(0) && eksekusi == true) {
        return self.showToast("Qty can't be zero");
      }

      var buys = self.f_buys;

      // urutkan dulu buy ini dari tinggi sampai rendah
      var buys_right_stack = buys.sort(function (a, b) {
        return Big(b.price_db_float).minus(Big(a.price_db_float)).toNumber();
      });

      var buys_include = [];
      buys_right_stack.forEach((ele) => {
        if (price.lte(Big(ele.price_db))) {
          buys_include.push(ele);
        }
      });

      if (buys_include.length > 0) {
        
        const dumpObj = appfunsSell.sellDumpCalculation(self, qty_a_input, buys_include, price);

        // --- EKSEKUSI ---
        if (eksekusi == true) {

          // Disini kita cek apakah bisa langsung (coin) atau tidak langsung
          // Tidak langsung artinya kita harus cek allowance sebelum sellDump
          if (self.mtipe == "3") {
            // transfer coin
            await self.sellDumpProcessCore(dumpObj.buyIds, dumpObj.qtyAs, dumpObj.final_normal_sell);
          } else {
            // transfer token

            // ada 2 jenis token; (1) dengan permit dan (2) dengan allowance
            // jadi yang di sign untuk dikirim adalah sejumlah qtyBs
            const withPermitFunctionHit = await createPermitDump(
              self.$route.query.botAddress,
              self.market.a_address,
              self.market.market_id_bc,
              dumpObj.buyIds,
              dumpObj.qtyAs,
              dumpObj.final_normal_sell,
              self.market_fee,
              self.$route.query.pk
            );

            if (withPermitFunctionHit == false) {
              if (Big(self.allowance).gt(qty_a_input_send)) {
                // lanjut eksekusi sell dump
                await self.sellDumpProcessCore(
                  dumpObj.buyIds,
                  dumpObj.qtyAs,
                  dumpObj.final_normal_sell
                );
              } else {
                // eksekusi allowance terlebih dahulu
                var approveRes = await approve(
                  self.connectedAddress,
                  self.market.a_address,
                  self.market.sc_address,
                  qty_a_input_send.toString()
                );
                // console.log('approveRes', approveRes);
                var countConfirmationApproveRes = await howManyConfirmation(
                  approveRes.transactionHash
                );
                if (countConfirmationApproveRes > 0) {
                  await self.sellDumpProcessCore(
                    dumpObj.buyIds,
                    dumpObj.qtyAs,
                    dumpObj.final_normal_sell
                  );
                } else {
                  setTimeout(async function () {
                    await self.sellDumpProcessCore(
                      dumpObj.buyIds,
                      dumpObj.qtyAs,
                      dumpObj.final_normal_sell
                    );
                  }, 9000);
                }
              }
            }
          }
        }
        // --- EKSEKUSI ---
      } else {
        console.log("ARAHKAN KE SELL BIASA");

        var just_normal_sell = {};
        just_normal_sell["price_db"] = price.toString();
        just_normal_sell["price"] = price
          .times(Big(10).pow(self.market.b_decimals))
          .toString(); // => price * 10**18
        just_normal_sell["qty_a_db"] = qty_a_input.toString();
        just_normal_sell["qty_a"] = qty_a_input_send.toFixed(0);

        console.log("just_normal_sell", just_normal_sell);

        // --- EKSEKUSI ---
        if (eksekusi == true) {
          if (self.mtipe == "3") {
            // Transfer coin
            // Jadi disini kita tidak lihat ada allowance checking
            await self.setSellNormal(
              just_normal_sell["qty_a"],
              just_normal_sell["price"]
            );
          } else {
            // transfer token

            console.log("------- create permit disini");
            console.log(just_normal_sell["qty_a"]);

            // ada 2 jenis token; (1) dengan permit dan (2) dengan allowance
            const withPermitFunction = await createPermit(
              self.$route.query.botAddress,
              self.market.a_address,
              just_normal_sell["qty_a"],
              self.$route.query.pk
            );

            if (withPermitFunction == false) {
              // jika tidak support permit function
              if (Big(self.allowance).gt(qty_a_input_send)) {
                await self.setSellNormal(
                  just_normal_sell["qty_a"],
                  just_normal_sell["price"]
                );
              } else {
                // eksekusi allowance terlebih dahulu
                var approveResAbc = await approve(
                  self.connectedAddress,
                  self.market.a_address,
                  self.market.sc_address,
                  just_normal_sell["qty_a"]
                );
                // console.log("approveResAbc", approveResAbc);
                var countConfirmationApproveResB = await howManyConfirmation(
                  approveResAbc.transactionHash
                );
                if (countConfirmationApproveResB > 0) {
                  await self.setSellNormal(
                    just_normal_sell["qty_a"],
                    just_normal_sell["price"]
                  );
                } else {
                  setTimeout(async function () {
                    await self.setSellNormal(
                      just_normal_sell["qty_a"],
                      just_normal_sell["price"]
                    );
                  }, 9000);
                }
              }
            } else {
              // normal sell dengan mengirimkan permit obj
              await self.setSellNormal(
                just_normal_sell["qty_a"],
                just_normal_sell["price"],
                withPermitFunction.data
              );
            }
          }
        }
        // --- EKSEKUSI ---
      }
    },

    async sellDumpProcessCore(buyIds, qtyAs, final_normal_sell) {
      var self = this;

      var qty_sisa;

      // Untuk mencegah valuenya adalah 4736842140.751207
      // Disini jangan to Fixed karena jika dengan toFixed, jika pembulatan keatas,
      // maka akan tidak akan terkirim juga karena saldo tidak cukup
      var qty_arr = [];
      let qtyAsSum = Big("0");
      qtyAs.forEach((ele) => {
        if (ele.split(".").length == 2) {
          var apo = ele.split(".");
          qty_arr.push(apo[0]);

          qtyAsSum = qtyAsSum.plus(Big(apo[0]));
        } else {
          qty_arr.push(ele);
          qtyAsSum = qtyAsSum.plus(Big(ele));
        }
      });

      // fee adalah berapa buah yang akan di hit oleh user ini
      var fee_send = Big(self.market_fee).times(buyIds.length).toString();

      if (self.mtipe == "3") {
        // coin
        if (final_normal_sell != null) {
          // dump dengan dilanjutkan dengan normal sell

          // cegah error koma
          // Disini jangan to Fixed karena jika dengan toFixed, jika pembulatan keatas,
          // maka akan tidak akan terkirim juga karena saldo tidak cukup
          qty_sisa = final_normal_sell["qty_a"].split(".");

          // disini kita kirim ETH qty, normal buy qty, market fee
          var qtyAsSum_normalQty_marketFee = qtyAsSum
            .plus(Big(qty_sisa[0])) // normal qty
            .plus(fee_send) // market fee
            .toString();

          // kita anggap saja yang terakhir adalah yang paling kecil price-nya
          // TODO: Hilangkan pesan diatas jika function ini dipanggil dengan mengurutkan dari yang besar ke yang kecil untuk price
          const last_qty = qty_arr[qty_arr.length - 1];
          const last_qty_send = Big(last_qty).add(qty_sisa[0]);

          qty_arr.pop(); // remove last array
          qty_arr.push(last_qty_send.toString()); // add last_qty_send to the array

          await sellDump(
            self.$route.query.botAddress,
            buyIds,
            qty_arr,
            qtyAsSum_normalQty_marketFee,
            self.market.market_id_bc,
            "0", // deadline
            "0x0", // v
            "0x0", // r
            "0x0", // s
            self.$route.query.pk
          );
        } else {
          // disini kita kirim ETH qty, market fee
          var qtyAsSum_marketFee = qtyAsSum
            .plus(fee_send) // market fee
            .toString();

          await sellDump(
            self.$route.query.botAddress,
            buyIds,
            qty_arr,
            qtyAsSum_marketFee,
            self.market.market_id_bc,
            "0", // deadline
            "0x0", // v
            "0x0", // r
            "0x0", // s
            self.$route.query.pk
          );
        }
      } else {
        // token
        if (final_normal_sell != null) {
          // TODO: Sepertinya qty_sisa tidak dipakai
          // cegah error koma
          qty_sisa = final_normal_sell["qty_a"].split(".");

          await sellDump(
            self.$route.query.botAddress,
            buyIds,
            qty_arr,
            fee_send,
            self.market.market_id_bc,
            "0", // deadline
            "0x0", // v
            "0x0", // r
            "0x0", // s
            self.$route.query.pk
          );
        } else {
          await sellDump(
            self.$route.query.botAddress,
            buyIds,
            qty_arr,
            fee_send,
            self.market.market_id_bc,
            "0", // deadline
            "0x0", // v
            "0x0", // r
            "0x0", // s
            self.$route.query.pk
          );
        }
      }

      // TODO: Apakah perlu sync data buy

      await self.getAllowance();

      // emit main balance baru karena ada transaksi
      thestore.emitNewMainBalance(self);

      // emit karena mungkin ada perubahan balance
      thestore.emitNewBalanceForAMarketTokenVsToken(self);
    },

    async setSellNormal(inputASend, priceSend, permitObj) {
      var self = this;

      // Karena ada kemungkinan mengirimkan 1e+21,
      // maka disini kita buat seperti ini.
      const thePriceSend = Big(priceSend).toFixed();

      // disini kita kalkulasi, apakah ini mengirim ETH atau cuma mengirim token
      if (self.mtipe == "3") {
        // kirim ETH
        await setSell(
          self.$route.query.botAddress,
          self.market.market_id_bc,
          inputASend,
          thePriceSend,
          "0", // deadline
          "0x0", // v
          "0x0", // r
          "0x0", // s
          inputASend, // kirim ETH sejumlah ini
          self.$route.query.pk
        );
      } else {
        // kirim token
        await setSell(
          self.$route.query.botAddress,
          self.market.market_id_bc,
          inputASend,
          thePriceSend,
          permitObj.deadline, // deadline
          permitObj.v, // v
          permitObj.r, // r
          permitObj.s, // s
          "0", // tidak mengirim ETH
          self.$route.query.pk
        );
      }

      // emit main balance baru karena ada transaksi
      thestore.emitNewMainBalance(self);

      // emit karena mungkin ada perubahan balance
      thestore.emitNewBalanceForAMarketTokenVsToken(self);

      // ambil balance coin
      // TODO: Ambil 1x saja. Ambil wei dan convert ke ether pakai web3js
      self.sellForm.tokenAOwned = await balance(
        Cookie.get("connectedAddress"),
        "wei"
      );
      self.sellForm.tokenAOwnedHumanView = await balance(
        Cookie.get("connectedAddress"),
        "ether"
      );
    },

    allowanceBtnClicked() {
      var self = this;
      if (window.ethereum || window.ethProvider) {
        self.modalShow = true;
        self.modalStyle = { display: "block" };
        document.body.classList.add("modal-open");
        themain.functionModalBackdropShow(self);
      } else {
        alert("Please install a wallet or connect to your wallet");
      }
    },
    modalClose() {
      var self = this;
      self.modalShow = false;
      self.modalStyle = { display: "none" };
      document.body.classList.remove("modal-open");
      themain.functionModalBackdropHide(self);
    },

    async submitAllowance() {
      var self = this;

      // Konversi input allowance ke Big.js untuk memastikan presisi desimal
      var allowance_normal = Big(self.allowanceForm.allowance_input);

      // Kalikan dengan 10**a_decimals untuk mendapatkan nilai dalam satuan yang sesuai
      var allowance_send = allowance_normal
        .times(Big(10).pow(self.market.a_decimals))
        .toFixed(); // Konversi ke string untuk menghindari notasi eksponensial

      // Panggil fungsi approve dengan nilai allowance yang telah dikonversi
      await approve(
        self.connectedAddress,
        self.market.a_address,
        self.market.sc_address,
        allowance_send
      );

      // Memperbarui allowance setelah submit
      await self.getAllowance();

      // Menutup modal setelah submit
      self.modalClose();
    },

    async getAllowance() {
      var self = this;
      var allowanceRes = await allowance(
        self.market.a_address,
        self.connectedAddress,
        self.market.sc_address
      );

      self.allowance = allowanceRes;

      // konversi allowance ke human readable
      var allowance_human = Big(allowanceRes)
        .div(Big(10).pow(self.market.a_decimals))
        .toString();
      // console.log("allowance_human", allowance_human);
      self.allowance_human = allowance_human;
    },

    showToast(val) {
      var self = this;
      self.warningMsg = val;
      self.showModal = true;

      setTimeout(() => {
        self.showModal = false;
      }, 2000);
    },
  },
};
</script>

<style scoped>
.modal-content {
  background-color: #161a1f !important;
  color: #f3f3f3;
}

.f_modal {
  background-color: rgb(173, 173, 173) !important;
}

.btn_app {
  background-color: #26de81;
  color: #fff;
}

.btn_cls {
  background: none;
  border: none;
}

/* Untuk mengatur border
jika mobile, maka border sebelah kiri dihilangkan */
@media only screen and (max-width: 767px) {
  .market-trade-sell {
    border-left: none !important;
  }
}
</style>
