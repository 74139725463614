<template>
  <div>
    <TopNav />

    <section class="container">
      <section class="row">
        <div class="col">
          <h1 class="text-light mt-4">Transfer</h1>

          <form class="row mt-4">
            <div class="col"></div>
          </form>

          <div class="row">
            <div class="col">
              <div class="btn btn-dark" @click="submitForm()">Create</div>
            </div>
          </div>
        </div>
      </section>
    </section>

    <Footer />
  </div>
</template>

<script>
// // node_modules
// import Web3 from "web3";

// // applibs
// import jsonInterface from "@/applibs/research/abi";
// import jsonInterfaceToken from "@/applibs/erc20/abi";

// // components
// import TopNav from "@/components/global/TopNav.vue";
// import Footer from "@/components/global/Footer.vue";

// export default {
//   components: {
//     TopNav,
//     Footer,
//   },
//   data() {
//   },

//   mounted() {
//   },

//   methods: {
//     async submitForm() {

//       try {

//         const web3 = new Web3(window.ethereum);

//         // Mengambil akun pengguna
//         const accounts = await web3.eth.getAccounts();
//         const account = accounts[0];

//         // Address dan ABI smart contract Market
//         const marketAddress = '0x3a8DCbD8097A5E40c912776e840f9cA4F33eC0aA';
//         const marketABI = jsonInterface;

//         // Membuat instance smart contract
//         const marketContract = new web3.eth.Contract(marketABI, marketAddress);

//         // Address dan ABI token ERC20
//         const tokenAddress = '0x8Ef2E27C9d23cf9acd7eeE8307aBB4ea32ffc11D';
//         const tokenABI = jsonInterfaceToken;

//         // Membuat instance token contract
//         const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);

//         // Data permit
//         const amount = web3.utils.toWei('1', 'ether'); // Contoh: 1 token
//         const deadline = Math.floor(Date.now() / 1000) + 60 * 60; // 1 jam dari sekarang

//         // Dapatkan nonce untuk pemilik token
//         const nonce = await tokenContract.methods.nonces(account).call();

//         // DOMAIN_SEPARATOR
//         // const domainSeparator = await tokenContract.methods.DOMAIN_SEPARATOR().call();

//         const domainType = [
//           { name: 'name', type: 'string' },
//           { name: 'version', type: 'string' },
//           { name: 'chainId', type: 'uint256' },
//           { name: 'verifyingContract', type: 'address' }
//         ];

//         // EIP-712 data
//         const permitData = {
//           owner: account,
//           spender: marketAddress,
//           value: amount,
//           nonce: nonce,
//           deadline: deadline,
//         };

//         console.log('permitData', permitData);

//         const domain = {
//           name: 'XStart Token', // Ganti dengan nama token Anda
//           version: '1',
//           chainId: await web3.eth.getChainId(),
//           verifyingContract: tokenAddress,
//         };

//         const types = {
//           EIP712Domain: domainType,
//           Permit: [
//             { name: 'owner', type: 'address' },
//             { name: 'spender', type: 'address' },
//             { name: 'value', type: 'uint256' },
//             { name: 'nonce', type: 'uint256' },
//             { name: 'deadline', type: 'uint256' },
//           ],
//         };

//         const message = permitData;

//         const data = JSON.stringify({
//           types: types,
//           domain: domain,
//           primaryType: 'Permit',
//           message: message,
//         });

//         // Buat tanda tangan menggunakan Metamask
//         const signature = await ethereum.request({
//           method: 'eth_signTypedData_v4',
//           params: [account, data],
//         });

//         // Memecah tanda tangan
//         const r = signature.slice(0, 66);
//         const s = '0x' + signature.slice(66, 130);
//         let v = web3.utils.hexToNumber('0x' + signature.slice(130, 132));

//         // Menyesuaikan nilai `v` untuk EIP-155
//         if (v < 27) {
//           v += 27;
//         }

//         console.log({ v, r, s });

//         // Memanggil fungsi submitToken di smart contract Market
//         await marketContract.methods.submitToken(tokenAddress, amount, deadline, v, r, s).send({ from: account });

//       } catch (error) {
//         console.log(error)
//       }
//     }
//   },
// };
</script>
