import jsonInterface from "./abi";
import themain from "@/applibs/themain.js";
import Web3 from "web3";

var utama = async function (fromAddress, sellId, newPrice) {
  const chainId = themain.getChosenChainId();
  const chainObj = themain.getChosenChainObj(chainId);

  const web3 = new Web3(window.ethereum);

  var myContract = new web3.eth.Contract(jsonInterface, chainObj.sc_address);

  var hasil = await myContract.methods.moveSell(sellId, newPrice).send({
    from: fromAddress,
  });

  return hasil;
};

export default utama;
