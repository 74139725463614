var utama = async function (web3, toAddress, abiObj, abiData) {
  var data = web3.eth.abi.encodeFunctionCall(abiObj, abiData);
  // console.log('data', data);

  var hasil = await web3.eth.call({
    to: toAddress,
    data: data,
  });

  // lihat dokumentasi disini https://web3js.readthedocs.io/en/v1.2.0/web3-eth-abi.html#decodeparameters
  // array dari output
  // abione punya data seperti berikut
  // {"inputs":[],"name":"name","outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view","type":"function","constant":true,"signature":"0x06fdde03"}
  // ambil semua type dari property outputs
  var typesArray = [];
  abiObj.outputs.forEach(function (el) {
    typesArray.push({ type: el.type, name: el.name });
  });

  var hasilAkhir = web3.eth.abi.decodeParameters(typesArray, hasil);

  // hasil akhir seperti {0: 'USDT', 1: 'sdffds'}

  var hasilAkhirDisplay = "{";
  for (const [key, value] of Object.entries(hasilAkhir)) {
    // console.log(`${key}: ${value}`);
    if (key != "__length__") {
      hasilAkhirDisplay = `${hasilAkhirDisplay}"${key}": "${value}",`;
    }
  }
  hasilAkhirDisplay = hasilAkhirDisplay.slice(0, -1) + "}";

  return JSON.parse(hasilAkhirDisplay);
};

export default utama;
