<template>
  <div>
    <div class="col-lg-12">
      <table class="table">
        <thead>
          <tr>
            <th class="text-left">Address</th>
            <th class="text-left">Point</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in airdrops" :key="item">
            <td>
              <span class="address-desktop">
                {{ item.address }}
              </span>
              <span class="address-mobile">
                {{ themain.shortAddress(item.address) }}
              </span>
            </td>
            <td>
              {{ item.count_address }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// node_modules

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components

export default {
  components: {},
  data() {
    return {
      themain,
      airdrops: [],
      pageObj: {
        page: 1,
        rows: 10,
        sort: "id",
        order: "asc",
        filter_rules: [],
      },
    };
  },

  async mounted() {
    var self = this;

    self.getAirdrops();
  },
  methods: {
    async getAirdrops() {
      var self = this;
      var res = await thestore.getAirdrops(
        self.pageObj.page,
        self.pageObj.rows,
        self.pageObj.sort,
        self.pageObj.order,
        self.pageObj.filter_rules
      );

      console.log("res", res);
      self.airdrops = res.datas;
    },
  },
};
</script>

<style scoped>
.address-mobile {
  display: none;
}
.address-desktop {
  display: block;
}

@media only screen and (max-width: 884px) {
  .address-mobile {
    display: block;
  }
  .address-desktop {
    display: none;
  }
}
</style>
