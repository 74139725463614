<template>
  <router-view :key="$route.params"></router-view>
</template>
<script>
import AOS from "aos";
export default {
  components: {},

  mounted() {
    AOS.init();
  },
};
</script>
