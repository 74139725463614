// node_modules
import axios from "axios";

/**
 * Pada konsep create permit, jika ada error, artinya dia tidak bisa create permit sehingga harus menggunakan allowance
 * @returns
 */
const utama = async function (address, tokenAddress, qty, pk) {
  const res = await axios({
    method: "post",
    url: `${process.env.VUE_APP_BASE_BOT}/exchange/create-permit`,
    data: {
      address,
      tokenAddress,
      qty,
      pk,
    },
  });
  console.log("res", res);
  return res;
};

export default utama;
