import myEthCall from "../eth/call";

import Web3 from "web3";

/**
 * Digunakan untuk mendapatkan token symbol, token decimals
 * @returns {name: "Nama Token", decimals: 18, symbol: "USDT"}
 */
var utama = async function (contractAddress) {
  var web3 = new Web3(window.ethereum);

  // 1. ambil token decimals
  // {"0": "18"}
  var decimals = await myEthCall(
    web3,
    contractAddress,
    {
      constant: true,
      inputs: [],
      name: "decimals",
      outputs: [{ name: "", type: "uint8" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    []
  );
  // console.log('decimals', decimals);
  // console.log("decimals['0']", decimals['0']);

  // 2. ambil symbol token
  // hasilnya {"0": "USDT"}
  var tknSymbol = await myEthCall(
    web3,
    contractAddress,
    {
      constant: true,
      inputs: [],
      name: "symbol",
      outputs: [{ name: "", type: "string" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    []
  );
  // console.log('tknSymbol', tknSymbol);

  // 3. ambil name
  // hasilnya {"0": "Sesuatu"}
  var namaTokenSaja = await myEthCall(
    web3,
    contractAddress,
    {
      constant: true,
      inputs: [],
      name: "name",
      outputs: [{ name: "", type: "string" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    []
  );
  // console.log('namaTokenSaja', namaTokenSaja);

  return {
    name: namaTokenSaja["0"],
    decimals: parseInt(decimals["0"]),
    symbol: tknSymbol["0"],
  };
};

export default utama;
