import Web3 from "web3";

/**
 *
 * @param {*} userAddress
 * @param {*} unit => Yang banyak dipakai [ether, gwei, wei]
 * @returns
 */
var utama = async function (userAddress, unit) {
  var web3 = new Web3(window.ethereum);

  var hasil = web3.utils.fromWei(
    (await web3.eth.getBalance(userAddress)).toString(),
    unit
  );

  return hasil;
};

export default utama;
