import Web3 from "web3";

import abiToken from "@/applibs/erc20/abi.js";

var utama = async function (spenderAddress, tokenAddress, qty) {
  // DISINI KITA TEBAK SAJA, JIKA ERROR, ARTINYA GAK BISA CREATE PERMIT DAN DIA HARUS PAKAI ALLOWANCE
  try {
    const web3 = new Web3(window.ethereum);

    // Mengambil akun pengguna
    const accounts = await web3.eth.getAccounts();
    const account = accounts[0];

    // Membuat instance token contract
    const tokenContract = new web3.eth.Contract(abiToken, tokenAddress);

    // Data permit
    // const amount = web3.utils.toWei('1', 'ether'); // Contoh: 1 token
    const deadline = Math.floor(Date.now() / 1000) + 60 * 60; // 1 jam dari sekarang

    // Dapatkan nonce untuk pemilik token
    const nonce = await tokenContract.methods.nonces(account).call();

    const tokenName = await tokenContract.methods.name().call();
    console.log("tokenName", tokenName);

    // DOMAIN_SEPARATOR
    // const domainSeparator = await tokenContract.methods.DOMAIN_SEPARATOR().call();
    // console.log('domainSeparator', domainSeparator);

    const domainType = [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" },
    ];

    // EIP-712 data
    const permitData = {
      owner: account,
      spender: spenderAddress,
      value: qty,
      nonce: nonce,
      deadline: deadline,
    };

    console.log("permitData", permitData);

    const domain = {
      name: tokenName,
      version: "1",
      chainId: await web3.eth.getChainId(),
      verifyingContract: tokenAddress,
    };

    const types = {
      EIP712Domain: domainType,
      Permit: [
        { name: "owner", type: "address" },
        { name: "spender", type: "address" },
        { name: "value", type: "uint256" },
        { name: "nonce", type: "uint256" },
        { name: "deadline", type: "uint256" },
      ],
    };

    const message = permitData;

    const data = JSON.stringify({
      types: types,
      domain: domain,
      primaryType: "Permit",
      message: message,
    });

    // Buat tanda tangan menggunakan Metamask
    const signature = await window.ethereum.request({
      method: "eth_signTypedData_v4",
      params: [account, data],
    });

    // Memecah tanda tangan
    const r = signature.slice(0, 66);
    const s = "0x" + signature.slice(66, 130);
    let v = web3.utils.hexToNumber("0x" + signature.slice(130, 132));

    // Menyesuaikan nilai `v` untuk EIP-155
    if (v < 27) {
      v += 27;
    }

    console.log({ v, r, s });

    return {
      deadline,
      v,
      r,
      s,
    };
  } catch (error) {
    console.log(error);

    // jika dicancel pada window metamask, akan dilihat seperti berikut
    // {code: 4001, message: 'User rejected the request.'}
    // Dibuat return true dan transaksi tidak berlanjut
    if (error.code == 4001) {
      return true;
    } else {
      return false;
    }
  }
};

export default utama;
