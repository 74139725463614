<template>
  <div class="wrapper_i">
    <TopNav />
    <div class="markets ptb70">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="markets-pair-list">
              <MarketTable />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer_sc">
      <Footer />
    </div>
  </div>
</template>

<script>
// node_modules
import axios from "axios";
import Cookie from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import MarketTable from "@/components/global/MarketTable.vue";

export default {
  components: {
    TopNav,
    Footer,
    MarketTable,
  },
  data() {
    return {
      themain,
      markets: [],
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected");
    },

    market_update: function (data) {
      var self = this;

      var market = data.market;

      // buat timeout sedikit karena hasilnya kadang gak sesuai ekspektasi
      setTimeout(function () {
        self.emitter.emit("fe-market_update", {
          data: market,
        });
      }, 100);
    },
  },

  async beforeMount() {
    var self = this;
    themain.beforeMountConnectToWallet(self);
    themain.networkAlert();
    themain.networkChanged(self);
    themain.accountChanged(self);
  },

  async mounted() {
    var self = this;

    // cari terlebih dahulu chosenChainId
    // jika undefined, artinya belum di set
    if (Cookie.get("chosenChainId") == undefined) {
      Cookie.set("chosenChainId", themain.defaultChainId);
    }

    // jika chosenChainId tidak sama dengan path params chainId, maka diredirect ke yang betul
    if (Cookie.get("chosenChainId") != self.$route.params.chainId) {
      self.$router.push({
        path: `${Cookie.get("chosenChainId")}/markets`,
      });
    }

    var marketsRes = await axios({
      method: "get",
      url: `${themain.baseApi}/markets/${Cookie.get("chosenChainId")}`,
    });
    self.markets = marketsRes.data.data.datas;
  },
  methods: {},
};
</script>

<style scoped>
.header_mr {
  width: 100%;
  height: 534px;
  background-color: #282b37;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.top_wrap {
  padding-top: 60px;
  width: 90%;
  display: flex;
}

.mr_left {
  flex: 1;
  display: flex;
  align-items: center;
}

.mr_right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.mr_right img {
  height: 500px;
}

.mr_left h2 {
  color: #fff;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mr_present-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mr_present {
  width: 90%;
  height: 200px;
  background: #23262f;
  border-radius: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
}

.mr_pre-item {
  /* background-color: antiquewhite; */
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  color: #fff;
}

.mr_re-item-left h2 {
  font-size: 16px;
  color: #989797;
}

.mr_re-item-left h3 {
  font-size: 24px;
  color: #ffffff;
}

.mr_re-persen {
  /* max-height: 20px;
  padding: 3px 5px; */
  width: 70px;
  background-color: #0ecb81;
  border-radius: 11px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  display: grid;
}

.mr_re-persen p {
  margin: 0 auto;
  padding: 3px 5px;
}

.ptb70 {
  margin-top: 60px;
}
</style>
