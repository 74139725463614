import { createStore } from "vuex";

import axios from "axios";

// applibs
import themain from "@/applibs/themain.js";
import getConnectedAddress from "@/applibs/metamask/get-connected-address.js";

// import Data from "../assets/DummyData/data.json";
export default createStore({
  state() {
    return {
      // allData: Data,
    };
  },

  mutations: {
    connectedAddress(state, connectedAddress) {
      state.connectedAddress = connectedAddress;
    },
    bnbUsdtPrice(state, theValue) {
      state.bnbUsdtPrice = theValue;
    },
  },
  actions: {
    async getConnectedAddress({ commit }) {
      var connectedAddress;
      if (window.ethereum || window.ethProvider) {
        connectedAddress = await getConnectedAddress();

        if (connectedAddress != null) {
          // cek di database
          await axios({
            method: "post",
            url: `${themain.baseApi}/users/connect-me`,
            data: {
              address: connectedAddress,
            },
          });
        }
      } else {
        connectedAddress = "";
      }
      // dari sini panggil mutations untuk mengganti datanya
      commit("connectedAddress", connectedAddress);
    },
    async getBnbUsdtPrice({ commit }) {
      var response = await axios({
        url: `https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT`,
        method: "get",
      });
      console.log("bnbusdt", response);

      commit("bnbUsdtPrice", parseFloat(response.data.price));
    },
  },
  getters: {
    sells(state) {
      return state.allData.sells;
    },

    connectWith(state) {
      return state.allData.connectWith;
    },
  },
});
