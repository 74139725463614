import Web3 from "web3";

var utama = async function () {
  var web3;
  if (window.ethereum) {
    web3 = new Web3(window.ethereum);
  } else {
    web3 = new Web3(window.ethProvider);
  }

  var accounts = await web3.eth.getAccounts();

  if (accounts.length != 0) {
    return accounts[0];
  } else {
    return "";
  }
};

export default utama;
