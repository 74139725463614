<!-- disini connected address sebagai buyer -->
<template>
  <div class="wrapper">
    <TopNav />

    <div class="container-fluid text-light">
      <div class="row">
        <div class="col-12">
          <h1>Your {{ b_symbol }} for {{ a_symbol }}</h1>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <p>
            User {{ obook.owner }} offer {{ obook.qty_a_db }} {{ a_symbol }} at
            price {{ obook.price_db }} {{ b_symbol }} per {{ a_symbol }}
          </p>

          <p>
            If you put
            <input
              type="text"
              v-model="theForm.qty_b_db_input"
              @input="resultCalculation"
            />
            {{ b_symbol }}, you will get {{ theForm.calculation }}
            {{ a_symbol }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <router-link to="/p2p" class="btn btn-default"> Back </router-link>
          <button type="button" @click="formExec">Swap</button>
        </div>
      </div>

      <div class="row">
        <div class="col-12 alert alert-info">
          <h5>Perhatian</h5>
          <p>
            Ini adalah pasar desentralisasi. Harap memastikan Anda berinteraksi
            dengan token yang Anda tuju.
          </p>
          <p>
            Anda harus memastikan Address Token yang diinginkan karena token
            dapat mempunyai symbol yang sama
          </p>

          <h5>Informasi Token</h5>
          <div class="row">
            <div class="col">
              Token 1:

              <!-- TODO: Link address -->
              <table>
                <tr>
                  <td>Symbol</td>
                  <td>:</td>
                  <td>{{ a_symbol }}</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>:</td>
                  <td>{{ obook.market_obj.a_name }}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>:</td>
                  <td>{{ obook.market_obj.a_address }}</td>
                </tr>
              </table>
            </div>

            <div class="col">
              Token 2:

              <!-- TODO: Link address -->
              <table>
                <tr>
                  <td>Symbol</td>
                  <td>:</td>
                  <td>{{ b_symbol }}</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>:</td>
                  <td>{{ obook.market_obj.b_name }}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>:</td>
                  <td>{{ obook.market_obj.b_address }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer_sc">
      <Footer />
    </div>
  </div>
</template>

<script>
// node_modules
import Cookie from "js-cookie";
import Big from "big.js";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";
import buyPump from "@/applibs/exchange/buy-pump.js";
import approve from "@/applibs/erc20/approve.js";
import allowance from "@/applibs/erc20/allowance.js";
import fee from "@/applibs/exchange/fee.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {
      themain,
      sc_address: this.$route.query.sc_address,
      market_id_bc: this.$route.query.market_id_bc,
      id_bc: this.$route.query.id_bc,
      buy_or_sell: this.$route.query.buy_or_sell,

      buy_id_bc: 0,
      sell_id_bc: 0,

      obook: {
        market_obj: {
          b_address: "",
        },
      },

      a_symbol: "",
      b_symbol: "",

      theForm: {
        qty_b_db: "",
        qty_b_db_input: "",
        calculation: "",
        qty_a_db: "",
      },

      allowance: 0,
      allowance_human: 0,

      marketFee: 0,
      marketFeeHuman: 0,
    };
  },

  async beforeMount() {
    var self = this;
    themain.beforeMountConnectToWallet(self);
    themain.networkAlert();
    themain.networkChanged(self);
    themain.accountChanged(self);
  },

  async mounted() {
    var self = this;

    // tentukan, apakah dia adalah sell_id_bc atau buy_id_bc
    if (self.buy_or_sell == "sells") {
      self.sell_id_bc = self.id_bc;
    } else {
      self.buy_id_bc = self.id_bc;
    }

    // ambil dulu data orderbook baik itu sell atau buy
    await self.getObookByMarketScAndBcId();

    await self.getAllowance();

    await self.getMarketFee();
  },

  methods: {
    async getAllowance() {
      var self = this;
      var allowanceRes = await allowance(
        self.obook.market_obj.b_address,
        Cookie.get("connectedAddress"),
        self.sc_address
      );

      self.allowance = allowanceRes;

      // konversi allowance ke human readable
      var allowance_human = Big(`${allowanceRes}`)
        .div(`1e${self.obook.market_obj.b_decimals}`)
        .toString();
      console.log("allowance_human", allowance_human);
      self.allowance_human = allowance_human;
    },

    async getMarketFee() {
      var self = this;
      var res = await fee();
      var humanFee = Big(res).div(
        `1e${process.env.VUE_APP_ROOT_COIN_DECIMALS}`
      );
      self.marketFeeHuman = humanFee.toString();
      self.marketFee = res;
    },

    async getObookByMarketScAndBcId() {
      var self = this;
      var res = await thestore.getObookByMIdScBcId(
        self.sc_address,
        self.market_id_bc,
        self.buy_id_bc,
        self.sell_id_bc
      );
      self.obook = res;

      // disini juga kita berikan symbol a dan b
      self.a_symbol = res.market_obj.pair.split("-")[0];
      self.b_symbol = res.market_obj.pair.split("-")[1];

      // ketika form pertama kali di load, maka harus dikalkulasi juga yang ada didalam form
      self.theForm = res;

      // Sengaja dibuat properti baru karena bagian ini nanti akan diedit oleh user
      // Ketika form pertama kali di load, diberikan nilai yang sama dengan yang ada dari database
      self.theForm["qty_b_db_input"] = res.qty_b_db;

      // kalkulasi untuk pertama kali harus sama dengan yang ada di database
      self.theForm["calculation"] = res.qty_a_db;
    },

    /**
     * Ketika terjadi perubahan input, maka kode ini di eksekusi
     * untuk membuat perhitungan berapa perkiraan yang didapatkan oleh user
     * TODO: Gunakan big number
     */
    async resultCalculation() {
      var self = this;

      // cegah input lebih besar dari yang ada di swap

      // tracking perbandingan dari yang di input
      var persentase =
        parseFloat(self.theForm.qty_b_db_input) /
        parseFloat(self.theForm.qty_b_db);
      console.log("persentase", persentase);

      if (persentase <= 1) {
        // hasil kalkulasi
        self.theForm["calculation"] =
          parseFloat(self.theForm.qty_a_db) * persentase;
      } else {
        alert("Can not be bigger than offering value");
      }
    },

    async formExec() {
      var self = this;

      // perhitungan untuk di submit
      var qty_b_input = Big(self.theForm.qty_b_db_input);
      var qty_b_input_send = qty_b_input.times(
        Big(`1e${self.obook.market_obj.b_decimals}`)
      );

      var qty_b_input_send_string = qty_b_input_send.toString();

      console.log("qty_b_input_send_string", qty_b_input_send_string);

      if (Big(self.allowance).gte(qty_b_input_send_string)) {
        console.log("xisini");
        // jika ada allowance, maka eksekusi langsung
        await buyPump(
          Cookie.get("connectedAddress"),
          [self.sell_id_bc],
          [qty_b_input_send_string],
          self.marketFee,
          0,
          0,
          0
        );
      } else {
        // Jika tidak ada allowance, eksekusi dulu disini
        await approve(
          Cookie.get("connectedAddress"),
          self.obook.market_obj.b_address,
          self.sc_address,
          qty_b_input_send_string
        );

        await buyPump(
          Cookie.get("connectedAddress"),
          [self.sell_id_bc],
          [qty_b_input_send_string],
          self.marketFee,
          0,
          0,
          0
        );
      }

      // TODO: APAKAH PERLU DIBUATKAN sync data buy

      setTimeout(function () {
        self.$router.go();
      }, 3000);
    },
  },
};
</script>
