<template>
  <div>
    <TopNav />

    <section class="container mb-4">
      <section class="row">
        <div class="col">
          <h1 class="text-light mt-4">Create Market</h1>

          <form class="row mt-4">
            <div class="col">
              <h4 class="text-light">Contract A</h4>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Address"
                  v-model="tokenA.contract_address"
                  @keyup="getData('A')"
                />
              </div>
              <div class="row text-white">
                <div class="col-lg-12 app-token-info">
                  <div class="row text-light">
                    <div class="col-md-2 col-12">Name</div>
                    <div class="col-md-10 col-12 app-val">
                      {{ tokenA.name }}
                    </div>
                  </div>
                  <div class="row text-light">
                    <div class="col-md-2 col-12">Symbol</div>
                    <div class="col-md-10 col-12 app-val">
                      {{ tokenA.symbol }}
                    </div>
                  </div>
                  <div class="row text-light">
                    <div class="col-md-2 col-12">Decimals</div>
                    <div class="col-md-10 col-12 app-val">
                      {{ tokenA.decimals }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <h4 class="text-white">Contract B</h4>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Address"
                  v-model="tokenB.contract_address"
                  @keyup="getData('B')"
                />
              </div>
              <div class="row">
                <div class="col-lg-12 app-token-info">
                  <div class="row text-light">
                    <div class="col-md-2 col-12">Name</div>
                    <div class="col-md-10 col-12 app-val">
                      {{ tokenB.name }}
                    </div>
                  </div>
                  <div class="row text-light">
                    <div class="col-md-2 col-12">Symbol</div>
                    <div class="col-md-10 col-12 app-val">
                      {{ tokenB.symbol }}
                    </div>
                  </div>
                  <div class="row text-light">
                    <div class="col-md-2 col-12">Decimals</div>
                    <div class="col-md-10 col-12 app-val">
                      {{ tokenB.decimals }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="row">
            <div class="col">
              <div class="btn btn-dark" @click="submitForm()">Create</div>
            </div>
          </div>
        </div>
      </section>
    </section>
    <Footer />
  </div>
</template>

<!-- TODO: TAMPILKAN LISTING FEE PADA FE -->
<script>
// node_modules
import Cookie from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import erc20Data from "@/applibs/erc20/data.js";
import setMarket from "@/applibs/exchange/set-market.js";
import listingFee from "@/applibs/exchange/listing-fee.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {
      themain,

      mtipe: this.$route.query.mtipe,

      tokenA: {
        contract_address: "",
        name: "",
      },
      tokenB: {
        contract_address: "",
        name: "",
      },

      market_fee: 0,
      listing_fee: 0,
    };
  },

  async beforeMount() {
    var self = this;
    themain.beforeMountConnectToWallet(self);
    themain.networkAlert();
    themain.networkChanged(self);
    themain.accountChanged(self);
  },

  async mounted() {
    var self = this;

    // Cek dulu dan cari dia ini adalah tipe apa
    // Dengan mendeteksi tipenya, kita bisa membuat apakah field A yang aktif,
    // atau field B atau kedua field
    if (self.mtipe == "1") {
      // token/token
      // Disini aktif keduanya
    } else if (self.mtipe == "2") {
      // token/coin
      // Disini aktif token A

      // token B adalah ETH.
      // Datanya didapat dari env saja
      self.tokenB["contract_address"] =
        "0x0000000000000000000000000000000000000000";
      self.tokenB["name"] = themain.rootCoinName;
      self.tokenB["symbol"] = themain.rootCoinSymbol;
      self.tokenB["decimals"] = themain.rootCoinDecimals;
    } else if (self.mtipe == "3") {
      // coin/token
      // Disini aktif token B

      // token A adalah ETH.
      // Datanya didapat dari env saja
      self.tokenA["contract_address"] =
        "0x0000000000000000000000000000000000000000";
      self.tokenA["name"] = themain.rootCoinName;
      self.tokenA["symbol"] = themain.rootCoinSymbol;
      self.tokenA["decimals"] = themain.rootCoinDecimals;
    } else {
      self.$router.push({ path: "/" });
    }

    self.listing_fee = await listingFee();
    console.log("self.listing_fee", self.listing_fee);
  },

  methods: {
    async getData(a_or_b) {
      var self = this;
      var data;

      if (a_or_b == "A") {
        data = await erc20Data(self.tokenA.contract_address);

        self.tokenA.name = data.name;
        self.tokenA.decimals = data.decimals;
        self.tokenA.symbol = data.symbol;
      } else {
        data = await erc20Data(self.tokenB.contract_address);

        self.tokenB.name = data.name;
        self.tokenB.decimals = data.decimals;
        self.tokenB.symbol = data.symbol;
      }
    },

    async submitForm() {
      var self = this;

      console.log(Cookie.get("connectedAddress"));
      console.log(self.tokenA.contract_address);
      console.log(self.tokenB.contract_address);
      console.log(self.mtipe);

      await setMarket(
        Cookie.get("connectedAddress"),
        self.tokenA.contract_address,
        self.tokenB.contract_address,
        self.mtipe,
        self.listing_fee
      );

      setTimeout(function () {
        // arahkan ke markets
        self.$router.push({
          path: `/${themain.getChosenChainId()}/markets`,
        });
      }, 6000);
    },
  },
};
</script>

<style scoped>
.app-token-info .row {
  margin-bottom: 10px;
}
.app-token-info .app-val {
  font-weight: bold;
  font-size: 1.3em;
}
</style>
