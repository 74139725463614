// node_modules
import axios from "axios";

const utama = async function (address, buyId, pk) {
  const res = await axios({
    method: "post",
    url: `${process.env.VUE_APP_BASE_BOT}/exchange/buy-cancel`,
    data: {
      address,
      buyId,
      pk,
    },
  });
  console.log("res", res);
  return res;
};

export default utama;
