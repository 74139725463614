import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie";

import Home from "../pages/Home.vue";

import Owner from "../pages/Owner/Index.vue";

import About from "../pages/About.vue";
import PageFaq from "../pages/p/Faq.vue";

import SettingsIndex from "../pages/Settings/Index.vue";
import SettingsProfile from "../pages/Settings/Profile.vue";
import SettingsWallet from "../pages/Settings/Wallet.vue";
import SettingsMarket from "../pages/Settings/Market.vue";

import Markets from "../pages/markets/Index.vue";
import MarketsExchange from "../pages/markets/Exchange.vue";

import P2pIndex from "../pages/p2p/Index.vue";
import P2pSwapSell from "../pages/p2p/SwapSell.vue";
import P2pSwapBuy from "../pages/p2p/SwapBuy.vue";
import P2pCreateStepOne from "../pages/p2p/create/StepOne.vue";
import P2pCreateStepTwo from "../pages/p2p/create/StepTwo.vue";

import Coins from "../pages/Coins/Index.vue";

import UserCreateMarket from "../pages/user/CreateMarket.vue";
import UserCreateArticle from "../pages/user/CreateArticle.vue";
import UserGetPubkey from "../pages/user/GetPubkey.vue";
import UserCreateToken from "../pages/user/CreateToken.vue";

import DevCreatePermitPage from "../pages/dev/CreatePermit.vue";

import ResearchMultiTrxTokenInOneGo from "../pages/research/MultiTrxTokenInOneGo.vue";


import Chat from "../pages/chat/Chat.vue";

const isConnected = () => Cookies.get('connected');

const router = createRouter({
  history: createWebHistory(),
  mode: "history",
  scrollBehavior(_, _2, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    // { path: "/", redirect: "/home" },
    // { path: "/home", component: Home },
    { path: "/", component: Home, name: "Home" },

    {
      path: "/:chainId/markets",
      component: Markets,
      name: "Market",
    },
    {
      path: "/:chainId/markets/:sc_address/:market_id_bc",
      component: MarketsExchange,
      name: "Exchange",
    },

    { path: "/p2p", component: P2pIndex, name: "P2P" },
    { path: "/p2p/swap-sell", component: P2pSwapSell, name: "P2P Swap Sell" },
    { path: "/p2p/swap-buy", component: P2pSwapBuy, name: "P2P Swap Buy" },
    {
      path: "/p2p/create/step-one",
      component: P2pCreateStepOne,
      name: "Step One",
    },
    {
      path: "/p2p/create/step-two",
      component: P2pCreateStepTwo,
      name: "Step Two",
    },

    { path: "/coins", component: Coins, name: "Coins" },

    { path: "/owner", component: Owner, name: "Owner" },

    { path: "/settings/index", component: SettingsIndex, name: "Settings" },
    {
      path: "/settings/profile",
      component: SettingsProfile,
      name: "Setting Profile",
      beforeEnter: (to, from, next) => {
        if (isConnected() === "no") {
          next("/");
        } else {
          next();
        }
      },
    },
    {
      path: "/settings/wallet",
      component: SettingsWallet,
      name: "Setting Wallet",
      beforeEnter: (to, from, next) => {
        if (isConnected() === "no") {
          next("/");
        } else {
          next();
        }
      },
    },
    {
      path: "/settings/market",
      component: SettingsMarket,
      name: "Setting Market",
      beforeEnter: (to, from, next) => {
        if (isConnected() === "no") {
          next("/");
        } else {
          next();
        }
      },
    },

    {
      path: "/user/create-market",
      component: UserCreateMarket,
      name: "Create Market",
    },
    {
      path: "/user/create-article",
      component: UserCreateArticle,
      name: "Create Article",
    },
    { path: "/user/get-pubkey", component: UserGetPubkey, name: "Pubkey" },
    {
      path: "/user/create-token",
      component: UserCreateToken,
      name: "Create Token",
    },

    { path: "/about", component: About },
    { path: "/p/faq", component: PageFaq },

    { path: "/dev/create-permit", component: DevCreatePermitPage },

    { path: "/research/multi-trx-token-in-one-go", component: ResearchMultiTrxTokenInOneGo },

    { path: "/chat", component: Chat },
  ],
});
router.beforeEach(function (to, _2, next) {
  setTimeout(() => {
    window.scrollTo(0, 0);
    next();
  }, 10);

  const appName = "DEXGOOD";
  const metaName = to.name;
  document.title = `${appName} - ${metaName}`;
  next();
});
export default router;
