// node_modules
import axios from "axios";

const utama = async function (
  address,
  tokenAddress,
  marketId,
  sellIds,
  qtyBs,
  final_normal_buy,
  market_fee,
  pk
) {
  const res = await axios({
    method: "post",
    url: `${process.env.VUE_APP_BASE_BOT}/exchange/create-permit-pump`,
    data: {
      address,
      tokenAddress,
      marketId,
      sellIds,
      qtyBs,
      final_normal_buy,
      market_fee,
      pk,
    },
  });
  console.log("res", res);
  return res;
};

export default utama;
