<template>
  <div class="col-md-12 col-lg-3">
    <div
      class="nav flex-column nav-pills settings-nav"
      id="v-pills-tab"
      role="tablist"
      aria-orientation="vertical"
    >
      <router-link
        class="nav-link"
        id="settings-profile-tab"
        data-toggle="pill"
        to="/settings/profile"
        role="tab"
        aria-controls="settings-profile"
        aria-selected="true"
      >
        <i class="icon ion-md-person"></i>
        Profile
      </router-link>
      <router-link
        class="nav-link"
        id="settings-wallet-tab"
        data-toggle="pill"
        to="/settings/wallet"
        role="tab"
        aria-controls="settings-wallet"
        aria-selected="false"
      >
        <i class="icon ion-md-wallet"></i> Wallet
      </router-link>
      <router-link
        class="nav-link"
        id="settings-tab"
        data-toggle="pill"
        to="/settings/market"
        role="tab"
        aria-controls="settings"
        aria-selected="false"
        ><i class="icon ion-md-settings"></i>
        Market Settings
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.router-link-active {
  background-color: #ff666d !important;
  color: #fff !important;
}
</style>
