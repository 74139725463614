import jsonInterface from "./abi";
import Web3 from "web3";

var utama = async function (contractAddress, owner, spender) {
  var web3 = new Web3(window.ethereum);

  var myContract = new web3.eth.Contract(jsonInterface, contractAddress);

  var hasil = await myContract.methods.allowance(owner, spender).call();
  // console.log("hasil", hasil);

  return hasil;
};

export default utama;
