import Big from "big.js";

const utama = {
  sellDumpCalculation(self, qty_a_input, buys_include, price) {

    // digunakan untuk mencatat blockchain id dari partial buy
    var partial_buy_bc_id = null;
    // digunakan untuk mencatat semua data yang ada sebelum perubahan partial buy
    var partial_buy_when_full_data = null;

    var sisa = qty_a_input;
    var index = 0;
    var sisa_arr = [];
    var minus_index = null; // ketemu minus pada index? catat pada variable ini
    buys_include.forEach((ele) => {
      // console.log(
      //   "-- ele",
      //   `index ${index} -`,
      //   ele.price_db,
      //   "-",
      //   ele.qty_a_db,
      //   "-",
      //   ele.qty_b_db
      // );

      sisa = sisa.minus(Big(ele.qty_a_db));
      // console.log("sisa", sisa);
      sisa_arr.push({
        index: index,
        sisa: sisa,
        price: Big(ele.price_db),
        qty_a: Big(ele.qty_a_db),
      });

      if (minus_index == null && sisa.lt(0)) {
        minus_index = index;
      }

      index = index + 1;
    });

    var final_normal_sell = null;
    // final_smash_list adalah daftar yang dimasukkan dalam sell yang akan disubmit ke blockchain
    // bagian ini ditandai dengan minus_index
    var final_smash_list = [];
    if (minus_index == null) {
      // disini semua buys_include dimasukkan karena tidak ada minus_index
      buys_include.forEach((ele) => {
        final_smash_list.push(ele);
      });
    } else {
      // masukkan sampai minus_index - 1
      for (let i = 0; i < minus_index; i++) {
        final_smash_list.push(buys_include[i]);
      }
    }

    // console.log("smash_list init", final_smash_list);

    // console.log("sisa_arr", sisa_arr);
    // console.log("minus_index", minus_index);

    // row terakhir dari smash, apakah partial atau ada sisa sell tertinggal
    if (minus_index == null) {
      // console.log("ADA BAGIAN TERSISA DI SELL DISINI");

      var sisa_pop = sisa_arr.pop(); // kita ambil bagian terakhir
      // console.log(sisa_pop);
      sisa_pop["price_db"] = price.toString();
      sisa_pop["price"] = price
        .times(Big(10).pow(self.market.b_decimals))
        .toString(); // => price * 10**18
      sisa_pop["qty_a_db"] = sisa_pop.sisa.toString();
      sisa_pop["qty_a"] = Big(sisa_pop.sisa)
        .times(Big(10).pow(self.market.a_decimals))
        .toString(); // => sisa * 10**18

      if (Big(sisa_pop["qty_a"]).lte(0)) {
        final_normal_sell = null;
      } else {
        final_normal_sell = sisa_pop;
      }
    } else {
      // disini artinya ada partial buy sehingga buy tersisa
      partial_buy_bc_id = buys_include[minus_index].buy_id_bc;
      partial_buy_when_full_data = JSON.parse(
        document.getElementById(`buy_bc_id_${partial_buy_bc_id}`).innerHTML
      );

      // jika minus_index == 0, artinya dia hanya hit pada row pertama
      if (minus_index == 0) {
        // console.log("qty_a_input", qty_a_input);
        // console.log("buys_include[0].qty_a_db", buys_include[0].qty_a_db);

        var persentase_dipakai = qty_a_input.div(
          Big(buys_include[0].qty_a_db)
        );
        // console.log("persentase_dipakai", persentase_dipakai);
        // console.log("persentase_dipakai", persentase_dipakai.toString());

        // ambil row pertama dari buys
        var smash_row_zero = buys_include[0];
        smash_row_zero["qty_a_db"] = qty_a_input.toString();
        smash_row_zero["qty_a"] = qty_a_input
          .times(Big(10).pow(self.market.a_decimals))
          .toString();
        smash_row_zero["qty_b_db"] = Big(buys_include[0].qty_b_db)
          .times(persentase_dipakai)
          .toString();
        smash_row_zero["qty_b"] = Big(buys_include[0].qty_b)
          .times(persentase_dipakai)
          .toString();

        // masukkan kedalam smash list
        final_smash_list.push(smash_row_zero);
      } else {
        // determinasi index ini, dipastikan ini adalah full sell bagi seller, partial buy pada buyer
        if (
          Big(sisa_arr[minus_index].qty_a).gt(
            Big(Math.abs(sisa_arr[minus_index - 1].sisa))
          )
        ) {
          // sisa_arr[minus_index].qty_a > Math.abs(sisa_arr[minus_index - 1].sisa)
          // console.log("partial sell here");

          // kalkulasi row terakhir

          var sisa_terakhir = Big(Math.abs(sisa_arr[minus_index - 1].sisa));
          // console.log("sisa_terakhir", sisa_terakhir);

          // CODE INI GAK DIPAKAI, JADI DI COMMENT SAJA DULU
          // persentase yang diambil adalah perbandingannya
          // var persentase = sisa_terakhir.div(
          //   buys_include[minus_index].qty_a_db
          // ); // sisa_terakhir / parseFloat(buys_include[minus_index].qty_a_db)
          // console.log("persentase ", persentase);

          // disini kita harus modifikasi qty_a
          var smash_list_last = buys_include[minus_index];
          smash_list_last["qty_a_db"] = sisa_terakhir.toString();
          smash_list_last["qty_a"] = sisa_terakhir
            .times(Big(10).pow(self.market.a_decimals))
            .toString();

          // masukkan kedalam smash list
          final_smash_list.push(smash_list_last);
        }
      }
    }

    // console.log("FINAL: final_smash_list submit", final_smash_list);
    // console.log("FINAL: normal_sell", final_normal_sell);

    var buyIds = [];
    var qtyAs = [];
    final_smash_list.forEach((el) => {
      buyIds.push(String(el.buy_id_bc));
      qtyAs.push(Big(el.qty_a).toFixed());
    });
    // console.log("a", buyIds);
    // console.log("b", qtyAs);

    // dapatkan estimasi dengan menjumlahkan semua hasil dari token B
    var estimasiHasilB = final_smash_list
      .reduce((accumulator, currentObject) => {
        return accumulator.plus(Big(currentObject.qty_b_db));
      }, Big(0))
      .toNumber();
    self.sellForm.qtyBEstimation = estimasiHasilB;

    // Sepertinya karena kalkulasi, maka ada perubahan pada tampilan dan malahan data pada partial buy
    // dimana seharusnya walaupun partial buy, data belum berubah pada tampilan karena belum terjadi perubahan pada blockchain.
    // Tetapi yang terjadi, tampilan langsung berubah. Sehingga disini harus dikembalikan lagi
    if (partial_buy_bc_id != null) {
      self.f_buys.forEach(function (baliknilai, idxbalik) {
        if (baliknilai.id == partial_buy_when_full_data.id) {
          self.f_buys[idxbalik] = partial_buy_when_full_data;
        }
      });
    }

    return {
      buyIds,
      qtyAs,
      final_normal_sell
    }
  }
};

export default utama;
