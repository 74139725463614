<template>
  <div>
    <TopNav />

    <section class="container">
      <section class="row">
        <div class="col">
          <h1 class="text-light mt-4">New Article</h1>

          <form class="row mt-4" id="create-article-form">
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  name="title"
                  v-model="theform.title"
                  class="form-control"
                  placeholder="Title"
                />
              </div>

              <div class="form-group">
                <input
                  type="text"
                  name="ext_link"
                  v-model="theform.ext_link"
                  class="form-control"
                  placeholder="External Link"
                />
              </div>

              <div class="form-group">
                <textarea
                  name="body"
                  v-model="theform.body"
                  class="form-control"
                  @keyup="checkArticleSize"
                ></textarea>
                <small :class="`text-light ${char_left_color}`">
                  {{ char_left }}
                </small>
              </div>
            </div>
          </form>

          <div class="row">
            <div class="col">
              <div class="btn btn-dark" @click="submitForm()">Submit</div>
            </div>
          </div>
        </div>
      </section>
    </section>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Web3 from "web3";
import axios from "axios";
import Cookie from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {
      themain,

      theform: {
        title: "",
        ext_link: "",
        body: "",
      },
      char_left: 500,
      char_left_color: "green",
    };
  },

  methods: {
    async submitForm() {
      var self = this;

      var web3;
      if (window.ethereum) {
        web3 = new Web3(window.ethereum);
      } else {
        web3 = new Web3(window.ethProvider);
      }

      // judul tidak boleh lebih dari 10
      if (self.theform.title.length < 10) {
        alert("Title cannot less than 10 characters");
        return false;
      }

      // tidak boleh kecil dari 10
      if (self.theform.body.length < 10) {
        alert("Content can not less than 10 characters");
        return false;
      }

      // hapus dulu html tag nya
      var cleanText = self.stripHtml(self.theform.body);
      self.theform.body = cleanText;

      // cek dulu apakah lebih dari 500 char
      if (self.theform.body.length > 500) {
        alert("Content can not more than 500 characters");
        return false;
      }

      if (self.isValidURL(self.theform.ext_link) == false) {
        alert("Invalid External Link");
        return false;
      }

      // jika masih lewat, maka dipisah saja disini
      if (self.theform.body.length > 500) {
        self.theform.body = self.theform.body.slice(0, 500);
      }

      var theFormJson = JSON.stringify(self.theform);

      // buat signature msg terlebih dahulu
      var signature = await web3.eth.personal.sign(
        theFormJson,
        Cookie.get("connectedAddress")
      );
      console.log("signature", signature);

      // ambil data form
      var myForm = document.getElementById("create-article-form");
      var fd = new FormData(myForm);
      console.log("fd", fd);
      fd.append("signature", signature);
      fd.append("address", Cookie.get("connectedAddress"));
      fd.append("msg", theFormJson);

      var res = await axios({
        url: `${themain.baseApi}/articles/create`,
        method: "post",
        data: fd,
      });
      console.log("response", res);

      if (res.data.success == true) {
        // arahkan ke markets
        self.$router.push({
          path: `/markets`,
        });
      } else {
        alert(res.data.msg);
      }
    },

    checkArticleSize() {
      var self = this;

      var sizeLeft = 500 - self.theform.body.length;
      if (sizeLeft >= 0) {
        self.char_left_color = "green";
      } else {
        self.char_left_color = "red";
      }

      self.char_left = sizeLeft;
    },

    stripHtml(htmlText) {
      let doc = new DOMParser().parseFromString(htmlText, "text/html");
      return doc.body.textContent || "";
    },

    isValidURL(str) {
      // var regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
      // if(!regex .test(str)) {
      //   // alert("Please enter valid URL.");
      //   return false;
      // } else {
      //   return true;
      // }
      console.log("str", str);
      return true;
    },
  },
};
</script>
