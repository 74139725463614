<template>
  <main>
    <div class="footer_wrapper">
      <footer class="footer_row">
        <div class="footer_container">
          <div class="footer_item">
            <div class="footer_left">
              <img src="/images/dexgood-logo.png" width="70" alt="" />
              <h3>YOUR GATEWAY TO THE FUTURE WORLD!</h3>
              <p class="x-footer-web-info">
                Web3, trusted and transparent cryptocurrency exchange
              </p>
            </div>
          </div>

          <div class="footer_item">
            <div>
              <h5>About</h5>
              <ul class="nav flex-column">
                <li class="nav-item mb-2">
                  <router-link to="/p/faq" class="nav-link p-0 text-muted"
                    >FAQ</router-link
                  >
                </li>
                <li class="nav-item mb-2">
                  <router-link
                    to="/p/term-of-service"
                    class="nav-link p-0 text-muted"
                    >Term of Service</router-link
                  >
                </li>
                <li class="nav-item mb-2">
                  <a
                    :href="`${explorer}/address/${marketAddress}`"
                    class="nav-link p-0 text-muted"
                    target="_blank"
                    >Explorer</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="footer_item">
            <div>
              <h5>Learn</h5>
              <ul class="nav flex-column">
                <li class="nav-item mb-2">
                  <router-link to="/marketplace" class="nav-link p-0 text-muted"
                    >Wallet</router-link
                  >
                </li>
                <li class="nav-item mb-2">
                  <router-link to="/about-us" class="nav-link p-0 text-muted"
                    >Create Market</router-link
                  >
                </li>
                <li class="nav-item mb-2">
                  <router-link to="/user/nfts" class="nav-link p-0 text-muted"
                    >Buy and Sell</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>

    <div class="footer_bottom">
      <div class="copy_right">
        <span>@ 2024 DexGood</span>
      </div>
    </div>
  </main>
</template>

<script>
// node_modules

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

export default {
  components: {},
  data() {
    return {
      themain,
      explorer: thestore.getBlockchainExplorerDomain(), 
      marketAddress: process.env.VUE_APP_EXCHANGE_ADDRESS,
    };
  },

  async mounted() {},

  methods: {},
};
</script>

<style scoped>
.footer_wrapper {
  background-color: #182d36;
  display: flex;
  justify-content: center;
}

.footer_row {
  width: 1400px;
}

.footer_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 50px 2rem;
}

.footer_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #6c757d;
}

.footer_item h5 {
  font-weight: 600;
}

.footer_left img {
  margin-bottom: 20px;
}

.footer_bottom {
  display: flex;
  justify-content: center;
  background-color: #0f2027;
}

.copy_right {
  width: 1400px;
  border-top: 3px solid #254451;
  padding: 20px 2rem;
  color: #6c757d;
}

.x-footer {
  padding-top: 46px;
  padding-bottom: 20px;
  margin-top: 60px;
  /* position: fixed;
  width: 100%;
  bottom: 0; */
}
ul.x-pages {
  list-style-type: none;
  padding-left: 0px;
  font-size: 0.9rem;
  line-height: 2rem;
  font-weight: 300;
}
ul.x-pages a {
  text-decoration: none;
}

.x-footer-bottom-section {
  border-top: 1px solid #fff;
  padding-top: 10px;
}

.x-footer-web-info {
  font-size: 0.8rem;
}

.text-muted:hover {
  color: #1bdbdb !important;
}

@media only screen and (max-width: 1400px) {
  /* .copy_right {
    margin-left: 20px;
  } */
}

@media only screen and (max-width: 1035px) {
  .footer_left {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .footer_container {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 1.5rem 1rem;
  }

  .footer_left {
    padding-left: 0;
  }

  .footer_item {
    align-items: flex-start;
    /* padding-left: 20px; */
  }

  .copy_right {
    padding: 1.5rem 1rem;
  }
}
</style>
