<template>
  <div class="wrapper">
    <TopNav />

    <div class="container-fluid text-light">
      <div class="row">
        <div class="col">
          I want to swap my token
          <input
            type="text"
            v-model="tokA_ContractAddress"
            @input="tokAFilterOptions"
            placeholder="Type to search..."
          />
          <ul v-show="tokA_ShowOptions">
            <li
              v-for="(option, index) in tokA_FilteredOptions"
              :key="index"
              @click="tokASelectOption(option)"
            >
              {{ option.address }} | {{ option.balance }} {{ option.simbol }}
            </li>
          </ul>

          <br />
          for token
          <input
            type="text"
            v-model="tokB_ContractAddress"
            @input="tokBFilterOptions"
            placeholder="Type to search..."
          />
          <ul v-show="tokB_ShowOptions">
            <li
              v-for="(option, index) in tokB_FilteredOptions"
              :key="index"
              @click="tokBSelectOption(option)"
            >
              {{ option.address }} | {{ option.balance }} {{ option.simbol }}
            </li>
          </ul>
          <br />
        </div>
      </div>

      <div class="row">
        <div class="col" v-show="markets.length > 0">
          We found {{ markets.length }} markets <br />
          <ul>
            <li v-for="(dat, index) in markets" :key="index">
              {{ dat.pair }}

              <router-link
                :to="`/p2p/create/step-two?sc_address=${dat.sc_address}&market_id=${dat.market_id_bc}&mytokenaddress=${tokA_ContractAddress}`"
              >
                Next
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col" v-show="markets.length == 0">Create New Market</div>
      </div>
    </div>

    <div class="footer_sc">
      <Footer />
    </div>
  </div>
</template>

<script>
// node_modules
import Cookie from "js-cookie";
// import Web3 from 'web3';

// applibs
import themain from "@/applibs/themain.js";
import erc20Data from "@/applibs/erc20/data.js";
import thestore from "@/applibs/thestore.js";
import balanceObj from "@/applibs/erc20/balance-obj.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {
      themain,
      tokenA: {
        contract_address: "",
        name: "",
      },
      tokenB: {
        contract_address: "",
        name: "",
      },
      cryptos: [],

      tokA_ContractAddress: "",
      tokA_FilteredOptions: [],
      tokA_ShowOptions: false,

      ct_addresses: [],

      tokB_ContractAddress: "",
      tokB_FilteredOptions: [],
      tokB_ShowOptions: false,

      markets: [],
    };
  },

  async beforeMount() {
    var self = this;
    themain.beforeMountConnectToWallet(self);
    themain.networkAlert();
    themain.networkChanged(self);
    themain.accountChanged(self);
  },

  async mounted() {
    var self = this;
    if (
      Cookie.get("connectedAddress") != null &&
      Cookie.get("connectedAddress") != ""
    ) {
      await self.getCryptos();
    }
  },

  methods: {
    async getData(a_or_b) {
      var self = this;
      var data;

      if (a_or_b == "A") {
        data = await erc20Data(self.tokenA.contract_address);
        self.tokenA.name = data.name;
        self.tokenA.decimals = data.decimals;
        self.tokenA.symbol = data.symbol;
      } else {
        data = await erc20Data(self.tokenB.contract_address);
        self.tokenB.name = data.name;
        self.tokenB.decimals = data.decimals;
        self.tokenB.symbol = data.symbol;
      }
    },

    async getCryptos() {
      var self = this;
      var res = await thestore.getCryptos(1, 50, "id", "desc", []);

      // dari data yang didapat, kita
      var ups = [];
      var jumlah = res.datas.length;
      var hitung = 0;
      res.datas.forEach(async (el) => {
        hitung = hitung + 1;
        // ambil balance
        var token = await balanceObj(
          Cookie.get("connectedAddress"),
          el.address
        );

        var nudata = el;
        nudata.balance = token["balance"];

        ups.push(nudata);

        // harus diakali seperti ini.
        // kalau tidak, maka render akan terjadi sebelum bagian loop ini selesai dan data tidak ter-load sempurna
        if (hitung == jumlah) {
          self.cryptos = [];
          self.cryptos = ups;
        }
      });
    },

    tokAFilterOptions() {
      var self = this;
      const inputArray = self.cryptos;
      const tokA_FilteredOptions = inputArray.filter((item) =>
        new RegExp(self.tokA_ContractAddress, "i").test(item.address)
      );
      self.tokA_FilteredOptions = tokA_FilteredOptions;
      self.tokA_ShowOptions = tokA_FilteredOptions.length > 0;
    },

    tokASelectOption(option) {
      var self = this;
      self.tokA_ContractAddress = option.address;
      self.tokA_ShowOptions = false;
      self.getMarkets();
    },

    tokBFilterOptions() {
      var self = this;
      const inputArray = self.cryptos;
      const tokB_FilteredOptions = inputArray.filter((item) =>
        new RegExp(self.tokB_ContractAddress, "i").test(item.address)
      );
      self.tokB_FilteredOptions = tokB_FilteredOptions;
      self.tokB_ShowOptions = tokB_FilteredOptions.length > 0;
    },

    tokBSelectOption(option) {
      var self = this;
      self.tokB_ContractAddress = option.address;
      self.tokB_ShowOptions = false;
      self.getMarkets();
    },

    async getMarkets() {
      var self = this;

      var adrs = [];
      if (self.tokA_ContractAddress != "") {
        adrs.push(self.tokA_ContractAddress);
      }
      if (self.tokB_ContractAddress != "") {
        adrs.push(self.tokB_ContractAddress);
      }

      console.log('adrs.join(",")', adrs.join(","));

      // hanya ambil jika ada 2 address
      if (adrs.length == 2) {
        if (
          self.tokA_ContractAddress.toLowerCase() !=
          self.tokB_ContractAddress.toLowerCase()
        ) {
          var res = await thestore.getMarketsByCtAddresses(adrs.join(","));
          console.log("res", res);
          self.markets = res;
        } else {
          alert("You cannot swap within the same addresses.");
        }
      }
    },
  },
};
</script>
