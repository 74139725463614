<template>
  <div class="wrapper">
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <div class="tab-content" id="v-pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="settings-wallet"
                role="tabpanel"
                aria-labelledby="settings-wallet-tab"
              >
                <div class="wallet">
                  <div v-if="nomarkets.length" class="row">
                    <div class="col-md-12 col-lg-12">
                      <h1 class="text-white">
                        I don't want to see this market
                      </h1>
                      <br />
                      <br />
                      <table class="table color-white">
                        <thead>
                          <tr>
                            <th style="text-align: left; color: #fff">
                              SmartContract
                            </th>
                            <th style="text-align: left; color: #fff">
                              MarketId
                            </th>
                            <th style="text-align: left; color: #fff">Pair</th>
                            <th style="text-align: left; color: #fff">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in nomarkets" :key="item.id">
                            <td>{{ item.sc_address }}</td>
                            <td>{{ item.market_id_bc }}</td>
                            <td>{{ item.market.pair }}</td>
                            <td>
                              <button
                                @click="removeMe(item.market)"
                                class="btn btn-light btn-sm"
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    v-else
                    class="d-flex justify-content-center align-items-center"
                    style="height: 15rem"
                  >
                    <h5>No Markets Available</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Web3 from "web3";
import axios from "axios";
import Cookie from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";

export default {
  components: {
    TopNav,
    Footer,
    Menu,
  },
  data() {
    return {
      themain,
      connectedAddress: Cookie.get("connectedAddress"),
      nomarkets: [],
    };
  },

  async beforeMount() {
    var self = this;
    themain.beforeMountConnectToWallet(self);
    themain.networkAlert();
    themain.networkChanged(self);
    themain.accountChanged(self);

    self.connectedAddress = Cookie.get("connectedAddress");
  },

  async mounted() {
    var self = this;
    self.connectedAddress = Cookie.get("connectedAddress");
    await self.getUsermarketconfigs();
  },

  methods: {
    async getUsermarketconfigs() {
      var self = this;
      var res = await thestore.getUsermarketconfigs(1, 20, "id", "desc", [
        {
          field: "user_address",
          op: "equal",
          value: `${self.connectedAddress.toLowerCase()}`,
        },
      ]);

      res.datas.forEach(async (ele) => {
        var market = await thestore.getMarket(ele.sc_address, ele.market_id_bc);

        var thedata = ele;

        thedata["market"] = market;

        self.nomarkets.push(thedata);
      });
    },

    async removeMe(marketItem) {
      var self = this;

      var web3;
      if (window.ethereum) {
        web3 = new Web3(window.ethereum);
      } else {
        web3 = new Web3(window.ethProvider);
      }

      if (confirm("Are you sure remove this from the list?") == true) {
        var msg = `I want to see ${marketItem.sc_address} #${marketItem.market_id_bc} market again`;

        // alert('mantap');
        var signature = await web3.eth.personal.sign(
          msg,
          Cookie.get("connectedAddress")
        );

        // yang dikirim adalah show: false
        await axios({
          url: `${themain.baseApi}/usermarketconfigs/dont-wanna-see`,
          method: "post",
          data: {
            address: Cookie.get("connectedAddress"),
            signature: signature,
            msg,
            sc_address: marketItem.sc_address,
            market_id_bc: marketItem.market_id_bc,
            show: true,
          },
        });

        self.$router.go();
      }
    },
  },
};
</script>

<style scoped>
#dark tr {
  background: transparent;
}
.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wallet {
  width: 100%;
  background-color: #1e222d;
  border-radius: 5px;
  color: aliceblue;
  padding: 5px 10px;

  display: flex;
  flex-direction: column;
}
</style>
