import jsonInterface from "./abi.js"; // Pastikan format file sesuai
import themain from "@/applibs/themain.js";
import Web3 from "web3";

var utama = async function (
  fromAddress,
  marketId,
  qtyA,
  price,
  deadline,
  v,
  r,
  s,
  ethSend
) {
  console.log({
    fromAddress,
    marketId,
    qtyA,
    price,
    deadline,
    v,
    r,
    s,
    ethSend,
  });

  try {
    const chainId = themain.getChosenChainId();
    const chainObj = themain.getChosenChainObj(chainId);

    if (typeof window.ethereum !== "undefined") {
      const web3 = new Web3(window.ethereum);

      var myContract = new web3.eth.Contract(
        jsonInterface,
        chainObj.sc_address
      );

      var hasil = await myContract.methods
        .setSell(marketId.toString(), qtyA, price, deadline, v, r, s)
        .send({
          from: fromAddress,
          value: ethSend,
        });

      return hasil;
    } else {
      throw new Error("Metamask not found");
    }
  } catch (error) {
    console.log(error);
    throw error; // Rethrow error agar bisa ditangani di tempat lain jika diperlukan
  }
};

export default utama;
