import jsonInterface from "./abi";
import Web3 from "web3";

var utama = async function (senderAddress, contractAddress, _sepender, _value) {
  const web3 = new Web3(window.ethereum);

  var myContract = new web3.eth.Contract(jsonInterface, contractAddress);

  var hasil = await myContract.methods.approve(_sepender, _value).send({
    from: senderAddress,
  });
  console.log("hasil", hasil);

  return hasil;
};

export default utama;
