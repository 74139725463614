// node_modules
import axios from "axios";

const utama = async function (
  address,
  sellIds,
  qty_arr,
  fee_send,
  market_id_bc,
  qty_sisa,
  price_sisa,
  deadline,
  v,
  r,
  s,
  pk
) {
  const res = await axios({
    method: "post",
    url: `${process.env.VUE_APP_BASE_BOT}/exchange/buy-pump`,
    data: {
      address,
      sellIds,
      qty_arr,
      fee_send,
      market_id_bc,
      qty_sisa,
      price_sisa,
      deadline,
      v,
      r,
      s,
      pk,
    },
  });
  console.log("res", res);
  return res;
};

export default utama;
