import jsonInterface from "./abi";
import Web3 from "web3";

var utama = async function (userAddress, contractAddress) {
  var web3;
  if (window.ethereum) {
    web3 = new Web3(window.ethereum);
  } else {
    web3 = new Web3(window.ethProvider);
  }

  var myContract = new web3.eth.Contract(jsonInterface, contractAddress);

  var hasil = await myContract.methods.balanceOf(userAddress).call();

  return hasil;
};

export default utama;
