import Web3 from "web3";
const web3 = new Web3(window.ethereum);

/**
 * Untuk mengetahui berapa konfirmasi yang didapat
 * @param {*} txhash
 * @returns jumlahKonfirmasi
 */
var howManyConfirmation = async function (txhash) {
  var blockNumber = await web3.eth.getBlockNumber();
  var txhashData = await web3.eth.getTransaction(txhash);

  // console.log('bn', blockNumber);
  // console.log('txhashData', txhashData);

  if (txhashData.blockNumber == null) {
    return 0;
  } else if (blockNumber == txhashData.blockNumber) {
    // pada ganache, konfirmasi sama dengan block karena dia mined setiap ada tx saja
    return 1;
  } else {
    return blockNumber - txhashData.blockNumber;
  }
};

export default howManyConfirmation;
