<template>
  <div class="wrapper_i">
    <TopNav />
    <div class="markets ptb70">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="markets-pair-list">
              <button @click="createPermitExec">Permit</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer_sc">
      <Footer />
    </div>
  </div>
</template>

<script>
// node_modules

// applibs
import themain from "@/applibs/themain.js";
import createPermitPure from "@/applibs/exchange/create-permit-pure.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {};
  },

  async beforeMount() {
    var self = this;
    themain.beforeMountConnectToWallet(self);
    themain.networkAlert();
    themain.networkChanged(self);
    themain.accountChanged(self);
  },

  async mounted() {},
  methods: {
    async createPermitExec() {
      // hard coding saja karena ini test 1x
      // ini adalah smartcontract market
      const spenderAddress = "0xB3deCB06D481fA3f685370d9b0Bfa94383396083";

      // ini adalah token CBT
      const erc20TokenAddress = "0xB6AD40cA41377155c6936FfD158c90f869782349";

      // ini adalah 1 CBT.
      const qtyToSpend = "1000000000000000000";

      const permitRes = await createPermitPure(
        spenderAddress,
        erc20TokenAddress,
        qtyToSpend
      );
      console.log("permitRes", permitRes);
    },
  },
};
</script>
